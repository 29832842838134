import React, { createContext, useContext, useState } from 'react';

const StonesContext = createContext();

export const StonesProvider = ({ children }) => {
  const [listStones, setListStones] = useState([]);
  const [batchAvgList, setBatchAvgList] = useState([]);
  const [findNo, setFindNo] = useState("");

  return (
    <StonesContext.Provider value={{ listStones, setListStones, batchAvgList, setBatchAvgList, findNo, setFindNo }}>
      {children}
    </StonesContext.Provider>
  );
};

export const useStonesContext = () => useContext(StonesContext);
