
const Protected = (props) => {
    const Component = props.Component;
    return (
        <div>
            <Component />
        </div >
    )
}

export default Protected