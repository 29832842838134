import React from 'react'
import Spinner from "react-bootstrap/Spinner";

export default function Loader() {
  return (
    <>
          <div className="overlay"></div>
          <div className="overlay-content text-center">
            <Spinner animation="" variant="primary">
              <span class="loader"></span>
            </Spinner>
          </div>
        </>
  )
}
