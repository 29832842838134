import React, { useRef, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import api from "../../Utils/api";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import Swal from "sweetalert2";
import Loader from "../../Components/Common/Loader";

const emptyPartyName = {
  partyMaster_id: 0,
  Party_Name: "",
  Adderss: "",
  Contact_Number: "",
  Hastak_Name: "",
  GSTNo: "",
  Broker_Name: "",
};

export default function GenerateBag() {
  const [formData, setFormData] = useState(emptyPartyName);
  const [validated, setValidated] = useState(false);
  const CreateParty = useRef(null);
  const [generatePartyData, setGeneratePartyData] = useState([]);
  const [editPartyMasterData, setEditPartyMasterData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getGeneratePartyData();
  }, []);

  useEffect(() => {
    if (editPartyMasterData) {
      setFormData({
        partyMaster_id: editPartyMasterData.partyMasterID,
        Party_Name: editPartyMasterData.partyName,
        Adderss: editPartyMasterData.address,
        Contact_Number: editPartyMasterData.contactNumber,
        Hastak_Name: editPartyMasterData.hastakName,
        GSTNo: editPartyMasterData.gstNo,
        Broker_Name: editPartyMasterData.brokerName,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editPartyMasterData]);

  const getGeneratePartyData = () => {
    api
      .get("PartyMaster/All")
      .then((result) => {
        setGeneratePartyData(result.data.data); // Correct function name
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const InsertPartyMaster = (event) => {
    ;
    setValidated(true);
    if (event) {
      event.preventDefault();
    }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    } else {
      const data = {
        partyMasterID: formData?.partyMaster_id || 0,
        partyName: formData.Party_Name,
        address: formData.Adderss,
        contactNumber: formData.Contact_Number,
        hastakName: formData.Hastak_Name,
        gSTNo: formData.GSTNo,
        brokerName: formData.Broker_Name,
      };

      const endpoint =
        formData.partyMaster_id === 0
          ? "PartyMaster/InsertPartyMaster"
          : "PartyMaster/EditPartyMaster";

      api[formData.partyMaster_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            getGeneratePartyData(); // Refresh party data after successful insertion
            resetForm(); // Reset form after successful insertion
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
    }
  };

  const getPartyMaster = (id) => {
    ;
    api
      .get(`PartyMaster/ByIDPartyMaster?PartyMasterID=${id}`)
      .then((result) => {
        const PartyMasterData = result.data.data;
        setEditPartyMasterData(PartyMasterData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DeletePartyMaster = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`PartyMaster/DeletePartyMaster?PartyMasterID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getGeneratePartyData();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const resetForm = () => {
    setFormData(emptyPartyName);
    setValidated(false);
  };

  const columnDefs = [
    {
      headerName: "PartyName",
      field: "partyName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Address",
      field: "address",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "ContactNumber",
      field: "contactNumber",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "HastakName",
      field: "hastakName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "GSTNo",
      field: "gstNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "BrokerName",
      field: "brokerName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Edit",
      width: 100,
      field: "partyMasterID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getPartyMaster(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 80,
      field: "partyMasterID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => DeletePartyMaster(value)}
        ></ion-icon>
      ),
      pinned: "right",
    },
  ];

  return (
    <>
      {showLoader && <Loader />}
      <ToastContainer />
      <Card>
        <Card.Header>
          <span>New Party </span>
        </Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={InsertPartyMaster}
            ref={CreateParty}
          >
            <Row>
              <Col md={6}>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="PartyName">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Party Name :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="text"
                        name="Party_Name"
                        placeholder="Enter PartyName"
                        value={formData.Party_Name}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value PartyName
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="adderss">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Address :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="adderss">
                      <Form.Control
                        type="text"
                        name="Adderss"
                        placeholder="Enter Address"
                        value={formData.Adderss}
                        onChange={handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the Adderess.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="contactNumber">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Contact.No :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="Contact_Number">
                      <Form.Control
                        type="text"
                        name="Contact_Number"
                        placeholder="Enter Contact Number"
                        value={formData.Contact_Number}
                        onChange={handleInputChange}
                        maxLength={10}
                        pattern="[0-9]*"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the Contact Number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="HastakName">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Hastak Name :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="Hastak_Name">
                      <Form.Control
                        type="text"
                        name="Hastak_Name"
                        placeholder="Enter HastakName"
                        value={formData.Hastak_Name}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the number of pieces.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="GSTNo">
                      <Form.Label style={{ marginRight: "20px" }}>
                        GSTNo :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="GSTNo">
                      <Form.Control
                        type="text"
                        name="GSTNo"
                        placeholder="Enter GST Number"
                        value={formData.GSTNo}
                        onChange={handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the GST Number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="brokerName">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Broker Name :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="Broker_Name">
                      <Form.Control
                        type="text"
                        name="Broker_Name"
                        placeholder="Enter BrokerName"
                        value={formData.Broker_Name}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the number of pieces.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                {formData?.partyMaster_id > 0 ? "Update" : "Save"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <span>Party Master Data</span>
        </Card.Header>
        <Card.Footer>
          <AgGridComponent
            rowData={generatePartyData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="70vh"
          />
        </Card.Footer>
      </Card>
    </>
  );
}
