import React from "react";
// import logo from "../../assets/logo.png";
import {
  addDecimalPlaces,
  convertDateFormatWithOutTime,
} from "../../Utils/commonfunctions";
import QRCode from "react-qr-code";
import Logo from "../../assets/Asian Lab icon Logo.png";

export default function PrintJangad({companyData, data }) {

  const borderWithBottomNone = {
    border: "1px solid black",
    borderBottom: "none",
  };


  const TableBottomMargin = {
    marginBottom: "0px",
  };

  const NormalFontSize = {
    fontSize: "12px",
  };

  function calculateTotalPcs(data) {
    let totalPcs = 0;

    if (data && data.listJangadDetails) {
      data.listJangadDetails.forEach((item) => {
        totalPcs += parseInt(item.pcs, 10);
      });
    }

    return totalPcs;
  }

  function calculateTotalWeight(data) {
    let totalWeight = 0.0;

    if (data && data.listJangadDetails) {
      data.listJangadDetails.forEach((item) => {
        totalWeight += parseFloat(item.weight);
      });
    }

    return totalWeight.toFixed(2);
  }


  const calculateTotalRWeight = (data) => {
    return data.listJangadDetails.reduce(
      (total, item) => total + (item.r_Weight || 0),
      0
    );
  };

  let localTotalPer = 0;
  let ListColumn =
    data.listJangadDetails && data.listJangadDetails.length > 22
      ? data.listJangadDetails.length
      : 22;

  return (
    <div className="">
      <table className="table" style={TableBottomMargin}>
        <tbody>
          <tr>
            <td
              style={{
                width: "20%",
                textAlign: "center",
                ...borderWithBottomNone,
                ...NormalFontSize,
              }}
              className=" align-middle "
            >
              <img src={Logo} alt="" style={{ width: "100px" }} />
            </td>
            <td
              style={{
                width: "80%",
                textAlign: "center",
                ...borderWithBottomNone,
                ...NormalFontSize,
              }}
            >
              <strong>|| શ્રી.૧| || </strong>
              <h1 style={{ fontSize: "34px" }}>ASIANLAB GROWN DIAMONDS LLP</h1>
              <p style={{ ...NormalFontSize }}>
                {companyData[0].companyAddress }
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      {/* ======Second Table code ==================================================================================*/}

      <table className="table" style={TableBottomMargin}>
        <tr>
          <td
            style={{
              width: "40%",
              ...borderWithBottomNone,
              fontSize: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "80px",
              }}
            >
              <div>
                <strong>Party Name: &nbsp;</strong>
                {data.list.partyName}
              </div>
              <div>
                <strong>Reference By: &nbsp;</strong>
                {data.list.hastakName}
              </div>
              <div>
                <strong>Contact.No:&nbsp;</strong>
                {data.list.contactNumber}
              </div>
            </div>
          </td>
          {/* ======Invoice Information code ==================================================================================*/}

          <td
            style={{
              width: "40%",
              ...borderWithBottomNone,
              fontSize: "15px",
            }}
          >
            <div style={{ marginBottom: "10px" }}>
              <strong>&nbsp;Jangad No:&nbsp;</strong>
              {/* {invoiceData.invoiceNo} */} {data.list.jangadNo}
            </div>
            <div>
              <strong>&nbsp;Date:&nbsp;</strong>
              {convertDateFormatWithOutTime(data.list.date)}{" "}
            </div>
          </td>
          <td
            style={{
              width: "12%",
              ...borderWithBottomNone,
              fontSize: "15px",
            }}
          >
            <QRCode
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={data.list.jangadNo}
            />
          </td>
        </tr>
        {/* ======charge table code ==================================================================================*/}
      </table>
      {/* <table
        className="table table-bordered border-dark"
        style={TableBottomMargin}
      >
        <thead>
          <tr>
            <th style={{ width: "50px" }} className="text-center">
              <strong>Sr.No.</strong>
            </th>
            <th style={{ textAlign: "center" }} className="text-center">
              <strong>
                <i className="fa fa-list-alt"></i>&nbsp;BagNo
              </strong>
            </th>
            <th style={{ textAlign: "center" }} className="text-center">
              <strong>Stone Type</strong>
            </th>
            <th style={{ textAlign: "center" }} className="text-center">
              <strong>
                Pcs 
              </strong>
            </th>
            <th style={{ textAlign: "center" }} className="text-center">
              <strong>
                Weight
              </strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.listJangadDetails &&  Array.from(
                    {
                      length:
                      data.listJangadDetails.pcs > 40
                          ?  data.listJangadDetails.pcs
                          : 40,
                    },
                    (_, index ,item) => (
            <tr key={index}>
              <td style={{ textAlign: "center" }} className="text-center">
                {index + 1}
              </td>
              <td
                style={{ textAlign: "center" }}
                className="text-left"
              >
                <span>{item.bagNo}</span>
              </td>
              <td style={{ textAlign: "center" }} className="text-center">
                <span>King</span>
              </td>
              <td style={{ textAlign: "center" }} className="text-center">
                <span>
                  {item.pcs}
                </span>
              </td>
              <td style={{ textAlign: "center" }} className="text-center">
                <span>
                  {item.weight}
                </span>
              </td>
            </tr>
          ))}
         
        </tbody>
      </table> */}
      <table
        className="table table-bordered border-dark"
        style={TableBottomMargin}
      >
        <thead>
          <tr>
            <th style={{ width: "20px" }} className="text-center">
              <strong>No.</strong>
            </th>
            <th
              style={{ textAlign: "center", width: "130px" }}
              className="text-center"
            >
              <strong>ASINo</strong>
            </th>
            <th
              style={{ textAlign: "center", width: "50px" }}
              className="text-center"
            >
              <strong>Pcs</strong>
            </th>
            <th style={{ textAlign: "center" }} className="text-center">
              <strong>Rough Weight</strong>
            </th>
            {/* <th style={{ textAlign: "center" }} className="text-center">
              <strong>Clarity</strong>
            </th> */}
            <th
              style={{ textAlign: "center", width: "150px" }}
              className="text-center"
            >
              <strong>Color</strong>
            </th>
            <th
              style={{ textAlign: "center", width: "150px" }}
              className="text-center"
            >
              <strong>Polish Weight</strong>
            </th>
            <th
              style={{ textAlign: "center", width: "50px" }}
              className="text-center"
            >
              <strong>%</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: ListColumn }, (_, index) => {
            const rowData =
              (data.listJangadDetails && data.listJangadDetails[index]) || {};
            const {
              stoneNo = "",
              bagNo = "",
              pcs = "",
              weight = "",
              // clarityMsaterName = "",
              r_Weight,
              shapeAndColorRemarks = "",
              rW_Weight,
              weightRemarks = "",
              colorMasterName = "",
            } = rowData;
            //const isAsiToSingle = remarks === "ASI TO SINGLE";
            const isAsiToSingle = stoneNo !== bagNo;

            const Per =
              isAsiToSingle && rW_Weight ? (r_Weight / rW_Weight) * 100 : null;

            if (Per !== null) {
              localTotalPer = localTotalPer + Per;
            }

            return (
              <tr key={index}>
                <td
                  style={{ textAlign: "center", padding: "3px" }}
                  className="text-center"
                >
                  {index + 1}
                </td>
                <td
                  style={{ textAlign: "center", padding: "3px" }}
                  className="text-left"
                >
                  <span>{stoneNo}</span>
                </td>
                <td
                  style={{ textAlign: "center", padding: "3px" }}
                  className="text-center"
                >
                  <span>{pcs}</span>
                </td>
                <td
                  style={{ textAlign: "center", padding: "3px" }}
                  className="text-center"
                >
                  <span> {weight ? addDecimalPlaces(weight, 2) : ""}</span>
                </td>
                {/* <td
                  style={{ textAlign: "center", padding: "3px" }}
                  className="text-center"
                >
                  <span>{clarityMsaterName}</span>
                </td> */}
                <td
                  style={{ textAlign: "center", padding: "3px" }}
                  className="text-center"
                >
                  <span>
                    {isAsiToSingle ? colorMasterName : shapeAndColorRemarks}
                  </span>
                </td>
                <td
                  style={{ textAlign: "center", padding: "3px" }}
                  className="text-center"
                >
                  <span>
                    {isAsiToSingle
                      ? r_Weight > 0
                        ? r_Weight.toFixed(2)
                        : ""
                      : weightRemarks}
                  </span>
                </td>
                <td
                  style={{ textAlign: "center", padding: "3px" }}
                  className="text-center"
                >
                  <span>{Per !== null ? `${Per.toFixed(2)}%` : ""}</span>
                </td>
              </tr>
            );
          })}

          <tr>
            <td colSpan="2" style={{ textAlign: "left" }} className="text-left">
              Total
            </td>
            <td style={{ textAlign: "center" }} className="text-center">
              {calculateTotalPcs(data)}
            </td>
            <td style={{ textAlign: "center" }} className="text-center">
              {calculateTotalWeight(data)}
            </td>
            <td style={{ textAlign: "center" }} className="text-center">
              <div>-</div>
            </td>
            <td style={{ textAlign: "center" }} className="text-center">
              {calculateTotalRWeight(data).toFixed(2)}
            </td>
            {/* <td style={{ textAlign: "center" }} className="text-center">
              {calculateTotalRWWeight(data).toFixed(2)}
            </td> */}
            <td style={{ textAlign: "center" }} className="text-center">
              {localTotalPer !== null
                ? (localTotalPer / data.listJangadDetails.length).toFixed(2)
                : null}
              %
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table">
        <tbody>
          <tr>
            <td
              style={{
                width: "50%",
                border: "1px solid",
                borderTop: "none",
                ...NormalFontSize,
              }}
            >
              <div
                className="text-start"
                style={{ marginTop: "60px", fontSize: "16px" }}
              >
                <strong>&nbsp;For, Issue Signature&nbsp;</strong>
              </div>
            </td>
            <td
              style={{
                width: "50%",
                border: "1px solid",
                borderTop: "none",
                ...NormalFontSize,
              }}
            >
              <div
                className="text-end"
                style={{ marginTop: "60px", fontSize: "16px" }}
              >
                <strong>&nbsp;For, Receive Signature &nbsp;</strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
