import { useState } from "react";
import { useZxing } from "react-zxing";

const BarcodeScanner = ({ sendDataToParent }) => {
  const [barcodeValue, setBarcodeValue] = useState("");
  const { ref } = useZxing({
    onDecodeResult(result) {
      if (barcodeValue !== result.getText()) {
        setBarcodeValue(result.getText());
        sendDataToParent(result.getText());
      }
    },
  });

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <video
          class="col-12 col-md-6 col-lg-3"
          style={{ margin: "20px" }}
          ref={ref}
        />
      </div>
    </>
  );
};
export default BarcodeScanner;
