import React, { useState, useEffect } from "react";
import CurrentstockofuserWithOutBag from "./CurrentstockofuserWithOutBag";
import CurrentBagstockofuser from "./CurrentBagstockofuser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import api from "../../Utils/api";
import Select from "react-select";

export default function Currentstockofuser() {
  const [selectedSubUserID, setSelectedSubUserID] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [subUsers, setSubUsers] = useState([]);

  useEffect(() => {
    getSubUser();
  }, []);

  const getSubUser = () => {
    api
      .get("UserAssigment/LoginUserSubUser")
      .then((result) => {
        setSubUsers(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubUserSelect = (selectedOption) => {
    setSelectedSubUserID(selectedOption.value);
    setSelectedOption(selectedOption);
  };

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-end"
        // style={{ padding: "10px 0px 0px 10px", marginBottom: "0px" }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "10px" }}
        >
          <label style={{ marginRight: "10px" }}>User: </label>
          <Select
            options={subUsers.map((option) => ({
              value: option.subUserID,
              label: option.subUserName,
            }))}
            value={selectedOption}
            onChange={handleSubUserSelect}
            placeholder="Select SubUser"
            required
            styles={{
              control: (base) => ({ ...base, width: "300px" }), // Adjust the width as needed
            }}
          />
        </div>
        <Button
          style={{ marginRight: "10px" }}
          onClick={() => {
            setSelectedSubUserID("");
            setSelectedOption("");
          }}
        >
          <i className="fa fa-refresh"></i>{" "}
        </Button>
      </div>
      <ToastContainer />
      <Card>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          variant="underline"
        >
          <Tab eventKey="home" title="Current Stock" className="mr-4 ">
            <CurrentstockofuserWithOutBag subUserID={selectedSubUserID} />
          </Tab>
          <Tab eventKey="profile" title="Final Stock">
            <CurrentBagstockofuser  subUserID={selectedSubUserID}/>
          </Tab>
        </Tabs>
      </Card>
    </>
  );
}
