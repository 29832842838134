import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { Card, Form, Button } from "react-bootstrap";

export default function Department() {
  const [departmentData, setDepartmentData] = useState([]);

  useEffect(() => {
    getDepartment();
  }, []);

  // Fetch all departments
    const getDepartment = () => {
      api
        .get("Department/All")
        .then((result) => {
          setDepartmentData(result.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

  // Insert & Update Data
  const formik = useFormik({
    initialValues: {
      department_id: 0,
      department_name: "",
    },
    validationSchema: Yup.object({
      department_name: Yup.string()
        .min(3, "Department Name must be at least 3 characters")
        .required("Enter Department Name"),
    }),
    validateOnChange: true, // Enable validation on change
    validateOnBlur: true, // Enable validation on blur
    onSubmit: (values, { resetForm }) => {
      const data = {
        departmentID: values.department_id,
        departmentName: values.department_name,
      };

      const endpoint =
        values.department_id === 0 ? "Department/Insert" : "Department/Edit";

      api[values.department_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          getDepartment();
          values.department_id === 0
            ? toast.success(result.data.message)
            : toast.success(result.data.message);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
      resetForm();
    },
  });

  // Get data of a single department
  const getSingleDepartment = (id) => {
    api
      .get(`Department/ByID?DepartmentID=${id}`)
      .then((result) => {
        const departmentData = result.data.data;
        setTimeout(() => {
          formik.setFieldValue(
            "department_name",
            departmentData.departmentName
          );
          formik.setFieldValue("department_id", departmentData.departmentID);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete department
  const deleteDepartment = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Department/Delete?DepartmentID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getDepartment();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "Department Name",
      field: "departmentName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Edit",
      width: 100,
      field: "departmentID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleDepartment(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 100,
      field: "departmentID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteDepartment(value)}
        ></ion-icon>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />
      {/* <div className="card">
        <div className="card-header">
          <span>New Department</span>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row col-md-12">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="department_name" className="form-label">
                  Department Name:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  id="department_name"
                  placeholder="Department Name"
                  {...formik.getFieldProps("department_name")}
                />
                {formik.touched.department_name &&
                  formik.errors.department_name && (
                    <div className="text-danger">
                      {formik.errors.department_name}
                    </div>
                  )}
              </div>
              <label id="department_name"></label>
            </div>
            <div className="card-footer margin_top10">
              <button type="submit" className="btn btn-primary mb-20px">
                {formik.values.department_id > 0 ? "Update" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
       */}
      <Card>
        <Card.Header>
          <span>New Department</span>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <Form.Group className="row col-md-12">
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="department_name"
              >
                Department Name:
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Control
                  type="text"
                  id="department_name"
                  placeholder="Department Name"
                  {...formik.getFieldProps("department_name")}
                />
                {formik.touched.department_name &&
                  formik.errors.department_name && (
                    <div className="text-danger">
                      {formik.errors.department_name}
                    </div>
                  )}
              </div>
            </Form.Group>
            <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                {formik.values.department_id > 0 ? "Update" : "Save"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      {/* <div className="card">
        <div className="card-header">
          <span>Department List</span>
        </div>
        <div className="card-body">
          <AgGridComponent
            columnDefs={columnDefs}
            rowData={departmentData}
          ></AgGridComponent>
        </div>
      </div> */}
      <Card>
        <Card.Header>
          <span>Department List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            columnDefs={columnDefs}
            rowData={departmentData}
            paginationPageSize={100}
            height="55vh"
          ></AgGridComponent>
        </Card.Body>
      </Card>
    </>
  );
}
