import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux'
import { loginUser } from '../../Features/userSlice';
import "./Login.css";

const Login = () => {
    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()

    const CheckLogin = async (e) => {
        
        e.preventDefault();
        if (validate()) {
            const data = {
                "userName": username,
                "password": password,
            }
            dispatch(loginUser(data))
        }
    }

    const validate = () => {
        let result = true;
        if (username === '' || username === null) {
            result = false;
        }
        if (password === '' || password === null) {
            result = false;
        }
        return result;
    }

    return (
        <>
            <ToastContainer />
            <div className='LoginSection'>
                <div className="form-box">
                    <div className="form-value">
                        <form>
                            <h2 className='heading'>Login</h2>
                            <div className="input-box">
                                <ion-icon name="mail-outline"></ion-icon>
                                <input type="text" value={username} onChange={(e) => setUserName(e.target.value)} placeholder="User Name" />
                            </div>
                            <div className="input-box">
                                <ion-icon name="lock-closed-outline"></ion-icon>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                            </div>
                            <button className="btntheam" type="submit" onClick={CheckLogin}>Login</button>
                        </form>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Login
