import { React, useEffect, useState } from "react";
import LightGalleryComponent from "./LightGalleryComponent";
import Swal from "sweetalert2";
import api from "../../Utils/api";
import UserSetting from "../../UserSettings.json";

const ImageViewerModalComponent = ({
  showModal,
  onClose,
  referenceID,
  refType,
}) => {
  const [isModalOpenForViewImage, setIsModalOpenForViewImage] =
    useState(showModal);
  const [imageList, setImageList] = useState([]);

  //const imgURL = "https://localhost:5071";

  useEffect(() => {
    if (showModal) {
      popUpForViewImage(referenceID, refType);
    }
  }, [showModal, referenceID, refType]);

  const popUpForViewImage = (id) => {
    api
      .get(
        `ImagesForAll/ViewImagesByReferenceWise?BatchNo=${id}&ReferenceType=${refType}`
      )
      .then((result) => {
        ;
        if (result.data.flag) {
          setImageList(result.data.data);
          setIsModalOpenForViewImage(true);
        } else {
          setIsModalOpenForViewImage(false);
          Swal.fire("Error", result.data.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire("Error", "An Error", "error");
      });
  };

  const handleCloseModalForImageViewer = () => {
    setIsModalOpenForViewImage(false);
    onClose(); // Call the onClose function to close the modal
  };

  return (
    isModalOpenForViewImage && (
      <div
        className={`modal fade ${
          isModalOpenForViewImage ? "show d-block" : ""
        }`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={!isModalOpenForViewImage}
        style={{ zIndex: isModalOpenForViewImage ? "1000" : "-1" }}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Image Viewer</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModalForImageViewer}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="row col-md-12">
                  <LightGalleryComponent
                    imageList={imageList}
                    imgURL={UserSetting.imgURL}
                  ></LightGalleryComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ImageViewerModalComponent;
