import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import { Card, Form, Button, Col, Row, InputGroup } from "react-bootstrap";

export default function UserProfile() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    user_id: 0,
    user_name: "",
    password: "",
  });

  useEffect(() => {
    getSingleUser();
  }, []);

  // Fetch all Users
  const getSingleUser = () => {
    const USER_ID = localStorage.getItem("user_ID");
    api
      .get(`User/ByID?UserID=${USER_ID}`)
      .then((result) => {
        const UserData = result.data.data;
        setFormData({
          user_id: UserData.userID,
          user_name: UserData.userName,
          password: UserData.password,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        userID: formData?.user_id,
        userName: formData.user_name,
        password: formData.password,
      };
      api
        .patch("User/UpdateUserProfile", data)
        .then((result) => {
          setValidated(false); // Reset validation state
          formData?.user_id === 0
            ? toast.success(result.data.message)
            : toast.success(result.data.message);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Card>
        <Card.Header>
          <span>User Profile</span>
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col md={4} sm={2} lg={4}>
                <Form.Group controlId="user_name">
                  <strong htmlFor="user_name">User Name</strong>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fas fa-user"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="user_name"
                      placeholder="Enter User Name"
                      value={formData.user_name}
                      onChange={handleInputChange}
                      required
                      disabled
                    />
                    {validated && !formData.client_name && (
                      <div className="invalid-feedback">
                        Please Enter User Name.
                      </div>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4} sm={2} lg={4}>
                <Form.Group controlId="password">
                <strong htmlFor="password">Password</strong>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fas fa-lock"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                    {validated && !formData.client_name && (
                      <div className="invalid-feedback">
                        Please Enter Password.
                      </div>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                {formData?.user_id > 0 ? "Update" : "Save"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
