import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Utils/api";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import Swal from "sweetalert2";

export default function RoleTemplate() {
  const [listOfForms, setListOfForms] = useState([]);
  const [roleTemplateName, setRoleTemplateName] = useState("");
  const [roleTemplateID, setRoleTemplateID] = useState(0);
  const [roleTemplateDetailsData, setRoleTemplateDetailsData] = useState([]);
  const [selectAllView, setSelectAllView] = useState(false);
  const [selectAllAdd, setSelectAllAdd] = useState(false);
  const [selectAllEdit, setSelectAllEdit] = useState(false);
  const [selectAllDelete, setSelectAllDelete] = useState(false);

  useEffect(() => {
    getForm();
    getRoleTemplateData();
  }, []);

  // Fetch all departments
  const getForm = () => {
    
    api
      .get("Form/All")
      .then((result) => {
        const data = result.data?.data || [];
        setListOfForms(
          data.map((form) => ({
            formID: form.formID,
            formName: form.formName,
            viewPermission: false,
            addPermission: false,
            editPermission: false,
            deletePermission: false,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckboxChange = (formID, field) => {
    setListOfForms((prevForms) =>
      prevForms.map((item) =>
        item.formID === formID ? { ...item, [field]: !item[field] } : item
      )
    );
  };

  // Save role template details
  const saveOrUpdateRoleTemplateDetails = () => {
    ;
    if (!roleTemplateName.trim()) {
      toast.error("Role template name is required.");
      return;
    }

    if (!roleTemplateID || roleTemplateID === 0) {
      // Insert new role template
      api
        .post("RoleTemplateDetail/Insert", {
          RoleTemplateDetailList: listOfForms,
          RoleTemplateName: roleTemplateName,
          RoleTemplateID: roleTemplateID,
        })
        .then((response) => {
          getForm();
          setRoleTemplateName("");
          setRoleTemplateID(0);
          getRoleTemplateData();
          setSelectAllView(false);
          setSelectAllAdd(false);
          setSelectAllEdit(false);
          setSelectAllDelete(false);
          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error("Failed to save role template details.");
          console.log(error);
        });
    } else {
      // Update existing role template
      api
        .patch(`RoleTemplateDetail/Edit`, {
          RoleTemplateDetailList: listOfForms,
          RoleTemplateName: roleTemplateName,
          RoleTemplateID: roleTemplateID,
        })
        .then((response) => {
          getForm();
          setRoleTemplateName("");
          setRoleTemplateID(0);
          getRoleTemplateData();
          setSelectAllView(false);
          setSelectAllAdd(false);
          setSelectAllEdit(false);
          setSelectAllDelete(false);
          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error("Failed to update role template details.");
          console.log(error);
        });
    }
  };

  const getRoleTemplateData = () => {
    api
      .get("RoleTemplate/All")
      .then((result) => {
        setRoleTemplateDetailsData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSingleRoleTemplateDetail = (id) => {
    
    api
      .get(`RoleTemplateDetail/ByID?RoleTemplateID=${id}`)
      .then((result) => {
        ;
        const RoleTemplateData = result.data.data;
        const uniqueFormIDs = [
          ...new Set(RoleTemplateData.map((form) => form.formID)),
        ];
        api.get(`Form/All`).then((formResult) => {
          const allForms = formResult.data.data;
          // Filter out forms that are not in RoleTemplateData
          const missingForms = allForms.filter(
            (form) => !uniqueFormIDs.includes(form.formID)
          );
          const updatedListOfForms = RoleTemplateData.map((form) => ({
            roleTemplateDetailID: form.roleTemplateDetailID,
            formID: form.formID,
            formName: form.formName,
            viewPermission: form.viewPermission,
            addPermission: form.addPermission,
            editPermission: form.editPermission,
            deletePermission: form.deletePermission,
          }));

          // Add missing forms to the updated list
          updatedListOfForms.push(
            ...missingForms.map((form) => ({
              roleTemplateDetailID: 0,
              formID: form.formID,
              formName: form.formName,
              viewPermission: false,
              addPermission: false,
              editPermission: false,
              deletePermission: false,
            }))
          );

          // Sort the updatedListOfForms by formName in alphabetical order
          updatedListOfForms.sort((a, b) =>
            a.formName.localeCompare(b.formName)
          );
          setRoleTemplateName(RoleTemplateData[0].roleTemplateName);
          setRoleTemplateID(RoleTemplateData[0].roleTemplateID);
          setListOfForms(updatedListOfForms);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteRoleTemplateDetail = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`RoleTemplateDetail/Delete?RoleTemplateID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getRoleTemplateData();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Role Template Name",
      field: "roleTemplateName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Edit",
      width: 100,
      field: "roleTemplateID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleRoleTemplateDetail(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 100,
      field: "roleTemplateID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteRoleTemplateDetail(value)}
        ></ion-icon>
      ),
    },
  ];

  const handleSelectAll = (permissionType) => {
    switch (permissionType) {
      case "view":
        setListOfForms((prevForms) =>
          prevForms.map((item) => ({ ...item, viewPermission: !selectAllView }))
        );
        setSelectAllView(!selectAllView);
        break;
      case "add":
        setListOfForms((prevForms) =>
          prevForms.map((item) => ({ ...item, addPermission: !selectAllAdd }))
        );
        setSelectAllAdd(!selectAllAdd);
        break;
      case "edit":
        setListOfForms((prevForms) =>
          prevForms.map((item) => ({ ...item, editPermission: !selectAllEdit }))
        );
        setSelectAllEdit(!selectAllEdit);
        break;
      case "delete":
        setListOfForms((prevForms) =>
          prevForms.map((item) => ({
            ...item,
            deletePermission: !selectAllDelete,
          }))
        );
        setSelectAllDelete(!selectAllDelete);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>Enter Role Template</span>
        </div>
        <div className="card-body">
          <div className="row align-middle">
            <div className="col-md-2 col-sm-2 col-lg-2 align-middle">
              <label htmlFor="Find_no" className="form-label">
                Role Template:
              </label>
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4">
              <input
                type="text"
                className="form-control"
                id="Find_no"
                placeholder="Enter Role Template"
                value={roleTemplateName}
                onChange={(e) => setRoleTemplateName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <span>Role Template Details List</span>
        </div>
        <div className="card-body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>FormName</th>
                <th>
                  <div className="d-flex align-items-center">
                    Show
                    <input
                      type="checkbox"
                      className="form-check-input ms-2 mb-2"
                      checked={selectAllView}
                      onChange={() => handleSelectAll("view")}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Add
                    <input
                      type="checkbox"
                      className="form-check-input ms-2 mb-2"
                      checked={selectAllAdd}
                      onChange={() => handleSelectAll("add")}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Edit
                    <input
                      type="checkbox"
                      className="form-check-input ms-2 mb-2"
                      checked={selectAllEdit}
                      onChange={() => handleSelectAll("edit")}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Delete
                    <input
                      type="checkbox"
                      className="form-check-input ms-2 mb-2"
                      checked={selectAllDelete}
                      onChange={() => handleSelectAll("delete")}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {listOfForms &&
                listOfForms.map((item) => (
                  <tr key={item.formID}>
                    <td>{item.formName}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.viewPermission}
                        onChange={() =>
                          handleCheckboxChange(item.formID, "viewPermission")
                        }
                        className="form-check-input"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.addPermission}
                        onChange={() =>
                          handleCheckboxChange(item.formID, "addPermission")
                        }
                        className="form-check-input"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.editPermission}
                        onChange={() =>
                          handleCheckboxChange(item.formID, "editPermission")
                        }
                        className="form-check-input"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.deletePermission}
                        onChange={() =>
                          handleCheckboxChange(item.formID, "deletePermission")
                        }
                        className="form-check-input"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="card-footer margin_top10">
            <button
              type="button"
              className="btn btn-primary mb-20px"
              onClick={saveOrUpdateRoleTemplateDetails}
            >
              {roleTemplateID === 0 ? "Save" : "Update"}
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <span>Role Template List</span>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={roleTemplateDetailsData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="50vh"
          ></AgGridComponent>
        </div>
      </div>
    </>
  );
}
