import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Modal,
  InputGroup,
} from "react-bootstrap";
import Select from "react-select";
import api from "../../Utils/api";
import PartyMasterDD from "../PartyMaster/PartyMasterDD";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const emptyFormData = {
  purchase_date: new Date().toISOString().slice(0, 10),
  partyMaster_ID: 0,
  stonetype_id: null,
  pcs: 0,
  length: 0,
  width: 0,
  height: 0,
  weight: 0,
};

export default function Purchase() {
  const [formData, setFormData] = useState(emptyFormData);
  const [showPopup, setShowPopup] = useState(false);
  const [partyMasterName, setPartyMasterName] = useState([]);
  const [stoneTypeName, setStoneTypeName] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);
  const [selectedStonetype, setSelectedStonetype] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [purchaseAggridData, setPurchaseAggridData] = useState([]);
  const [purchaseDataFilter, setPurchaseDataFilter] = useState({
    start_date: new Date().toISOString().substr(0, 10),
    end_date: new Date().toISOString().substr(0, 10),
  });

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setPurchaseDataFilter((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    getAllPartyMaster();
    getStoneTypeName();
    getPurchaseDataAggrid();
  }, []);

  const getPurchaseDataAggrid = () => {
    api
      .get("Purchase/AllPuchase")
      .then((result) => {
        setPurchaseAggridData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllPartyMaster = async () => {
    try {
      const result = await api.get("PartyMaster/All");
      setPartyMasterName(result.data.data);
    } catch (error) {
      console.error("Error fetching party master data:", error);
      toast.error("An error occurred while fetching party data.");
    }
  };

  const getStoneTypeName = async () => {
    try {
      const result = await api.get("StoneType/All");
      setStoneTypeName(result.data.data);
    } catch (error) {
      console.error("Error fetching stone type data:", error);
      toast.error("An error occurred while fetching stone type data.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePartyMasterChange = (selectedOption) => {
    setSelectedParty(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      partyMaster_ID: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleStoneTypeChange = (selectedOption) => {
    setSelectedStonetype(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      stonetype_id: selectedOption ? selectedOption.value : null,
    }));
  };

  const selectDDPartyMaster = (partyMasterID) => {
    getAllPartyMaster()
      .then((data) => {
        const selectedParty = data.find(
          (f) => f.partyMasterID === partyMasterID
        );
        if (selectedParty) {
          var option = {
            value: partyMasterID,
            label: selectedParty.partyName,
          };
          handlePartyMasterChange(option);
          setShowPopup(false); // Close the modal after selecting a party
        } else {
          console.error("Party master not found for ID:", partyMasterID);
          // Optionally, handle the case where party master is not found
        }
      })
      .catch((error) => {
        console.error("Error fetching party master data:", error);
        // Optionally, handle the error
      });
  };

  const insertPurchase = () => {
    debugger;
    if (!formData.partyMaster_ID) {
      toast.error("Please Select party.");
      return;
    }
    if (!formData.purchase_date) {
      toast.error("Please Select Date.");
      return;
    }
    if (formData.pcs <= 0) {
      toast.error("Please Enter valid PCS.");
      return;
    }
    setShowLoader(true);

    const data = {
      PartyMasterID: selectedParty.value,
      PurchaseDate: formData.purchase_date,
      StoneType: selectedStonetype ? selectedStonetype.label : null,
      Pcs: formData.pcs,
      Length: formData.length,
      Width: formData.width,
      Height: formData.height,
      Weight: formData.weight,
    };
    api
      .post("Purchase/PurchaseInsert", data)
      .then((result) => {
        toast.success(result.data.message);
        resetForm();
        getPurchaseDataAggrid();
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const DeletePurchase = (id) => {
    debugger;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Purchase/PurchaseDelete?PurchaseID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getPurchaseDataAggrid();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const resetForm = () => {
    setFormData(emptyFormData);
    setSelectedParty(null);
    setSelectedStonetype(null);
  };

  const getPurchaseDataDateWise = () => {
    debugger;
    api
      .get(
        `Purchase/AllPurchaseDataDateWise?START_DATE=${purchaseDataFilter.start_date}&END_DATE=${purchaseDataFilter.end_date}`
      )
      .then(({ data }) => setPurchaseAggridData(data.data))
      .catch((error) => {
        console.error("Error fetching Jangad data:", error);
      });
  };

  // export exel function
  const exportToExcel = () => {
    setShowLoader(true);

    setTimeout(async () => {
      try {
        const fileName = "Purchase.xlsx";

        // Format the data to be exported, including the date and time
        const formattedData = purchaseAggridData.map((item) => {
          return {
            PurchaseDate: new Date(item.purchaseDate).toLocaleDateString(
              "en-GB"
            ),
            StoneType: item.stoneType,
            Pcs: item.pcs,
            Length: item.length,
            Width: item.width,
            Height: item.height,
            Weight: item.weight,
          };
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Issue Machine List");

        // Set up the columns
        worksheet.columns = [
          { header: "Purchase Date", key: "PurchaseDate", width: 15 },
          { header: "Stone Type", key: "StoneType", width: 15 },
          { header: "PCS", key: "Pcs", width: 10 },
          { header: "Length", key: "Length", width: 10 },
          { header: "Width", key: "Width", width: 10 },
          { header: "Height", key: "Height", width: 10 },
          { header: "Weight", key: "Weight", width: 15 },
        ];

        // Add the rows to the worksheet
        worksheet.addRows(formattedData);

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF4F81BD" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Adjust column widths based on content length
        worksheet.columns.forEach((column) => {
          let maxLength = column.header.length;
          formattedData.forEach((row) => {
            const cellValue = row[column.key];
            if (cellValue) {
              maxLength = Math.max(maxLength, cellValue.toString().length);
            }
          });
          column.width = maxLength + 2; // Add padding
        });

        // Export the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        // Handle any error here, such as showing an error message to the user
      } finally {
        setShowLoader(false);
      }
    }, 1000);
  };

  const columnDefs = [
    {
      headerName: "PurchaseDate",
      field: "purchaseDate",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          if (isNaN(date.getTime())) {
            return params.value; // Return the original value if it's not a valid date
          } else {
            const formattedDate = date.toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            return formattedDate;
          }
        }
        return params.value; // Return the original value if it's empty or undefined
      },
    },
    {
      headerName: "StoneType",
      field: "stoneType",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pcs",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Length",
      field: "length",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Width",
      field: "width",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Height",
      field: "height",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Delete",
      width: 80,
      field: "purchaseID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => DeletePurchase(value)}
        ></ion-icon>
      ),
      pinned: "right",
    },
  ];

  return (
    <>
      <ToastContainer />
      <Card>
        <Card.Header>
          <span>Purchase</span>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={4}>
                <div className="d-flex align-items-center mt-4">
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label style={{ marginRight: "20px" }}>
                        Party:
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8">
                    <div className="mb-3 d-flex justify-content-between">
                      <Select
                        value={selectedParty}
                        onChange={handlePartyMasterChange}
                        options={partyMasterName.map((option) => ({
                          value: option.partyMasterID,
                          label: option.partyName,
                        }))}
                        placeholder="--Select Party--"
                      />
                      <div className="selectEndIcon input-group-text">
                        <i
                          className="fa fa-plus plusIcon"
                          onClick={() => setShowPopup(true)}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="d-flex align-items-center mt-4">
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label style={{ marginRight: "20px" }}>
                        Purchase Date:
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8">
                    <Form.Group>
                      <Form.Control
                        type="date"
                        name="purchase_date"
                        value={formData.purchase_date}
                        onChange={handleInputChange}
                        placeholder="Select Date"
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="d-flex align-items-center mt-4">
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label style={{ marginRight: "20px" }}>
                        Stone Type:
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8">
                    <div className="mb-3 d-flex justify-content-between">
                      <Select
                        value={selectedStonetype}
                        onChange={handleStoneTypeChange}
                        options={stoneTypeName.map((option) => ({
                          value: option.stoneTypeID,
                          label: option.stoneTypeName,
                        }))}
                        placeholder="--Select Stone Type--"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="d-flex align-items-center mt-4">
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label style={{ marginRight: "20px" }}>
                        PCS:
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8">
                    <Form.Group>
                      <Form.Control
                        type="number"
                        name="pcs"
                        placeholder="Enter PCS"
                        value={formData.pcs}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="d-flex align-items-center mt-4">
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label style={{ marginRight: "20px" }}>
                        Length:
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8">
                    <Form.Group>
                      <Form.Control
                        type="number"
                        name="length"
                        placeholder="Enter Length"
                        value={formData.length}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="d-flex align-items-center mt-4">
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label style={{ marginRight: "20px" }}>
                        Width:
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8">
                    <Form.Group>
                      <Form.Control
                        type="number"
                        name="width"
                        placeholder="Enter Width"
                        value={formData.width}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="d-flex align-items-center mt-4">
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label style={{ marginRight: "20px" }}>
                        Height:
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8">
                    <Form.Group>
                      <Form.Control
                        type="number"
                        name="height"
                        placeholder="Enter Height"
                        value={formData.height}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="d-flex align-items-center mt-4">
                  <div className="col-md-4">
                    <Form.Group>
                      <Form.Label style={{ marginRight: "20px" }}>
                        Weight:
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8">
                    <Form.Group>
                      <Form.Control
                        type="number"
                        name="weight"
                        placeholder="Enter Weight"
                        value={formData.weight}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <div className="card-footer margin_top10">
          <Button type="button" variant="primary" onClick={insertPurchase}>
            Save
          </Button>
        </div>
      </Card>

      <Card>
        <Card.Header>
          <span>Purchase Data</span>
        </Card.Header>
        <Card.Header>
          <Row className="justify-content-end">
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <b className="">Start</b>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={purchaseDataFilter.start_date}
                    onChange={handleDateChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Expected start_date
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <b className="">End</b>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={purchaseDataFilter.end_date}
                    onChange={handleDateChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Expected end_date
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} className="text-end">
              <Button variant="primary" onClick={getPurchaseDataDateWise}>
                Search
              </Button>
              <Button
                type="button"
                variant="success"
                className="btn btn-primary"
                onClick={exportToExcel}
                style={{ marginLeft: "10px" }}
              >
                <i class="fa fa-file-excel"></i> Export to Excel
              </Button>
            </Col>
          </Row>
        </Card.Header>

        <Card.Footer>
          <AgGridComponent
            rowData={purchaseAggridData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="70vh"
          />
        </Card.Footer>
      </Card>

      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        size="xl"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Party</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <Form.Group>
              <PartyMasterDD
                partyMasterID={selectDDPartyMaster}
              ></PartyMasterDD>
            </Form.Group>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
