import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Header/Header";
import Department from "./Pages/Department/Department";
import Login from "./Pages/Login/Login";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import Protected from "./Features/Protected";
import Logout from "./Pages/Logout/Logout";
import Loader from "./Components/Common/Loader";
// import UserType from './Pages/UserType/UserType';
// import Form from './Pages/Form/Form';
import Home from "./Pages/Home/Home";
import Machine from "./Pages/Machine/Machine";
import Stone from "./Pages/Stone/Stone";
import GenerateBatchNo from "./Pages/GenerateBatchNo/GenerateBatchNo";
import Currentstockofuser from "./Pages/Current stock of user/Currentstockofuser";
import IssueToDepartment from "./Pages/IssueToDepartment/IssueToDepartment";
import Receivewindow from "./Pages/Receivewindow/Receivewindow";
import UserMaster from "./Pages/UserMaster/UserMaster";
import RoleTemplate from "./Pages/RoleTemplate/RoleTemplate";
import Worker from "./Pages/Worker/Worker";
import IssueToWorker from "./Pages/IssueToWorker/IssueToWorker";
import MachineDashboard from "./Pages/MachineDashboard/MachineDashboard";
import ReceiveToWorker from "./Pages/ReceiveToWorker/ReceiveToWorker";
import IssueToMachine from "./Pages/IssueToMachine/IssueToMachine";
import ReceiveFromMachine from "./Pages/ReceiveFromMachine/ReceiveFromMachine";
import GenerateMixNo from "./Pages/GenerateMixNo/GenerateMixNo";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import api from "./Utils/api";
import { StonesProvider } from "./contextFile/StoneContext/StonesContext";
import StoneType from "./Pages/StoneType/StoneType";
import { handleKeyDown } from "./Utils/commonfunctions";
import MachineReport from "./Pages/MachineReport/MachineReport";
import UserProfile from "./Pages/UserProfile/UserProfile";
import SplitStone from "./Pages/SplitStone/SplitStone";
import IssueAndReceiveReport from "./Pages/IssueAndReceiveReport/IssueAndReceiveReport";
import MixStoneReport from "./Pages/MixStoneReport/MixStoneReport";
import TransferToFinal from "./Pages/Transfer To Final/TransferToFinal";
import ReceiveFromFinal from "./Pages/Receive From Final/ReceiveFromFinal";
import GenerateBag from "./Pages/Generate Bag/GenerateBag";
import PartyMaster from "./Pages/PartyMaster/PartyMaster";
import Jangad from "./Pages/Jangad/Jangad";
import Purchase from "./Pages/Purchase/Purchase";
import SingleStoneDataEntry from "./Pages/SingleStoneDataEntry/SingleStoneDataEntry";
import ColorMaster from "./Pages/ColorMaster/ColorMaster";
import ClarityMaster from "./Pages/ClarityMaster/ClarityMaster";
import LoaderWithLogo from "./Components/Common/LoaderWithLogo";

function App() {
  const [userName, setUserName] = useState("");
  let loginToken = localStorage.getItem("token");
  const [rolePermissionData, setRolePermissionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingComponent, setIsLoadingComponent] = useState(false);

  useEffect(() => {
    if (loginToken) {
      setIsLoading(true);
      ;
      CheckIsTokenExpired()
        .then((IsTokenNotExpired) => {
          if (IsTokenNotExpired) {
            const storedUserName = localStorage.getItem("userName");
            if (storedUserName) {
              setUserName(storedUserName);
            }
            getRolePermission();

            document.addEventListener("keydown", handleKeyDown);
            return () => {
              document.removeEventListener("keydown", handleKeyDown);
            };
          } else {
            window.location.href = "/logout";
          }
        })
        .catch((error) => {
          console.error("Error checking token expiration:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [loginToken]);

  const CheckIsTokenExpired = () => {
    return api
      .post(`Login/IsTokenExpired?token=${loginToken}`)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        throw error; // Rethrow the error to be caught in the useEffect
      });
  };

  // useEffect(() => {
  //   getRolePermission();

  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  // Fetch all departments
  const getRolePermission = async () => {
    setIsLoadingComponent(true);
    await api
      .get("RoleTemplateDetail/ViewPermissionWiseFormShow")
      .then((result) => {
        setRolePermissionData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingComponent(false);
      });
  };

  const checkViewPermission = (permissionName) => {
    return rolePermissionData.some(
      (item) => item.formName === permissionName && item.viewPermission
    );
  };

  return (
    <StonesProvider>
      <div className="App">
        {isLoading && <Loader />}
        {loginToken ? (
          <>
            {isLoadingComponent == true ? (
              <LoaderWithLogo  />
            ) : (
              <BrowserRouter>
                <Navbar userName={userName} />
                <Routes>
                  {/* <Route path="/" /> */}
                  {checkViewPermission("Stock") && (
                    <Route
                      path="/"
                      element={<Protected Component={Currentstockofuser} />}
                    />
                  )}
                  <Route
                    path="/home"
                    element={<Protected Component={Home} />}
                  />
                  {checkViewPermission("Department") && (
                    <Route
                      path="/department"
                      element={<Protected Component={Department} />}
                    />
                  )}
                  {checkViewPermission("Machine") && (
                    <Route
                      path="/Machine"
                      element={<Protected Component={Machine} />}
                    />
                  )}
                  {checkViewPermission("Machine Dashboard") && (
                    <Route
                      path="/MachineDashboard"
                      element={<Protected Component={MachineDashboard} />}
                    />
                  )}
                  {checkViewPermission("Stone") && (
                    <Route
                      path="/stone"
                      element={<Protected Component={Stone} />}
                    />
                  )}
                  {checkViewPermission("Generate LotNo") && (
                    <Route
                      path="/GenerateBatchNo"
                      element={<Protected Component={GenerateBatchNo} />}
                    />
                  )}
                  {checkViewPermission("Generate Bag") && (
                    <Route
                      path="/GenerateBag"
                      element={<Protected Component={GenerateBag} />}
                    />
                  )}
                  {checkViewPermission("Stock") && (
                    <Route
                      path="/Currentstockofuser"
                      element={<Protected Component={Currentstockofuser} />}
                    />
                  )}
                  {checkViewPermission("Issue To Department") && (
                    <Route
                      path="/IssueToDepartment"
                      element={<Protected Component={IssueToDepartment} />}
                    />
                  )}
                  {checkViewPermission("Stone Type") && (
                    <Route
                      path="/StoneType"
                      element={<Protected Component={StoneType} />}
                    />
                  )}
                  {checkViewPermission("Issue To Worker") && (
                    <Route
                      path="/IssueToWorker"
                      element={<Protected Component={IssueToWorker} />}
                    />
                  )}
                  {checkViewPermission("Issue To Machine") && (
                    <Route
                      path="/IssueToMachine"
                      element={<Protected Component={IssueToMachine} />}
                    />
                  )}
                  {checkViewPermission("Receive Stone") && (
                    <Route
                      path="/Receivewindow"
                      element={<Protected Component={Receivewindow} />}
                    />
                  )}
                  {checkViewPermission("Receive From Worker") && (
                    <Route
                      path="/ReceiveToWorker"
                      element={<Protected Component={ReceiveToWorker} />}
                    />
                  )}
                  {checkViewPermission("Receive From Machine") && (
                    <Route
                      path="/ReceiveFromMachine"
                      element={<Protected Component={ReceiveFromMachine} />}
                    />
                  )}
                  {checkViewPermission("User") && (
                    <Route
                      path="/UserMaster"
                      element={<Protected Component={UserMaster} />}
                    />
                  )}
                  {checkViewPermission("Role Template") && (
                    <Route
                      path="/RoleTemplate"
                      element={<Protected Component={RoleTemplate} />}
                    />
                  )}
                  {checkViewPermission("Worker") && (
                    <Route
                      path="/Worker"
                      element={<Protected Component={Worker} />}
                    />
                  )}
                  {checkViewPermission("Generate MixNo") && (
                    <Route
                      path="/GenerateMixNo"
                      element={<Protected Component={GenerateMixNo} />}
                    />
                  )}
                  {checkViewPermission("Admin Dashboard") && (
                    <Route
                      path="/AdminDashboard"
                      element={<Protected Component={AdminDashboard} />}
                    />
                  )}
                  {checkViewPermission("Report") && (
                    <Route
                      path="/MachineReport"
                      element={<Protected Component={MachineReport} />}
                    />
                  )}
                  {checkViewPermission("User Profile") && (
                    <Route
                      path="/UserProfile"
                      element={<Protected Component={UserProfile} />}
                    />
                  )}
                  {checkViewPermission("Split Stone") && (
                    <Route
                      path="/SplitStone"
                      element={<Protected Component={SplitStone} />}
                    />
                  )}
                  {checkViewPermission("Issue And Receive Report") && (
                    <Route
                      path="/IssueAndReceiveReport"
                      element={<Protected Component={IssueAndReceiveReport} />}
                    />
                  )}
                  {checkViewPermission("Mix Stone Report") && (
                    <Route
                      path="/MixStoneReport"
                      element={<Protected Component={MixStoneReport} />}
                    />
                  )}
                  {checkViewPermission("Transfer To Final") && (
                    <Route
                      path="/TransferToFinal"
                      element={<Protected Component={TransferToFinal} />}
                    />
                  )}
                  {checkViewPermission("Receive From Final") && (
                    <Route
                      path="/ReceiveFromFinal"
                      element={<Protected Component={ReceiveFromFinal} />}
                    />
                  )}
                  {checkViewPermission("PartyMaster") && (
                    <Route
                      path="/PartyMaster"
                      element={<Protected Component={PartyMaster} />}
                    />
                  )}
                  {checkViewPermission("Jangad") && (
                    <Route
                      path="/Jangad"
                      element={<Protected Component={Jangad} />}
                    />
                  )}
                  {checkViewPermission("SingleStoneDataEntry") && (
                    <Route
                      path="/SingleStoneDataEntry"
                      element={<Protected Component={SingleStoneDataEntry} />}
                    />
                  )}
                  {checkViewPermission("ColorMaster") && (
                    <Route
                      path="/ColorMaster"
                      element={<Protected Component={ColorMaster} />}
                    />
                  )}
                  {checkViewPermission("ClarityMaster") && (
                    <Route
                      path="/ClarityMaster"
                      element={<Protected Component={ClarityMaster} />}
                    />
                  )}
                  {checkViewPermission("Purchase") && (
                    <Route
                      path="/Purchase"
                      element={<Protected Component={Purchase} />}
                    />
                  )}

                  <Route path="/logout" Component={Logout} />
                  <Route
                    path="*"
                    element={<Protected Component={PageNotFound} />}
                  />
                </Routes>
              </BrowserRouter>
            )}
          </>
        ) : (
          <Login />
        )}
      </div>
    </StonesProvider>
  );
}

export default App;
