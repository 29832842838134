import React from "react";
import Logo from "../../assets/Asian Lab Logo.png";
import ProgressBar from 'react-bootstrap/ProgressBar';

export default function LoaderWithLogo() {
    const now = 75;
  return (
    <>
      <div className="overlay" ></div>
      <div className="overlay-content text-center" >
        <img src={Logo} alt="" />
        <ProgressBar animated now={now}  label={`${now}%`}/>
      </div>
    </>
  );
}
