import { React, useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import api from "../../Utils/api";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import * as XLSX from "xlsx";
import {
  getCurrentDateTime,
  formatDate,
  addDecimalPlaces,
} from "../../Utils/commonfunctions";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Multiselect from "multiselect-react-dropdown";
import { Modal, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sumAggFunc = (params) => {
  const values = params.values;
  let sum = 0;

  values.forEach((value) => {
    sum += value;
  });

  return sum;
};

const EmtyEditMachieTime = {
  MachineEndTime: "",
  MachineStartTime: "",
  BatchNo: "",
  MachineHours: "",
  MachineMinit: "",
  StoneProcessNo: "",
  Remarks: "",
};

const pinnedBottomRowData = [
  {
    srNo: "Total",
    machineStartDate: null,
    machineEndDate: "",
    machineHours: 0,
    batchNo: "",
    pcs: 0,
    broken: 0,
    machineNo: 0,
    height: 0,
    weight: 0,
    aM_Height: 0,
    aM_Weight: 0,
    growthRate: 0,
  },
];

const MachineReport = () => {
  const [machineID, setMachineID] = useState([]);
  const [machineStartDate, setMachineStartDate] = useState("");
  const [machineEndDate, setMachineEndDate] = useState("");
  const [machineOption, setMachineOption] = useState([]);
  const [machineReportData, setMachineReportData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  // const [oneMachineData, setOneMachineData] = useState([]);
  // const [machineStartTime, setMachineStartTime] = useState("");
  // const [machineStopTime, setMachineStopTime] = useState("");
  // const [machineHours, setMachineHours] = useState("");
  // const [machineMinute, setMachineMinute] = useState("");
  // const [comment, setComment] = useState("");

  // const [machineComments, setMachineComments] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [sums, setSums] = useState({
    footerTotalPcs: 0,
    footerTotalBroken: 0,
    footerTotalHeight: 0,
    footerTotalWeight: 0,
    footerTotalAMHeight: 0,
    footerTotalAMWeight: 0,
    footerTotalGrowthRate: 0,
    footerTotalMachineHours: 0,
    footerTotalMachine: 0,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [EditMachineTimeData, setEditMachineTimeData] =
    useState(EmtyEditMachieTime);

  useEffect(() => {
    getMachineName();
    setMachineStartDate(getCurrentDateTime);
    setMachineEndDate(getCurrentDateTime);
  }, []);

  const getMachineName = () => {
    api
      .get("Machine/MachineListAsPerPermission")
      .then((result) => {
        setMachineOption(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const ddMachineList = (e) => {
  //
  //   setMachineID(e.target.value);
  // };
  const [key, setKey] = useState(0);
  const ddMachineList = (e) => {
    const selectedOptions = Array.from(e, (option) => option.value);
    setMachineID(selectedOptions);
  };

  const handleRemove = (e) => {
    const selectedOptions = Array.from(e, (option) => option.value);
    setMachineID(selectedOptions);
  };

  const ClearData = () => {
    setShowTable(false);
    setMachineID([]);
    setMachineEndDate(getCurrentDateTime);
    setMachineStartDate(getCurrentDateTime);
    setMachineReportData([]);
    setKey((prevKey) => prevKey + 1);
  };

  const PrintMachineReport = () => {
    setIsLoading(true); // Show loader
    if (machineStartDate === "" || machineStartDate === null) {
      return Swal.fire("Error", "Please Select Start Date", "error");
    }

    if (machineEndDate === "" || machineEndDate === null) {
      return Swal.fire("Error", "Please Select End Date", "error");
    }

    // Reset the data and loader for each search
    setShowTable(false);
    setMachineReportData([]);

    const formattedStartDate = formatDate(machineStartDate);
    const formattedEndDate = formatDate(machineEndDate);

    api
      .get(
        `Report/PrintMachineDetails?START_DATE=${formattedStartDate}&END_DATE=${formattedEndDate}&MachineIDs=${machineID}`
      )
      .then((result) => {
        const MachineDataList = result.data.data;
        setMachineReportData(MachineDataList);
        console.log(MachineDataList);
        setMachineID(0);
        setShowTable(true);
        calculateSums();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  const editStoneModal = (batchNo, stoneProcessNo) => {
    api
      .get(
        `Machine/MachineHourUpdate?BatchNo=${batchNo}&StoneProcessNo=${stoneProcessNo}`
      )
      .then((result) => {
        var List = result.data.data;
        const [hours, minutes] = List.machineHours.split(":");
        const totalHours = parseInt(hours, 10);
        const totalMinutes = parseInt(minutes, 10);

        setEditMachineTimeData({
          MachineEndTime: List.machineEndTime,
          MachineStartTime: List.machineStartTime,
          BatchNo: List.batchNo,
          MachineHours: totalHours,
          MachineMinit: totalMinutes,
          StoneProcessNo: List.stoneProcessNo,
          Remarks: List.remarks,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    // Open the modal
    setIsModalOpen(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditMachineTimeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const columnDefs = [
    {
      headerName: "Sr. No.",
      width: 120,
      sortable: true,
      filter: true,
      cellRenderer: ({ node }) => {
        // Check if it's the "Total" row and display "Total"
        if (node && node.data && node.data.srNo === "Total") {
          return <strong>Total</strong>;
        }

        // Display the row index + 1 if node exists
        return <span>{node.rowIndex + 1}</span>;
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Machine Start Date",
      field: "machineStartDate",
      width: "200px",
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          const formattedDate = date.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return formattedDate;
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Machine End Date",
      field: "machineEndDate",
      width: "200px",
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => {
        if (params.value && params.value.includes("T")) {
          const date = new Date(params.value);
          if (isNaN(date.getTime())) {
            return params.value; // Return the original value if it's not a valid date
          } else {
            const formattedDate = date.toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            return formattedDate;
          }
        }
        return params.value; // Return the original value if it doesn't include "T"
      },
    },
    {
      headerName: "Machine Hours",
      field: "machineHours",
      width: "150px",
      cellStyle: { textAlign: "left" },
      sortable: true,
      filter: true,
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Batch No",
      field: "batchNo",
      width: "120px",
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "PCS",
      field: "pcs",
      width: "80px",
      cellStyle: { textAlign: "left" },
      sortable: true,
      filter: true,
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Broken",
      field: "broken",
      width: "100px",
      cellStyle: { textAlign: "left" },
      sortable: true,
      filter: true,
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Machine No",
      field: "machineNo",
      width: "130px",
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Seed Height",
      field: "height",
      wrapHeaderText: true,
      width: "150px",
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: ({ value, data }) => value.toFixed(2),
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Seed Weight",
      field: "weight",
      wrapHeaderText: true,
      width: "150px",
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: ({ value, data }) => value.toFixed(2),
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Grow Height",
      field: "aM_Height",
      wrapHeaderText: true,
      width: "150px",
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: ({ value, data }) => value.toFixed(2),
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Grow Weight",
      field: "aM_Weight",
      wrapHeaderText: true,
      width: "150px",
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
      //cellRenderer: ({ value, data }) => value.toFixed(2),
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Grow Rate",
      field: "growthRate",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      sortable: true,
      filter: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Edit",
      width: 100,
      field: "batchNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ data, value }) =>
        data.stoneType !== "TOTAL" ? (
          <ion-icon
            name="create-outline"
            class="iconsFonts editIcon"
            onClick={() => editStoneModal(value, data.stoneProcessNo)}
          ></ion-icon>
        ) : null,
    },
  ];

  const exportToExcel = () => {
    if (machineReportData.length > 0) {
      const params = {
        fileName: "Machine_Report-Data.xlsx",
        sheetName: "Data",
      };

      const excelData = [];
      const columns = [];

      // Extract column headers
      columnDefs.forEach((column) => {
        columns.push(column.headerName);
      });

      excelData.push(columns);

      // Add data rows
      machineReportData.forEach((row) => {
        const rowData = [];
        columnDefs.forEach((column) => {
          rowData.push(row[column.field]);
        });
        excelData.push(rowData);
      });

      // Add total row
      const totalRow = [
        "Total",
        "",
        "",
        sums.footerTotalMachineHours,
        "",
        sums.footerTotalPcs,
        sums.footerTotalBroken,
        sums.footerTotalMachine,
        sums.footerTotalHeight,
        sums.footerTotalWeight,
        sums.footerTotalAMHeight,
        sums.footerTotalAMWeight,
        sums.footerTotalGrowthRate,
      ];
      excelData.push(totalRow);

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, params.sheetName);

      XLSX.writeFile(wb, params.fileName);
    }
  };

  //Data Start
  useEffect(() => {
    updatePinnedBottomRowData();
  }, [machineReportData]);

  const calculateSums = (event) => {
    var filteredData;
    if (event === undefined) {
      filteredData = machineReportData;
    } else {
      filteredData = event.api.getModel().rowsToDisplay.map((row) => row.data);
    }

    const filteredSumPcs = _.sumBy(filteredData, "pcs");
    const filteredTotalMachine = filteredData.length;

    var totalHeight = 0;
    var totalAM_Height = 0;

    filteredData.forEach((item) => {
      totalHeight += item.pcs * item.height;
      totalAM_Height += item.pcs * item.aM_Height;
    });
    const filteredSumBroken = _.sumBy(filteredData, "broken");

    const filteredSumHeight = totalHeight / filteredSumPcs;

    const filteredSumWeight = _.sumBy(filteredData, "weight");
    const filteredSumAMHeight = totalAM_Height / filteredSumPcs;
    const filteredSumAMWeight = _.sumBy(filteredData, "aM_Weight");
    const filteredSumGrowthRate =
      _.sumBy(filteredData, "growthRate") / filteredData.length;
    const filteredSumMachineHours =
      _.sumBy(filteredData, "machineHours") / filteredTotalMachine;

    const totalSumMachineHours = _.sumBy(filteredData, "machineHours");

    // Calculate unique machine numbers
    const uniqueMachineNos = _.uniq(filteredData.map((item) => item.machineNo));
    const uniqueMachineCount = uniqueMachineNos.length;

    // calculateDayDifference(totalSumMachineHours);

    return {
      footerTotalPcs: filteredSumPcs,
      footerTotalBroken: filteredSumBroken,
      footerTotalHeight: addDecimalPlaces(filteredSumHeight, 2),
      footerTotalWeight: addDecimalPlaces(filteredSumWeight, 2),
      footerTotalAMHeight: addDecimalPlaces(filteredSumAMHeight, 2),
      footerTotalAMWeight: addDecimalPlaces(filteredSumAMWeight, 2),
      footerTotalGrowthRate: addDecimalPlaces(filteredSumGrowthRate, 2),
      footerTotalMachineHours: addDecimalPlaces(filteredSumMachineHours, 2),
      footerTotalMachine: uniqueMachineCount, // Just display the total count
      functionPassHours : totalSumMachineHours
    };
  };

  const calculateDayDifference = (footerTotalMachineHours) => {
    ;
    const startDate = new Date(machineStartDate);
    const endDate = new Date(machineEndDate);

    // Set both dates to the same time (midnight) to consider only the date part
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    // Calculate time difference in milliseconds
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());

    // Convert milliseconds to days
    const totalDays = Math.ceil(timeDifference / (1000  *3600 * 24)) + 1;
    const totalHours = totalDays * 24;

    const EfficiencyCount = (footerTotalMachineHours * 100 / totalHours).toFixed(2);;

    return EfficiencyCount;
  };

  const updatePinnedBottomRowData = (event) => {
    const sums = calculateSums(event);
    pinnedBottomRowData[0].srNo = "Total";
    pinnedBottomRowData[0].machineStartDate = null;
    pinnedBottomRowData[0].machineEndDate =
      "Efficiency: " +
      calculateDayDifference(sums.functionPassHours) +
      "%";
    pinnedBottomRowData[0].machineHours = sums.footerTotalMachineHours;
    pinnedBottomRowData[0].batchNo = "";
    pinnedBottomRowData[0].pcs = sums.footerTotalPcs;
    pinnedBottomRowData[0].broken = sums.footerTotalBroken;
    pinnedBottomRowData[0].machineNo = sums.footerTotalMachine;
    pinnedBottomRowData[0].height = sums.footerTotalHeight;
    pinnedBottomRowData[0].weight = sums.footerTotalWeight;
    pinnedBottomRowData[0].aM_Height = sums.footerTotalAMHeight;
    pinnedBottomRowData[0].aM_Weight = sums.footerTotalAMWeight;
    pinnedBottomRowData[0].growthRate = sums.footerTotalGrowthRate;
  };

  const onFilterChanged = (event) => {
    updatePinnedBottomRowData(event);
    if (event) {
      event.api.refreshCells({ force: true, suppressFlash: true });
    }
  };

  //End New Code

  const optionsNew = machineOption.map((option) => ({
    label: option.machineNo,
    value: option.machineID,
  }));

  const EditMachineTime = () => {
    setIsLoading(true);
    if (EditMachineTimeData.MachineHours === "") {
      toast.error("set Machine Hours");
      return;
    }
    if (EditMachineTimeData.MachineEndTime === "") {
      toast.error("set Machine Hours");
      return;
    }
    const FinalHoursToMinute = parseInt(EditMachineTimeData.MachineHours) * 60;

    const data = {
      MachineEndTime: EditMachineTimeData.MachineEndTime,
      MachineStartTime: EditMachineTimeData.MachineStartTime,
      BatchNo: EditMachineTimeData.BatchNo,
      MachineHours:
        FinalHoursToMinute + parseInt(EditMachineTimeData.MachineMinit),
      StoneProcessNo: EditMachineTimeData.StoneProcessNo,
      Remarks: EditMachineTimeData.Remarks,
    };

    api
      .post(`Machine/EditHourAndTime`, data)
      .then((result) => {
        setIsLoading(false);
        PrintMachineReport();
        toast.success(result.data.message);
        setEditMachineTimeData(EmtyEditMachieTime);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      });
    setIsModalOpen(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>Machine Report</span>
        </div>
        <div className="card-body">
          <div className="row col-md-12 mb-3">
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="machineStartDate">Start Date:</strong>
              <input
                type="datetime-local"
                className="form-control"
                id="machineStartDate"
                placeholder="Enter Machine Start Time"
                value={machineStartDate}
                onChange={(e) => setMachineStartDate(e.target.value)}
              />
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="machineEndDate">End Date:</strong>
              <input
                type="datetime-local"
                className="form-control"
                id="machineEndDate"
                placeholder="Enter Machine Start Time"
                value={machineEndDate}
                onChange={(e) => setMachineEndDate(e.target.value)}
              />
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="machineID">Machine Name:</strong>
              <div className="input-group">
                {/* <select
                  className="form-control"
                  name="machineID"
                  value={machineID}
                  onChange={ddMachineList}
                  multiple
                >
                  <option value="">--Select Machine Name--</option>
                  {machineOption.map((option) => (
                    <option key={option.machineID} value={option.machineID}>
                      {option.machineNo}
                    </option>
                  ))}
                </select> */}
                <Multiselect
                  key={key}
                  options={optionsNew}
                  // selectedValues={optionsNew.selectedValue}
                  value={machineID}
                  displayValue="label"
                  onSelect={ddMachineList}
                  placeholder="Select Machine No"
                  onRemove={handleRemove}
                />
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <br />
              <button
                type="submit"
                className="btn btn-primary"
                onClick={PrintMachineReport}
              >
                <i class="fa fa-search"></i>
                {"  "}
                Search
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={ClearData}
                style={{ marginLeft: "10px" }}
              >
                <i class="fa fa-refresh"></i>
                {"  "}
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        {isLoading && (
          <>
            <div className="overlay"></div>
            <div className="overlay-content text-center">
              <Spinner animation="" variant="primary">
                <span class="loader"></span>
              </Spinner>
            </div>
          </>
        )}

        <div className="card-header">
          <span>Show Machine Details</span>
        </div>
        <div className="card-body">
          {showTable ? (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button className="btn btn-primary mb-2" onClick={exportToExcel}>
                <i class="fa fa-file-excel"></i> Export to Excel
              </button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <strong>Data Not Found</strong>
            </div>
          )}
          {showTable && (
            <>
              <AgGridComponent
                columnDefs={columnDefs}
                rowData={machineReportData}
                onFilterChanged={onFilterChanged}
                pinnedBottomRowData={pinnedBottomRowData}
                paginationPageSize={100}
                height="80vh"
              />
            </>
          )}
        </div>
      </div>

      <Modal show={isModalOpen} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Machine Stop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="row col-md-12 mt-4">
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="machineStopTime"
              >
                Start Time:
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Control
                  type="datetime-local"
                  id="machineStartTime"
                  placeholder="Enter Machine Start Time"
                  value={EditMachineTimeData.MachineStartTime}
                  disabled
                />
              </div>
            </Form.Group>

            <Form.Group className="row col-md-12 mt-4">
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="machineStopTime"
              >
                Stop Time:
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Control
                  type="datetime-local"
                  id="machineStopTime"
                  placeholder="Enter Machine Start Time"
                  name="MachineEndTime"
                  value={EditMachineTimeData.MachineEndTime}
                  onChange={handleInputChange}
                />
              </div>
            </Form.Group>

            <Form.Group className="row col-md-12 mt-4">
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="machineStopTime"
              >
                Hrs./Min.
              </Form.Label>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <Form.Control
                  type="number"
                  id="machineStopHours"
                  placeholder="Enter Hours"
                  name="MachineHours"
                  value={EditMachineTimeData.MachineHours}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <Form.Control
                  type="number"
                  id="machineStopMinutes"
                  placeholder="Enter Minutes"
                  name="MachineMinit"
                  value={EditMachineTimeData.MachineMinit}
                  onChange={handleInputChange}
                />
              </div>
            </Form.Group>

            <Form.Group className="row col-md-12 mt-4">
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="MachineComment"
              >
                Comment:
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Control
                  as="textarea"
                  rows={4}
                  id="MachineComment"
                  placeholder="Comments"
                  name="Remarks"
                  value={EditMachineTimeData.Remarks}
                  onChange={handleInputChange}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="margin_top10">
          <Button variant="primary" onClick={() => EditMachineTime()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MachineReport;
