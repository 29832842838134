import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Utils/api";
import AgGridComponent from "../../Components/Common/AgGridComponent";
// import BarcodeReact from "react-barcode";
import QRCode from "react-qr-code";
import ReactDOM from "react-dom";
import {
  PrintBatchSticker,
  PrintStoneStickerFromBatchNo,
} from "../../Utils/commonfunctions";
import { ISOToDateTime } from "../../Components/Common/Common";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

export default function GenerateBatchNo() {
  const [stoneNo, setStoneNo] = useState("");
  const [stoneList, setStoneList] = useState([]);
  const [BatchNoData, setBatchNoData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editLotNo, setEditLotNo] = useState([]);

  const [editStoneNo, setEditStoneNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getBatchNo();
  }, []);

  const findStone = (e) => {
    if (e.key === "Enter") {
      if (!isModalOpen) {
        api
          .get(`Stone/ByStoneNo?StoneNo=${stoneNo}`)
          .then((result) => {
            const stoneObject = result.data.data[0];
            let isExist = false;
            setStoneNo("");
            if (stoneObject !== undefined && stoneObject !== null) {
              stoneList.forEach((item) => {
                ;
                if (item.stoneNo === stoneObject.stoneNo) {
                  toast.error("Stone is already in the list");
                  isExist = true;
                  return;
                }
              });
              if (!isExist) {
                setStoneList((oldArray) => [stoneObject, ...oldArray]);
              }
            } else {
              toast.error("Stone is Not found");
            }
          })
          .catch((error) => {
            toast.error("Stone/ByStoneNo : error");
          });
      } else {
        api
          .get(`Stone/ByStoneNo?StoneNo=${editStoneNo}`)
          .then((result) => {
            const stoneObject = result.data.data[0];
            let isExist = false;
            setEditStoneNo("");
            if (stoneObject !== undefined && stoneObject !== null) {
              editLotNo.forEach((item) => {
                ;
                if (item.stoneNo === stoneObject.stoneNo) {
                  toast.error("Stone is already in the list");
                  isExist = true;
                  return;
                }
              });
              if (!isExist) {
                setEditLotNo((oldArray) => [stoneObject, ...oldArray]);
              }
            } else {
              toast.error("Stone is Not found");
            }
          })
          .catch((error) => {
            toast.error("Stone/ByStoneNo : error");
          });
      }
    }
  };

  const RemoveData = (id) => {
    const updatedData = stoneList.filter((item) => item.stoneID !== id);
    setStoneList(updatedData);
  };

  const GenerateBatchNo = () => {
    if (stoneList.length > 0) {
      api
        .post(
          "Stone/GenerateBatchNo",
          stoneList.map((item) => item.stoneID)
        )
        .then((result) => {
          if (result.data.flag) {
            setStoneList([]);
            getBatchNo();
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const PrintPreview = ({ stoneDetails, batchDetails }) => {
    // const barcodeOptions = {
    //   width: 2,
    //   height: 25,
    //   displayValue: false,
    // };
    return (
      <>
        <div style={{ width: "760px;" }}>
          <table
            className="table table-bordered"
            style={{
              fontSize: "12px",
              lineHeight: "8px",
              marginTop: "-1px",
              borderColor: "#000",
            }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Machine No:</th>
                <th style={{ textAlign: "center" }}>Status</th>
                <th style={{ textAlign: "center" }}>DATE</th>
                <th style={{ textAlign: "center" }}>TIME</th>
                <th style={{ textAlign: "center" }}>OPERATOR NAME</th>
                <th style={{ textAlign: "center" }}>WEIGHT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  LotNo:<strong>{batchDetails[0].batchNo}</strong>
                </td>
                <td>START</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>{batchDetails[0].totalWeight}</td>
              </tr>
              <tr>
                <td>TOTAL GR.HR:</td>
                <td>End</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex" }}>
            <table
              class="table table-bordered"
              style={{
                fontSize: "12px",
                lineHeight: "8px",
                width: "70%",
                borderColor: "#000",
              }}
            >
              <colgroup>
                <col style={{ width: "32px" }} />
                <col style={{ width: "60px" }} />
                <col style={{ width: "80px" }} />
                <col style={{ width: "40px" }} />
                <col style={{ width: "40px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th class="tg-c3ow">NO</th>
                  <th class="tg-c3ow">Packet No</th>
                  <th class="tg-c3ow">Origin Height</th>
                  <th class="tg-c3ow">Final Height</th>
                  <th class="tg-c3ow">Growth</th>
                </tr>
              </thead>
              <tbody>
                {stoneDetails &&
                  // Array.from({ length: 40 }, (_, index) => (
                  Array.from(
                    {
                      length:
                        batchDetails[0].totalPcs > 40
                          ? batchDetails[0].totalPcs
                          : 40,
                    },
                    (_, index) => (
                      <tr key={index}>
                        <td className="tg-0lax">{index + 1}</td>
                        <td className="tg-0lax">
                          {stoneDetails[index]?.stoneNo || null}
                        </td>
                        <td className="tg-0lax">
                          {stoneDetails[index]?.height || null}
                        </td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
            <table
              className="table table-bordered"
              style={{
                fontSize: "12px",
                lineHeight: "8px",
                width: "30%",
                borderColor: "#000",
              }}
            >
              <colgroup>
                <col style={{ width: "26px" }} />
                <col style={{ width: "26px" }} />
                <col style={{ width: "26px" }} />
                <col style={{ width: "25px" }} />
                <col style={{ width: "25px" }} />
                <col style={{ width: "25px" }} />
                <col style={{ width: "25px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th
                    className="tg-c3ow"
                    colSpan="7"
                    style={{ textAlign: "center" }}
                  >
                    QR
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tg-0lax" colSpan="7" rowSpan="5">
                    <div>
                      {/* <div style={{ textAlign: "center" }}>
                        <BarcodeReact
                          value={batchDetails[0].batchNo}
                          {...barcodeOptions}
                        />
                      </div> */}
                      <div
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: "150px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <QRCode value={batchDetails[0].batchNo} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {" "}
                          <strong>
                            {batchDetails[0].stoneType}/
                            {batchDetails[0].batchNo}
                          </strong>
                        </span>
                        <span>
                          <strong> Pcs: </strong> {batchDetails[0].totalPcs}
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          <strong> L*W: </strong>{" "}
                          {batchDetails[0].avgtotalLength.toFixed(2)} *{" "}
                          {batchDetails[0].avgtotalWidth.toFixed(2)}
                        </span>
                        {batchDetails[0].brokenItemCount > 0 && (
                          <span>
                            <strong>Broken: </strong>{" "}
                            {batchDetails[0].brokenItemCount.toFixed(2)}
                          </span>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <span>
                          <strong> Height: </strong>{" "}
                          {batchDetails[0].avgtotalHeight.toFixed(2)}
                        </span>
                        <span>
                          <strong> Weight: </strong>{" "}
                          {batchDetails[0].totalWeight.toFixed(2)} ct.
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <span>
                          <strong>Date: </strong>{" "}
                          {ISOToDateTime(batchDetails[0].createdDatetime)}
                        </span>
                      </div>
                      {batchDetails[0].machineHours && (
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            <strong>M No: </strong> {batchDetails[0].machineNo}
                          </span>
                          <span>
                            <strong>M Hr: </strong>{" "}
                            {batchDetails[0].machineHours}
                            {/* {Math.floor(batchDetails[0].machineHours / 60)}:{batchDetails[0].machineHours % 60 < 10 ? '0' : ''}{batchDetails[0].machineHours % 60} */}
                          </span>
                        </div>
                      )}
                      {batchDetails[0].machineGrowthRate > 0 && (
                        <div
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <span>
                            <strong>MG: </strong>{" "}
                            {batchDetails[0].machineGrowthRate.toFixed(2)}
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                {Array.from({ length: 7 }).map((_, rowIndex) => (
                  <tr key={rowIndex} style={{ lineHeight: "10px" }}>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                ))}
                <tr>
                  <td
                    className="tg-0lax"
                    colSpan="3"
                    style={{ lineHeight: "6px" }}
                  >
                    Av. Gr. Rt.
                  </td>
                  <td className="tg-0lax" colSpan="4"></td>
                </tr>
                <tr>
                  <td
                    className="tg-0lax"
                    colSpan="3"
                    style={{ lineHeight: "6px" }}
                  >
                    Av. Growth
                  </td>
                  <td className="tg-0lax" colSpan="4"></td>
                </tr>
                <tr>
                  <td
                    className="tg-0lax"
                    colSpan="7"
                    style={{
                      textAlign: "center",
                      fontWeight: "900",
                      fontSize: "15px",
                      lineHeight: "8px",
                    }}
                  >
                    Note:
                  </td>
                </tr>
                {Array.from({ length: 14 }).map((_, rowIndex) => (
                  <tr key={rowIndex} style={{ lineHeight: "9px" }}>
                    <td className="tg-0lax" colSpan="7"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  const generateBarcode = (no, processNo) => {
    ;
    api
      .get(`Stone/StoneListByBatchNo?BatchNo=${no}&StoneProcessNo=${processNo}`)
      .then((result) => {
        const StoneDetailsForPrint = result.data.data.stoneList;
        const BatchDetailsForPrint = result.data.data.list;

        const printWindow = window.open("", "_blank");
        printWindow.document.write(
          '<html><head><title>Print Barcode</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"><style>{{width:"760px;"}}</style></head><body>'
        );
        ReactDOM.render(
          <PrintPreview
            stoneDetails={StoneDetailsForPrint}
            batchDetails={BatchDetailsForPrint}
          />,
          printWindow.document.body
        );
        printWindow.document.write("</body></html>");
        setTimeout(() => {
          printWindow.print();
          printWindow.close();
        }, 200);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fetch all departments
  const getBatchNo = () => {
    setIsLoading(true); // Show loader
    api
      .get("Stone/AllBatchData")
      .then((result) => {
        setBatchNoData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  const exportToExcel = () => {
    setIsLoading(true);

    setTimeout(async () => {
      try {
        const fileName = "GenerateBatchNo.xlsx";

        // Format the data to be exported, including the date and time
        const formattedData = BatchNoData.map((item) => {
          return {
            BatchNo: item.batchNo,
            TotalPcs: item.totalPcs,
            AvgtotalLength: item.avgtotalLength,
            AvgtotalWidth: item.avgtotalWidth,
            AvgtotalHeight: item.avgtotalHeight,
            TotalWeight: item.totalWeight,
            DateTime: item.dateTime,
          };
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Issue Machine List");

        // Set up the columns
        worksheet.columns = [
          { header: "DateTime", key: "DateTime", width: 25 },
          { header: "Batch No", key: "BatchNo", width: 15 },
          { header: "Total PCS", key: "TotalPcs", width: 10 },
          { header: "Avg Length", key: "AvgtotalLength", width: 15 },
          { header: "Avg Width", key: "AvgtotalWidth", width: 15 },
          { header: "Avg Height", key: "AvgtotalHeight", width: 15 },
          { header: "Total Weight", key: "TotalWeight", width: 15 },
        ];

        // Add the rows to the worksheet
        worksheet.addRows(formattedData);

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF4F81BD" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Adjust column widths based on content length
        worksheet.columns.forEach((column) => {
          let maxLength = column.header.length;
          formattedData.forEach((row) => {
            const cellValue = row[column.key];
            if (cellValue) {
              maxLength = Math.max(maxLength, cellValue.toString().length);
            }
          });
          column.width = maxLength + 2; // Add padding
        });

        // Export the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        // Handle any error here, such as showing an error message to the user
      } finally {
        setIsLoading(false);
      }
    }, 1000);
  };

  const columnDefs = [
    {
      headerName: "Lot No",
      field: "batchNo",
      sortable: true,
      filter: true,
      width: 100,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Pcs",
      field: "totalPcs",
      sortable: true,
      filter: true,
      width: 120,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "AvgTotal Length",
      field: "avgtotalLength",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "AvgTotal Width",
      field: "avgtotalWidth",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "AvgTotal Height",
      field: "avgtotalHeight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Total Weight",
      field: "totalWeight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Edit",
      width: 100,
      field: "batchNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() =>
            openModalForEditLotNo(data.batchNo, data.stoneProcessNo)
          }
        ></ion-icon>
      ),
    },
    {
      headerName: "Print List",
      width: 100,
      field: "batchNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="print-outline"
          class="iconsFonts"
          onClick={() => generateBarcode(data.batchNo, data.stoneProcessNo)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Print Lot",
      width: 100,
      field: "batchNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="print-outline"
          class="iconsFonts"
          onClick={() => PrintBatchSticker(data.batchNo, data.stoneProcessNo)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Print Stone",
      width: 100,
      field: "batchNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      pinned: "right",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="print-outline"
          class="iconsFonts"
          onClick={() =>
            PrintStoneStickerFromBatchNo(data.batchNo, data.stoneProcessNo)
          }
        ></ion-icon>
      ),
    },
    {
      headerName: " DateTime",
      field: "dateTime",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  // Edit Lot No
  const openModalForEditLotNo = (lotNo, processNo) => {
    setIsModalOpen(true);
    api
      .get(
        `Stone/PrintStoneStickerFromBatchNo?BatchNo=${lotNo}&StoneProcessNo=${processNo}`
      )
      .then((result) => {
        setIsModalOpen(true);
        setEditLotNo(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const RemoveEditLotNoData = (id) => {
    const updatedData = editLotNo.map((item) => {
      if (item.stoneID === id) {
        return { ...item, isDeleted: true };
      }
      return item;
    });
    setEditLotNo(updatedData);
  };

  const handleCloseMode = () => {
    setIsModalOpen(false);
    setEditLotNo([]);
  };

  const UpdateGenerateBatchNo = () => {
    if (editLotNo.length > 0) {
      api
        .patch("Stone/UpdateGenerateBatchNo", editLotNo)
        .then((result) => {
          if (result.data.flag) {
            setEditLotNo([]);
            getBatchNo();
            toast.success(result.data.message);
            setIsModalOpen(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
    {isLoading && (
          <>
            <div className="overlay"></div>
            <div className="overlay-content text-center">
              <Spinner animation="" variant="primary">
                <span class="loader"></span>
              </Spinner>
            </div>
          </>
        )}
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>Generate LotNo</span>
        </div>
        <div className="card-body">
          <div className="row align-middle">
            <div className="col-md-2 col-sm-2 col-lg-2 align-middle">
              <label htmlFor="Stone_no" className="form-label">
                StoneNo:
              </label>
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4">
              <input
                type="text"
                className="form-control"
                id="Stone_no"
                placeholder="EnterStone No"
                value={stoneNo}
                onChange={(e) => setStoneNo(e.target.value)}
                onKeyDown={findStone}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <span>Stone List</span>
        </div>
        <div className="card-body table-responsive">
          <table className="table table-borderd">
            <thead>
              <tr>
                <th style={{ width: "80px", textAlign: "center" }}>Sr. No</th>
                <th>Stone No</th>
                <th>Stone Type</th>
                <th>Length</th>
                <th>Width</th>
                <th>Height</th>
                <th>Weight</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {stoneList.map((item, index) => (
                <tr key={item.stoneID}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>{" "}
                  {/* Serial Number */}
                  <td>{item.stoneNo}</td>
                  <td>{item.stoneType}</td>
                  <td>{item.length}</td>
                  <td>{item.width}</td>
                  <td>{item.height}</td>
                  <td>{item.weight}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => RemoveData(item.stoneID)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-success mt-3 mb-3"
              onClick={() => GenerateBatchNo()}
            >
              Generate LotNo
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        
        <div className="card-header d-flex justify-content-between">
          <span>LotNo List</span>
          <Button
            type="button"
            variant="success"
            className="btn btn-primary mb-2"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={BatchNoData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="80vh"
          ></AgGridComponent>
        </div>
      </div>

      {isModalOpen && (
        <div
          className={`modal fade ${isModalOpen ? "show d-block" : ""}`}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden={!isModalOpen}
          style={{ zIndex: isModalOpen ? "1000" : "-1" }}
        >
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Generate LotNo</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseMode} // Pass a function
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-middle">
                      <div className="col-md-2 col-sm-2 col-lg-2 align-middle">
                        <label htmlFor="editStone_no" className="form-label">
                          StoneNo:
                        </label>
                      </div>
                      <div className="col-md-4 col-sm-4 col-lg-4">
                        <input
                          type="text"
                          className="form-control"
                          id="editStone_no"
                          placeholder="EnterStone No"
                          value={editStoneNo}
                          onChange={(e) => setEditStoneNo(e.target.value)}
                          onKeyDown={findStone}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <span>Stone List</span>
                  </div>
                  <div className="card-body table-responsive">
                    <table className="table table-borderd">
                      <thead>
                        <tr>
                          <th>Stone No</th>
                          <th>Stone Type</th>
                          <th>Length</th>
                          <th>Width</th>
                          <th>Height</th>
                          <th>Weight</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {editLotNo
                          .filter((item) => !item.isDeleted)
                          .map((item) => (
                            <tr key={item.stoneID}>
                              <td>{item.stoneNo}</td>
                              <td>{item.stoneType}</td>
                              <td>{item.length}</td>
                              <td>{item.width}</td>
                              <td>{item.height}</td>
                              <td>{item.weight}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() =>
                                    RemoveEditLotNoData(item.stoneID)
                                  }
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-success mt-3 mb-3"
                        onClick={() => UpdateGenerateBatchNo()}
                      >
                        Update Generate LotNo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
