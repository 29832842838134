import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Utils/api";
import AgGridComponent from "../../Components/Common/AgGridComponent";
// import FindStone from "../../Components/Common/FindStone";
import { useStonesContext } from "../../contextFile/StoneContext/StonesContext";
import { PrintStoneSticker } from "../../Utils/commonfunctions";
import { addDecimalPlaces } from "../../Utils/commonfunctions";
import BarcodeScanner from "../../Components/Common/BarcodeScanner";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const emptyMixStoneList = {
  stoneType: "",
  pcs: 0,
  length: 0,
  width: 0,
  height: 0,
  weight: 0,
  dlc: 0,
  loss: 0,
};

export default function GenerateBatchNo() {
  const [mixStoneList, setMixStoneList] = useState([]);
  const [MixNoData, setMixNoData] = useState([]);
  const [numOfRows, setNumOfRows] = useState(1);
  const [options, setOptions] = useState([]);
  const { listStones, setListStones } = useStonesContext();
  const [isLoading, setIsLoading] = useState(false);

  const GenerateMixNo = () => {
    setIsLoading(true);
    const row = mixStoneList.length - 1;
    if (
      mixStoneList[row].stoneType === "" ||
      mixStoneList[row].weight === "" ||
      mixStoneList[row].weight <= 0
    ) {
      toast.error(
        `Please fill in the ${
          mixStoneList[row].stoneType === ""
            ? "stoneType"
            : mixStoneList[row].pcs === "" || mixStoneList[row].pcs <= 0
            ? "pcs"
            : "weight"
        } for the MixStone.`
      );
      setIsLoading(false);
      return;
    }

    const data = {
      BatchAndStoneProcessList: batchAvgList.map((item) => ({
        BatchNo: item.batchNo,
        StoneProcessNo: item.stoneProcessNo,
      })),
      OldStoneID: listStones.map((item) => item.stoneID),
      StoneData: mixStoneList,
    };

    api
      .post("Stone/GenerateMixNo", data)
      .then((result) => {
        if (result.data.flag) {
          resetData();
          toast.success(result.data.message);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  const resetData = () => {
    setNumOfRows(1);
    setFindNo("");
    setListStones([]);
    setBatchAvgList([]);
    getMixNo();
    setMixStoneList([]);
  };

  useEffect(() => {
    getMixNo();
    getStoneTypeName();
    setListStones([]);
    setBatchAvgList([]);
    setMixStoneList([]);
    setDLCData([]);
    setLossData([]);
  }, []);

  const getStoneTypeName = () => {
    api
      .get("StoneType/GetStoneTypeName")
      .then((result) => {
        setOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Fetch all departments
  const getMixNo = () => {
    setIsLoading(true);
    api
      .get("Stone/MixNoData")
      .then((result) => {
        setMixNoData(result.data.data.list);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  const handleRowInputChange = (index, field, value) => {
    const updatedStoneList = [...mixStoneList];
    updatedStoneList[index] = {
      ...updatedStoneList[index],
      [field]: value,
    };
    setMixStoneList(updatedStoneList);
  };
  const addRow = () => {
    if (mixStoneList.length > 0) {
      const lastRow = mixStoneList[mixStoneList.length - 1];
      if (
        lastRow.stoneType === "" ||
        lastRow.weight === "" ||
        lastRow.weight <= 0
      ) {
        toast.error(
          `Please fill in the ${
            lastRow.stoneType === ""
              ? "stoneType"
              : lastRow.pcs === "" || lastRow.pcs <= 0
              ? "pcs"
              : "weight"
          } for the previous MixStone.`
        );
        return;
      }
    }
    setMixStoneList((oldArray) => [...oldArray, emptyMixStoneList]);
  };

  const removeRow = (rowIndex) => {
    setMixStoneList((oldList) =>
      oldList.filter((_, index) => index !== rowIndex)
    );
  };

  // for export excel
  const exportToExcel = () => {
    setIsLoading(true);

    setTimeout(async () => {
      try {
        const fileName = "GenerateMixNO.xlsx";

        // Format the data to be exported, including the date and time
        const formattedData = MixNoData.map((item) => {
          return {
            MixNo: item.mixNo,
            stoneType: item.stoneType,
            Pcs: item.pcs,
            Length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
          };
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Issue Machine List");

        // Set up the columns
        worksheet.columns = [
          { header: "Mix No", key: "MixNo", width: 25 },
          { header: "Stone Type", key: "stoneType", width: 15 },
          { header: "PCS", key: "Pcs", width: 10 },
          { header: "Length", key: "Length", width: 15 },
          { header: "Width", key: "width", width: 15 },
          { header: "Height", key: "height", width: 15 },
          { header: "Weight", key: "weight", width: 15 },
        ];

        // Add the rows to the worksheet
        worksheet.addRows(formattedData);

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF4F81BD" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Adjust column widths based on content length
        worksheet.columns.forEach((column) => {
          let maxLength = column.header.length;
          formattedData.forEach((row) => {
            const cellValue = row[column.key];
            if (cellValue) {
              maxLength = Math.max(maxLength, cellValue.toString().length);
            }
          });
          column.width = maxLength + 2; // Add padding
        });

        // Export the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        // Handle any error here, such as showing an error message to the user
      } finally {
        setIsLoading(false);
      }
    }, 1000);
  };

  const columnDefs = [
    {
      headerName: "MixNo",
      field: "mixNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Stone Type",
      field: "stoneType",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "PCS",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Length",
      field: "length",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      valueFormatter: ({ value }) =>
        value === 0 ? "0.00" : value ? parseFloat(value).toFixed(2) : "",
    },
    {
      headerName: "Width",
      field: "width",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      valueFormatter: ({ value }) =>
        value === 0 ? "0.00" : value ? parseFloat(value).toFixed(2) : "",
    },
    {
      headerName: "Height",
      field: "height",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      valueFormatter: ({ value }) =>
        value === 0 ? "0.00" : value ? parseFloat(value).toFixed(2) : "",
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      valueFormatter: ({ value }) =>
        value === 0 ? "0.00" : value ? parseFloat(value).toFixed(2) : "",
    },
    {
      headerName: "Print",
      width: 100,
      field: "stoneNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="print-outline"
          class="iconsFonts"
          onClick={() => PrintStoneSticker(data.stoneNo, data.stoneProcessNo)}
        ></ion-icon>
      ),
    },
  ];

  //New Start Here
  const inputRef = useRef(null);
  const { findNo, setFindNo } = useStonesContext();
  const { batchAvgList, setBatchAvgList } = useStonesContext();
  // const [totalPcs, setTotalPcs] = useState();
  //const [findDataIsTrue, setFindDataIsTrue] = useState([]);
  const [dlcData, setDLCData] = useState([]);
  const [lossData, setLossData] = useState([]);

  const [finalDLC, setFinalDLC] = useState([]);
  const [finalLOSS, setFinalLoss] = useState([]);

  const findStone = (e) => {
    if (e.key === "Enter") {
      getStoneData(findNo);
    }
  };

  const handleDataFromChild = (data) => {
    setFindNo(data);
    getStoneData(data);
  };

  const [isBarcodeScanner, setIsBarcodeScanner] = useState(false);
  const toggleBarcodeScanner = () => {
    setIsBarcodeScanner(!isBarcodeScanner);
  };

  // useEffect(() => {
  //   countLossPer();
  // }, [listStones, finalLOSS, finalDLC]);

  const getStoneData = (No) => {
    api
      .get(`Stone/ByBatchNoOrSotneNoForCueerntUser?BatchNoOrStoneNo=${findNo}`)
      // .get(`Stone/ByBatchNoOrStoneNoForMixStone?BatchNoOrStoneNo=${findNo}`)
      // .get(findUrlForStone + `=${No}`)
      .then((result) => {
        setFindNo("");
        const data = result.data.data;
        let isExist = false;
        if (data !== undefined && data !== null) {
          listStones.forEach((item) => {
            if (item.stoneNo === data[0].stoneNo) {
              toast.error("Stone is already in the list");
              isExist = true;
              return;
            }
          });
          if (!isExist) {
            const objFinalData = Object.assign({}, emptyMixStoneList);
            // const objBrokenData = Object.assign({}, emptyMixStoneList);
            const objOutData = Object.assign({}, emptyMixStoneList);
            if (data && data.length > 0) {
              data.forEach((item) => {
                item.oldstoneID = item.stoneID;
                item.oldstoneNo = item.stoneNo;
                item.oldpcs = item.pcs;
                item.oldlength = item.length;
                item.oldwidth = item.width;
                item.oldheight = item.height;
                item.oldweight = item.weight;
                item.dlc = item.dlc;
                item.loss = item.loss;
                item.isBroken = item.isBroken === null ? false : item.isBroken;
                item.isFinal = item.isFinal === null ? false : item.isFinal;
                item.isOut = item.isOut === null ? false : item.isOut;

                item.getFinal = item.isFinal === null ? false : item.isFinal;
                item.getOut = item.isOut === null ? false : item.isOut;

                if (item.isFinal) {
                  objFinalData.stoneType = "Final";
                  objFinalData.pcs = objFinalData.pcs + item.pcs;
                  objFinalData.weight = objFinalData.weight + item.weight;
                }
                // if (item.isBroken) {
                //   objBrokenData.stoneType = "Broken";
                //   objBrokenData.pcs = objBrokenData.pcs + item.pcs;
                //   objBrokenData.weight = objBrokenData.weight + item.weight;
                // }
                if (item.isOut) {
                  objOutData.stoneType = "Out";
                  objOutData.pcs = objOutData.pcs + item.pcs;
                  objOutData.weight = objOutData.weight + item.weight;
                }
                setListStones((oldArray) => [...oldArray, item]);
              });
            } else {
              toast.error("Stone is Not found");
            }
            if (objFinalData.pcs > 0) {
              const finalItemIndex = mixStoneList.findIndex(
                (item) => item.stoneType === "Final"
              );
              if (finalItemIndex !== -1) {
                mixStoneList[finalItemIndex].pcs += objFinalData.pcs;
                mixStoneList[finalItemIndex].weight += objFinalData.weight;
              } else {
                const emptyIndex = mixStoneList.findIndex(
                  (item) => item.stoneType.trim() === ""
                );
                if (emptyIndex !== -1) {
                  mixStoneList[emptyIndex] = objFinalData;
                } else {
                  setMixStoneList((oldArray) => [...oldArray, objFinalData]);
                }
              }
            }

            // Similar logic for objOutData
            if (objOutData.pcs > 0) {
              const outItemIndex = mixStoneList.findIndex(
                (item) => item.stoneType === "Out"
              );
              if (outItemIndex !== -1) {
                mixStoneList[outItemIndex].pcs += objOutData.pcs;
                mixStoneList[outItemIndex].weight += objOutData.weight;
              } else {
                const emptyIndex = mixStoneList.findIndex(
                  (item) => item.stoneType.trim() === ""
                );
                if (emptyIndex !== -1) {
                  mixStoneList[emptyIndex] = objOutData;
                } else {
                  setMixStoneList((oldArray) => [...oldArray, objOutData]);
                }
              }
            }
          }
          if (mixStoneList.length === 0) {
            addRow();
          }
        } else {
          toast.error("Stone is Not found");
        }
        // After fetching data, clear the input and focus on it
        setFindNo("");
        inputRef.current.focus(); // Focus on the input element
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred while fetching data.");
      });

    api
      .get(
        `Stone/BatchAvgListByBatchNoOrStoneNoForMixStone?BatchNoOrStoneNo=${findNo}`
        // `Stone/BatchAvgListForMixStone?BatchNoOrStoneNo=${findNo}`
      )
      .then((result) => {
        setFindNo("");
        const data = result.data.data.list;

        const dlcData = result.data.data.finalDLC;

        const updateDLCData = dlcData.map((item) => ({
          ...item,
          batchNo: item.batchNo,
          departmentName: item.departmentName,
          finalTotalDLC: item.finalTotalDLC,
        }));
        setDLCData((oldList) => [...oldList, ...updateDLCData]);

        const lossData = result.data.data.finalLOSS;

        const updateLossData = lossData.map((item) => ({
          ...item,
          batchNo: item.batchNo,
          departmentName: item.departmentName,
          finalTotalLOSS: item.finalTotalLOSS,
        }));
        setLossData((oldList) => [...oldList, ...updateLossData]);

        let isExist = false;
        if (data !== undefined && data !== null) {
          batchAvgList.forEach((item) => {
            if (item.batchNo === data[0].batchNo) {
              toast.error("Lot is already in the list");
              isExist = true;
              return;
            }
          });
          if (!isExist) {
            if (data !== undefined && data !== null) {
              const updatedBatchAvgList = data.map((item) => {
                const matchingDLC = updateDLCData.filter(
                  (dlcItem) => dlcItem.batchNo === item.batchNo
                );
                const matchingLoss = updateLossData.filter(
                  (lossItem) => lossItem.batchNo === item.batchNo
                );

                const finalTotalDLC =
                  matchingDLC.length > 0
                    ? matchingDLC.reduce(
                        (total, dlcItem) => total + dlcItem.finalTotalDLC,
                        0
                      )
                    : 0;

                const finalTotalLoss =
                  matchingLoss.length > 0
                    ? matchingLoss.reduce(
                        (total, lossItem) => total + lossItem.finalTotalLOSS,
                        0
                      )
                    : 0;

                setFinalDLC(finalTotalDLC);
                setFinalLoss(finalTotalLoss);
                // countLossPer();
                return {
                  ...item,
                  oldtotalPcs: item.totalPcs,
                  oldavgtotalLength: item.avgtotalLength,
                  oldavgtotalWidth: item.avgtotalWidth,
                  oldavgtotalHeight: item.avgtotalHeight,
                  oldtotalWeight: item.totalWeight,
                  totalDLC: addDecimalPlaces(finalTotalDLC, 2),
                  totalLoss: addDecimalPlaces(finalTotalLoss, 2),
                  totalGrowthStoneWeight: item.totalGrowthStoneWeight,
                  // totalDLC: item.totalDLC,
                  //totalLoss: item.totalLoss,
                  isBroken: item.isBroken,
                  isFinal: item.isFinal,
                  isOut: item.isOut,
                  stoneID: item.stoneID,
                  stoneProcessNo: item.stoneProcessNo,
                };
              });

              setBatchAvgList((prevList) => [
                ...prevList,
                ...updatedBatchAvgList,
              ]);
            } else {
              toast.error("Stone is Not found");
            }
          }
        } else {
          toast.error("Stone is Not found");
        }
        setFindNo("");
        inputRef.current.focus();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeData = (id) => {
    const updatedStones = listStones.filter((item) => item.stoneID !== id);
    const updatedBatchAvgList = batchAvgList.filter(
      (item) => item.batchNo !== id
    );
    const batchNumberToRemove = batchAvgList.find(
      (item) => item.batchNo === id
    )?.batchNo;
    const updatedStonesAfterBatchRemoval = updatedStones.filter(
      (item) => item.batchNo !== batchNumberToRemove
    );

    setListStones(updatedStonesAfterBatchRemoval);
    setBatchAvgList(updatedBatchAvgList);
  };

  return (
    <>
      {isLoading && (
        <>
          <div className="overlay"></div>
          <div className="overlay-content text-center">
            <Spinner animation="" variant="primary">
              <span class="loader"></span>
            </Spinner>
          </div>
        </>
      )}
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>Generate Mix No</span>
        </div>
        <div className="card-body">
          {isBarcodeScanner && (
            <BarcodeScanner
              sendDataToParent={handleDataFromChild}
            ></BarcodeScanner>
          )}
          <div className="row align-middle d-flex justify-content-center align-items-center">
            <div className="col-md-2 col-sm-2 col-lg-2 d-flex justify-content-end align-items-center">
              <label htmlFor="Find_no">
                <div className="d-flex align-items-center">
                  {" "}
                  <span>StoneNo/LotNo: </span>
                  <button
                    type="button"
                    style={{ marginLeft: "20px" }}
                    className="btn btn-primary"
                    onClick={toggleBarcodeScanner}
                  >
                    {isBarcodeScanner ? (
                      <i className="fa fa-close"></i>
                    ) : (
                      <i className="fa fa-camera"></i>
                    )}
                  </button>
                </div>
              </label>
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4">
              <input
                type="text"
                className="form-control"
                id="Find_no"
                placeholder="Enter BatchNoOrStoneNo"
                value={findNo}
                onChange={(e) => setFindNo(e.target.value)}
                onKeyDown={findStone}
                ref={inputRef} // Assign the ref to the input element
              />
            </div>
            <div className="col-md-1 col-sm-1 col-lg-1 searchBtn">
              <button
                type="button"
                className="align-items-center btn btn-primary"
                onClick={(e) => getStoneData(findNo)}
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Batch And Stone List Start Here */}

      <div className="card">
        <div className="card-header">
          <span>Batch AvgList</span>
        </div>
        <div className="card-body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Batch No</th>
                <th>Total Pcs</th>
                <th>Avgtotal Length</th>
                <th>Avgtotal Width</th>
                <th>Avgtotal Height</th>
                <th>Total Weight</th>
                <th>G. Weight</th>
                <th>DLC</th>
                <th>Loss</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {batchAvgList.map((item, index) => {
                //DLC
                const batchTotalDLC = dlcData
                  .filter((iteminner) => iteminner.batchNo === item.batchNo)
                  .reduce(
                    (total, iteminner) => total + iteminner.finalTotalDLC,
                    0
                  );
                //Loss
                const batchTotalLoss = lossData
                  .filter((itemLoss) => itemLoss.batchNo === item.batchNo)
                  .reduce(
                    (total, itemLoss) => total + itemLoss.finalTotalLOSS,
                    0
                  );
                return (
                  <tr key={item.batchNo}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>{" "}
                    <td>{item.batchNo}</td>
                    <td>{item.totalPcs}</td>
                    <td>{item.avgtotalLength}</td>
                    <td>{item.avgtotalWidth}</td>
                    <td>{item.avgtotalHeight}</td>
                    <td>{item.totalWeight}</td>
                    <td>{item.totalGrowthStoneWeight}</td>
                    <td>
                      {dlcData &&
                        dlcData
                          .filter(
                            (iteminner) => iteminner.batchNo === item.batchNo
                          )
                          .map((itemInner, indexInner) => (
                            <div>
                              <strong>{itemInner.departmentName}:</strong>{" "}
                              {itemInner.finalTotalDLC}
                            </div>
                          ))}
                      {batchTotalDLC > 0 && (
                        <div style={{ "border-top": "solid 1px" }}>
                          <strong>
                            Total: {addDecimalPlaces(batchTotalDLC, 2)}
                          </strong>
                        </div>
                      )}
                    </td>
                    <td>
                      {lossData &&
                        lossData
                          .filter(
                            (itemLoss) => itemLoss.batchNo === item.batchNo
                          )
                          .map((itemInner, indexInner) => (
                            <div>
                              <strong>{itemInner.departmentName}:</strong>{" "}
                              {itemInner.finalTotalLOSS}
                            </div>
                          ))}
                      {batchTotalLoss < 0 && (
                        <div style={{ "border-top": "solid 1px" }}>
                          <strong>
                            Total: {addDecimalPlaces(batchTotalLoss, 2)}
                          </strong>
                        </div>
                      )}
                    </td>
                    <td className="txtCenter">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => removeData(item.batchNo)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={2}>
                  <strong> Total</strong>
                </td>
                <td>
                  {addDecimalPlaces(
                    batchAvgList.reduce(
                      (total, batch) => total + batch.totalPcs,
                      0
                    ),
                    2
                  )}
                </td>
                <td>
                  {addDecimalPlaces(
                    batchAvgList.reduce(
                      (total, batch) =>
                        total + parseFloat(batch.avgtotalLength),
                      0
                    ),
                    2
                  )}
                </td>
                <td>
                  {addDecimalPlaces(
                    batchAvgList.reduce(
                      (total, batch) => total + parseFloat(batch.avgtotalWidth),
                      0
                    ),
                    2
                  )}
                </td>
                <td>
                  {addDecimalPlaces(
                    batchAvgList.reduce(
                      (total, batch) =>
                        total + parseFloat(batch.avgtotalHeight),
                      0
                    ),
                    2
                  )}
                </td>
                <td>
                  {addDecimalPlaces(
                    batchAvgList.reduce(
                      (total, batch) => total + parseFloat(batch.totalWeight),
                      0
                    ),
                    2
                  )}
                </td>
                <td>
                  {addDecimalPlaces(
                    batchAvgList.reduce(
                      (total, batch) =>
                        total + parseFloat(batch.totalGrowthStoneWeight),
                      0
                    ),
                    2
                  )}
                </td>
                <td>
                  {addDecimalPlaces(
                    batchAvgList.reduce(
                      (total, batch) => total + parseFloat(batch.totalDLC),
                      0
                    ),
                    2
                  )}
                </td>
                <td>
                  {addDecimalPlaces(
                    batchAvgList.reduce(
                      (total, batch) => total + parseFloat(batch.totalLoss),
                      0
                    ),
                    2
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Stone List */}
      <div className="card">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <strong> Stone List </strong>
            </Accordion.Header>
            <Accordion.Body>
              <div className="card-body table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>No</th>
                      <th>Broken</th>
                      <th>Final</th>
                      <th>Out</th>
                      <th style={{ width: "72px" }}>Pcs</th>
                      {/* {showPartNo && <th>PartNo</th>} */}
                      <th>Length</th>
                      <th>Width</th>
                      <th>Height</th>
                      <th>Weight</th>
                      {/* {showDLCAndLOSS && <th style={{ width: "90px" }}>DLC</th>}
                      {showDLCAndLOSS && (
                        <th style={{ width: "90px" }}>Loss</th>
                      )} */}
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listStones.map((item, index) => (
                      <tr key={item.stoneID}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>{" "}
                        {/* Serial Number */}
                        <td>
                          {item.stoneNo} / {item.batchNo}
                        </td>
                        <td>{item.isBroken ? "True" : ""}</td>
                        <td>{item.isFinal ? "True" : ""}</td>
                        <td>{item.isOut ? "True" : ""}</td>
                        <td>{item.pcs}</td>
                        {/* {showPartNo && <td>{item.partNo}</td>} */}
                        <td>{item.length}</td>
                        <td>{item.width}</td>
                        <td>{item.height}</td>
                        <td>{item.weight}</td>
                        {/* {showDLCAndLOSS && <td>{item.dlc}</td>}
                          {showDLCAndLOSS && <td>{item.loss}</td>} */}
                        <td className="txtCenter">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => removeData(item.stoneID)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={5} style={{ textAlign: "right" }}>
                        <strong> Total</strong>
                      </td>
                      <td>
                        {addDecimalPlaces(
                          batchAvgList.reduce(
                            (total, batch) => total + batch.totalPcs,
                            0
                          ),
                          2
                        )}
                      </td>
                      <td>
                        {addDecimalPlaces(
                          batchAvgList.reduce(
                            (total, batch) =>
                              total + parseFloat(batch.avgtotalLength),
                            0
                          ),
                          2
                        )}
                      </td>
                      <td>
                        {addDecimalPlaces(
                          batchAvgList.reduce(
                            (total, batch) =>
                              total + parseFloat(batch.avgtotalWidth),
                            0
                          ),
                          2
                        )}
                      </td>
                      <td>
                        {addDecimalPlaces(
                          batchAvgList.reduce(
                            (total, batch) =>
                              total + parseFloat(batch.avgtotalHeight),
                            0
                          ),
                          2
                        )}
                      </td>
                      <td>
                        {addDecimalPlaces(
                          batchAvgList.reduce(
                            (total, batch) =>
                              total + parseFloat(batch.totalWeight),
                            0
                          ),
                          2
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      {/* Batch And Stone List End Here */}

      <div className="card">
        <div className="card-body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Index</th>
                <th>Stone Type</th>
                <th>psc</th>
                {/* <th>Length</th>
                <th>Width</th>
                <th>Height</th> */}
                <th>Weight</th>
                <th>DLC</th>
                <th>Loss</th>
                <th className="text-center">Add</th>
                {mixStoneList.length > 1 && (
                  <th className="text-center" style={{ width: "10px" }}>
                    Remove{" "}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {mixStoneList.map((item, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <tr key={`row-${rowIndex}`} style={{ lineHeight: "10px" }}>
                    <td>{rowIndex + 1}</td>
                    <td>
                      <select
                        className="form-control"
                        name="stone_Type"
                        id={`stoneType-${rowIndex}`}
                        // value={mixStoneList[rowIndex]?.stoneType || ""}
                        value={item.stoneType}
                        onChange={(e) =>
                          handleRowInputChange(
                            rowIndex,
                            "stoneType",
                            e.target.value
                          )
                        }
                      >
                        <option value="0">--Stone Type--</option>
                        {options.map((option) => (
                          <option key={option.text} value={option.text}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td style={{ width: "10%" }}>
                      <input
                        type="number"
                        className="form-control"
                        id={`pcs-${rowIndex}`}
                        placeholder="Pcs"
                        value={
                          mixStoneList[rowIndex]?.pcs === 0
                            ? ""
                            : mixStoneList[rowIndex]?.pcs || ""
                        }
                        //value={item.pcs}
                        onChange={(e) =>
                          handleRowInputChange(rowIndex, "pcs", e.target.value)
                        }
                      />
                    </td>
                    {/* <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`length-${rowIndex}`}
                        placeholder="Length"
                        value={
                          mixStoneList[rowIndex]?.length === 0
                            ? ""
                            : mixStoneList[rowIndex]?.length || ""
                        }
                        //value={item.length}
                        onChange={(e) =>
                          handleRowInputChange(
                            rowIndex,
                            "length",
                            addDecimalPlaces(e.target.value, 2)
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`width-${rowIndex}`}
                        placeholder="Width"
                        value={
                          mixStoneList[rowIndex]?.width === 0
                            ? ""
                            : mixStoneList[rowIndex]?.width || ""
                        }
                        //value={item.width}
                        onChange={(e) =>
                          handleRowInputChange(
                            rowIndex,
                            "width",
                            addDecimalPlaces(e.target.value, 2)
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`height-${rowIndex}`}
                        placeholder="Height"
                        value={
                          mixStoneList[rowIndex]?.height === 0
                            ? ""
                            : mixStoneList[rowIndex]?.height || ""
                        }
                        //value={item.height}
                        onChange={(e) =>
                          handleRowInputChange(
                            rowIndex,
                            "height",
                            addDecimalPlaces(e.target.value, 2)
                          )
                        }
                      />
                    </td> */}
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`weight-${rowIndex}`}
                        placeholder="Weight"
                        value={
                          mixStoneList[rowIndex]?.weight === 0
                            ? ""
                            : mixStoneList[rowIndex]?.weight
                            ? addDecimalPlaces(
                                mixStoneList[rowIndex]?.weight,
                                2
                              )
                            : ""
                        }
                        //value={item.weight}
                        onChange={(e) =>
                          handleRowInputChange(
                            rowIndex,
                            "weight",
                            addDecimalPlaces(e.target.value, 2)
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`dlc-${rowIndex}`}
                        placeholder="DLC"
                        value={
                          mixStoneList[rowIndex]?.dlc === 0
                            ? ""
                            : mixStoneList[rowIndex]?.dlc || ""
                        }
                        //value={item.dlc}
                        onChange={(e) =>
                          handleRowInputChange(
                            rowIndex,
                            "dlc",
                            addDecimalPlaces(e.target.value, 2)
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`loss-${rowIndex}`}
                        placeholder="Loss"
                        value={
                          mixStoneList[rowIndex]?.loss === 0
                            ? ""
                            : mixStoneList[rowIndex]?.loss || ""
                        }
                        //value={item.loss}
                        onChange={(e) =>
                          handleRowInputChange(
                            rowIndex,
                            "loss",
                            addDecimalPlaces(e.target.value, 2)
                          )
                        }
                      />
                    </td>
                    <td className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={addRow}
                      >
                        <ion-icon name="add-outline"></ion-icon>
                      </button>
                    </td>
                    {mixStoneList.length > 1 && (
                      <td className="text-center">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removeRow(rowIndex)}
                        >
                          <ion-icon name="trash-outline"></ion-icon>
                        </button>
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-success mt-3 mb-3"
            onClick={() => GenerateMixNo()}
          >
            Generate MixNo
          </button>
        </div>
      </div>

      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <span>MixNo List</span>
          <Button
            type="button"
            variant="success"
            className="btn btn-primary mb-2"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </div>
        <div className="card-body">
          {Array.isArray(MixNoData) ? (
            <AgGridComponent
              rowData={MixNoData}
              columnDefs={columnDefs}
              paginationPageSize={100}
              height="70vh"
            />
          ) : (
            <div>Not found</div>
          )}
        </div>
      </div>
    </>
  );
}
