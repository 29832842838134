import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";

export default function StoneType() {
  const [stoneTypeData, setStoneTypeData] = useState([]);

  useEffect(() => {
    getStoneType();
  }, []);

  // Fetch all StoneTypes
  const getStoneType = () => {
    api
      .get("StoneType/All")
      .then((result) => {
        setStoneTypeData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Insert & Update Data
  const formik = useFormik({
    initialValues: {
      stoneType_id: 0,
      stoneType_name: "",
    },
    validationSchema: Yup.object({
      stoneType_name: Yup.string()
        .min(3, "StoneType Name must be at least 3 characters")
        .required("Enter StoneType Name"),
    }),
    validateOnChange: true, // Enable validation on change
    validateOnBlur: true, // Enable validation on blur
    onSubmit: (values, { resetForm }) => {
      const data = {
        stoneTypeID: values.stoneType_id,
        stoneTypeName: values.stoneType_name,
      };

      const endpoint =
        values.stoneType_id === 0 ? "StoneType/Insert" : "StoneType/Edit";

      api[values.stoneType_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          getStoneType();
          values.stoneType_id === 0
            ? toast.success(result.data.message)
            : toast.success(result.data.message);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
      resetForm();
    },
  });

  // Get data of a single StoneType
  const getSingleStoneType = (id) => {
    api
      .get(`StoneType/ByID?StoneTypeID=${id}`)
      .then((result) => {
        const StoneTypeData = result.data.data;
        setTimeout(() => {
          formik.setFieldValue(
            "stoneType_name",
            StoneTypeData.stoneTypeName
          );
          formik.setFieldValue("stoneType_id", StoneTypeData.stoneTypeID);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete StoneType
  const deleteStoneType = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`StoneType/Delete?StoneTypeID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getStoneType();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "StoneType Name",
      field: "stoneTypeName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Edit",
      width: 100,
      field: "stoneTypeID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleStoneType(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 100,
      field: "stoneTypeID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header", pinned: "right",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteStoneType(value)}
        ></ion-icon>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>New Stone Type</span>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row col-md-12">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="StoneType_name" className="form-label">
                  Stone Type Name:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  id="stoneType_name"
                  placeholder="StoneType Name"
                  {...formik.getFieldProps("stoneType_name")}
                />
                {formik.touched.stoneType_name &&
                  formik.errors.stoneType_name && (
                    <div className="text-danger">
                      {formik.errors.stoneType_name}
                    </div>
                  )}
              </div>
              <label id="stoneType_name"></label>
            </div>
            <div className="card-footer margin_top10">
              <button type="submit" className="btn btn-primary mb-20px">
                {formik.values.stoneType_id > 0 ? "Update" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <span>Stone Type List</span>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={stoneTypeData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="70vh"
          ></AgGridComponent>
        </div>
      </div>
    </>
  );
}
