import React, { useRef, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import api from "../../Utils/api";
import Loader from "../../Components/Common/Loader";

const initialFormData = {
  partyMaster_id: 0,
  Party_Name: "",
  Address: "",
  Contact_Number: "",
  Hastak_Name: "",
  GSTNo: "",
  Broker_Name: "",
};

export default function PartyMasterDD({ partyMasterID }) {
  const [formData, setFormData] = useState(initialFormData);
  const [validated, setValidated] = useState(false);
  const createPartyFormRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const insertOrUpdatePartyMaster = (event) => {
    event.preventDefault();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    const data = {
      partyMasterID: formData.partyMaster_id || 0,
      partyName: formData.Party_Name,
      address: formData.Address,
      contactNumber: formData.Contact_Number,
      hastakName: formData.Hastak_Name,
      gSTNo: formData.GSTNo,
      brokerName: formData.Broker_Name,
    };
    const endpoint =
      formData.partyMaster_id === 0
        ? "PartyMaster/InsertPartyMaster"
        : "PartyMaster/EditPartyMaster";

    const method = formData.partyMaster_id === 0 ? "post" : "patch";

    api[method](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (partyMasterID) {
            partyMasterID(result.data.data);
          }
          resetForm();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.error("Error inserting/updating party master:", error);
        toast.error("An error occurred");
      });
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setValidated(false);
  };

  return (
    <>
      {showLoader && <Loader />}
      <ToastContainer />
      
          <Form
            noValidate
            validated={validated}
            onSubmit={insertOrUpdatePartyMaster}
            ref={createPartyFormRef}
          >
            <Row>
              <Col md={6}>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="PartyName">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Party Name :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="text"
                        name="Party_Name"
                        placeholder="Enter PartyName"
                        value={formData.Party_Name}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value PartyName
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="adderss">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Address :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="adderss">
                      <Form.Control
                        type="text"
                        name="Adderss"
                        placeholder="Enter Address"
                        value={formData.Adderss}
                        onChange={handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the Adderess.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="contactNumber">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Contact.No :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="Contact_Number">
                      <Form.Control
                        type="text"
                        name="Contact_Number"
                        placeholder="Enter Contact Number"
                        value={formData.Contact_Number}
                        onChange={handleInputChange}
                        required
                        pattern="[0-9]*"
                        maxLength="10"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the Contact Number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="HastakName">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Hastak Name :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="Hastak_Name">
                      <Form.Control
                        type="text"
                        name="Hastak_Name"
                        placeholder="Enter HastakName"
                        value={formData.Hastak_Name}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the number of pieces.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="GSTNo">
                      <Form.Label style={{ marginRight: "20px" }}>
                        GSTNo :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="GSTNo">
                      <Form.Control
                        type="text"
                        name="GSTNo"
                        placeholder="Enter GST Number"
                        value={formData.GSTNo}
                        onChange={handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the GST Number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="brokerName">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Broker Name :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="Broker_Name">
                      <Form.Control
                        type="text"
                        name="Broker_Name"
                        placeholder="Enter BrokerName"
                        value={formData.Broker_Name}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the number of pieces.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                {formData?.partyMaster_id > 0 ? "Update" : "Save"}
              </Button>
            </div>
          </Form>
    </>
  );
}
