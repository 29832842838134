import React, { useState, useEffect } from "react";
import { Card, Form, Button, Table, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Loader from "../../Components/Common/Loader";

export default function TransferToFinal() {
  const [searchMixNo, setSearchMixNo] = useState("");
  const [StoneList, setStoneList] = useState([]);
  const [options, setOptions] = useState([]);
  const [useroptions, setUseroptions] = useState([]);
  const [departmentID, setDepartmentID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getAllDepartment();
  }, []);

  useEffect(() => {
    if (departmentID !== null) {
      api
        .get(`User/DepartmentViseUser?departmentID=${departmentID.value}`)
        .then((result) => {
          setUseroptions(result.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [departmentID]);

  const getAllDepartment = () => {
    api
      .get("Department/All")
      .then((result) => {
        setOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ddDepartmentNameList = (selectedOption) => {
    setDepartmentID(selectedOption);
  };

  // Handle user dropdown selection
  const ddUserNameList = (selectedOption) => {
    setUserID(selectedOption);
  };

  const SearchMixStoneData = (e) => {
    api
      .get(`Stone/MixNoFindForTransferToFinal?MixNo=${searchMixNo}`)
      .then((result) => {
        let Mixdata = result.data.data;

        if (Mixdata != null) {
          if (StoneList.length > 0) {
            let MixNoIsInList = StoneList.some(
              (stone) => stone.mno === Mixdata[0].mno
            );

            if (MixNoIsInList) {
              toast.error("Stone is already in the list");
              return;
            } else {
              setStoneList((prevData) => [...prevData, ...Mixdata]);
            }
          } else {
            setStoneList((prevData) => [...prevData, ...Mixdata]);
          }
        }

        setSearchMixNo("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const OnEnterSearchMixStoneData = (e) => {
    if (e.key === "Enter") {
      SearchMixStoneData();
    }
  };

  const TransferToFinal = () => {
    if (departmentID === null) {
      toast.error("Please Select Department.");
      return;
    }
    if (userID === null) {
      toast.error("Please Select User.");
      return;
    }
    setShowLoader(true);
    const data = {
      DepartmentID: departmentID?.value,
      UserID: userID?.value,
      ListStone: StoneList,
    };
    api
      .post(`Stone/TransferToFinalStone`, data)
      .then((result) => {
        toast.success(result.data.message);
        setSearchMixNo("");
        setStoneList([]);
        setUserID(null);
        setDepartmentID(null);
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
        setShowLoader(false);
      });
  };

  const removeData = (MixNoToRemove) => {
    const updatedList = StoneList.filter(
      (stone) => stone.stoneNo !== MixNoToRemove
    );
    setStoneList(updatedList);
  };

  return (
    <>
      {showLoader && <Loader />}

      <ToastContainer />
      <Card>
        <Card.Header>
          <span>Transfer To Final</span>
        </Card.Header>
        <Card.Body>
          <div className="row align-middle d-flex justify-content-center align-items-center">
            <div className="col-md-2 col-sm-2 col-lg-2 d-flex justify-content-end align-items-center">
              <label htmlFor="Find_no">
                <div className="d-flex align-items-center">
                  {" "}
                  <span>Find MixNo: </span>
                  {/* <button
                    type="button"
                    style={{ marginLeft: "20px" }}
                    className="btn btn-primary"
                    //onClick={toggleBarcodeScanner}
                  >
                    <i className="fa fa-close"></i>
                  </button> */}
                </div>
              </label>
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4">
              <input
                type="text"
                className="form-control"
                id="searchMicNo"
                placeholder="Search Mix No"
                value={searchMixNo}
                onChange={(e) => {
                  setSearchMixNo(e.target.value);
                  // PrintMixStoneSourceReport();
                }}
                onKeyDown={OnEnterSearchMixStoneData}
              />
            </div>
            <div className="col-md-1 col-sm-1 col-lg-1 searchBtn">
              <button
                type="button"
                className="align-items-center btn btn-primary"
                onClick={(e) => SearchMixStoneData()}
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <strong> Stone List </strong>
            </Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th> Sr.No</th>
                    <th>Stone No</th>
                    <th>Stone Type</th>
                    <th>Pcs</th>
                    <th>Length</th>
                    <th>Width</th>
                    <th>Height</th>
                    <th>Weight</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {StoneList.length > 0 ? (
                    StoneList.map((stone, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{stone.stoneNo}</td>
                        <td>{stone.stoneType}</td>
                        <td>{stone.pcs}</td>
                        <td>{stone.length}</td>
                        <td>{stone.width}</td>
                        <td>{stone.height}</td>
                        <td>{stone.weight !== null ? stone.weight.toFixed(2) : 0}</td>
                        <td>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => removeData(stone.stoneNo)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9">No stones available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>

      <Card>
        <Card.Body>
          <Row className="align-items-center">
            <Col md={2}>
              <Form.Group>
                <Form.Label htmlFor="departmentDropdown">
                  Department:
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Select
                  id="departmentDropdown"
                  value={departmentID}
                  onChange={ddDepartmentNameList}
                  options={options.map((option) => ({
                    value: option.departmentID,
                    label: option.departmentName,
                  }))}
                  placeholder="--Select Department--"
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label htmlFor="userDropdown">User:</Form.Label>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Select
                  id="userDropdown"
                  value={userID}
                  onChange={ddUserNameList}
                  options={useroptions.map((useroption) => ({
                    value: useroption.userID,
                    label: useroption.userName,
                  }))}
                  placeholder="--Select User--"
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button
            type="button"
            className="align-items-center btn btn-primary mb-20px mb-1"
            onClick={TransferToFinal}
          >
            save
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}
