import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./StoneHistorypopup.css"; // Import CSS file for styling
import api from "../../Utils/api";

function StoneHistorypopup({ show, onHide, BatchNo, stoneProcessNo }) {
  const [modalStoneLotData, setModalStoneLotData] = useState([]);

  useEffect(() => {
    ;
    if (stoneProcessNo && BatchNo) {
      api
        .get(
          `Stone/PrintStoneStickerFromBatchNo?BatchNo=${BatchNo}&StoneProcessNo=${stoneProcessNo}`
        )
        .then((result) => {
          setModalStoneLotData(result.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [stoneProcessNo, BatchNo]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"black"}>
          <Modal.Title id="example-custom-modal-styling-title">
            Stone Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Batch No</th>
                <th scope="col">Stone No</th>
                <th scope="col">Stone Pro. No</th>
                <th scope="col">Stone Type</th>
                <th scope="col">Length</th>
                <th scope="col">Width</th>
                <th scope="col">Height</th>
                <th scope="col">Weight</th>
                <th scope="col">Loss</th>
              </tr>
            </thead>
            <tbody>
              {modalStoneLotData.map((item, index) => (
                <tr key={index}>
                  <td>{item.batchNo}</td>
                  <td>{item.stoneNo}</td>
                  <td>{item.stoneProcessNo}</td>
                  <td>{item.stoneType}</td>
                  <td>{item.length}</td>
                  <td>{item.width}</td>
                  <td>{item.height}</td>
                  <td>{item.weight}</td>
                  <td>{item.loss}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StoneHistorypopup;
