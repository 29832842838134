// import { React } from "react";
// import LightGallery from "lightgallery/react";
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";
// import { imgURL } from "../../imgConfig";

// const LightGalleryComponent = ({ imageList, url }) => {
//   
//   const groupedImages = imageList.reduce((acc, item) => {
//     const createdDate = item.createDate; // Convert to a date string
//     if (!acc[createdDate]) {
//       acc[createdDate] = [];
//     }
//     acc[createdDate].push(item);
//     return acc;
//   }, {});

//   return (
//     <>
//       {Object.entries(groupedImages).map(([createdDate, images]) => (
//         <div key={createdDate}>
//           <div className="row">
//             <div className="divLabel">
//               <strong>{createdDate}</strong>
//             </div>
//             <div className="box">
//               <div className="box-header with-border">
//                 <div className="box-body">
//                   <LightGallery
//                     plugins={[lgZoom, lgThumbnail]}
//                     mode="lg-fade"
//                     id="lightgallery" // Assign an id to the LightGallery component
//                   >
//                     {images.map(
//                       (item, index) =>
//                         item.imagePath !== null && (
//                           <a
//                             key={index}
//                             href={imgURL + item.imagePath}
//                             className="col-md-3 lightgalleryimage"
//                           >
//                             <div
//                               style={{
//                                 justifyContent: "center",
//                                 fontWeight: "bold",
//                               }}
//                             >
//                               <div>{index + 1}</div>
//                               <img
//                                 src={imgURL + item.imagePath}
//                                 alt=""
//                                 style={{ height: "200px" }}
//                                 className="img-thumbnail img-responsive mt-2 mb-2 "
//                               />
//                             </div>
//                           </a>
//                         )
//                     )}
//                   </LightGallery>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </>
//   );
// };
// export default LightGalleryComponent;

import React from "react";
import "lightbox.js-react/dist/index.css";
import { SlideshowLightbox } from "lightbox.js-react";

const LightGalleryComponent = ({ imageList, imgURL }) => {
  ;
  const groupedImages = imageList.reduce((acc, item) => {
    const createdDate = item.createDate;
    if (!acc[createdDate]) {
      acc[createdDate] = [];
    }
    acc[createdDate].push(item);
    return acc;
  }, {});

  // useEffect(() => {
  //   initLightboxJS("Insert License key", "Insert plan type here");
  // });

  return (
    <>
      {Object.entries(groupedImages).map(([createdDate, images]) => (
        <div key={createdDate}>
          <div className="row">
            <div className="divLabel">
              <strong>{createdDate}</strong>
            </div>
            <div className="box">
              <div className="box-header with-border">
                <div className="box-body">
                  <SlideshowLightbox
                    className="container grid grid-cols-3 gap-2 mx-auto"
                    showThumbnails={true}
                  >
                    {images.map(
                      (item, index) =>
                        item.imagePath !== null && (
                          // <div
                          //   key={index}
                          //   className="col-md-3 lightgalleryimage"
                          // >
                          <img
                            className="w-full rounded lightgalleryimage"
                            src={imgURL + item.imagePath}
                            alt=""
                          />
                          // </div>
                        )
                    )}
                  </SlideshowLightbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default LightGalleryComponent;
