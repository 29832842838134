import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from '../Utils/api'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
        },
        logout: (state) => {
            state.user = null;
        },
    },
});


export const loginUser = createAsyncThunk(
    "loginUser",
    async (data, thunkAPI) => {
        try {
            const response = await api.post('/Login/CheckLogin', data);
            if (response.data.statusCode === 200) {
                localStorage.setItem('token', response.data.data.token)
                localStorage.setItem('userName', response.data.data.userNameForPrint)
                localStorage.setItem('user_ID', response.data.data.uid)
                window.location.reload(false);
                return response.data;
            } else {
                toast.error(response.data.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Login Failed',
                    text: response.data.message,
                });
            }
        } catch (error) {
            console.log("error", error.response);
            toast.error(error.response.data.message);
            Swal.fire({
                icon: 'error',
                title: 'Login Failed',
                text: error.response.data.message,
            });
            return thunkAPI.rejectWithValue(error.response.data.error);
        }
    }
);


export const { login, logout } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;