import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import ImageUploadModalComponent from "../../Components/Common/ImageUploadModalComponent";
import ImageViewerModalComponent from "../../Components/Common/ImageViewerModalComponent";
import { getCurrentDateTime } from "../../Utils/commonfunctions";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

export default function ReceiveFromMachine() {
  const [MachineOnData, setMachineOnData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [oneMachineData, setOneMachineData] = useState([]);
  const [machineStopTime, setMachineStopTime] = useState("");
  const [workerNo, setWorkerNo] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [machineComments, setMachineComments] = useState("");

  //Insert Image
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  //Image Viewer
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedBatchNo, setSelectedBatchNo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRunningMachine();
  }, []);

  const getRunningMachine = () => {
    setIsLoading(true); // Show loader
    api
      .get("IssueToMachine/MachineDataForMachineDashboard")
      .then((result) => {
        setMachineOnData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  const handleStartClick = (issueToWorkerNo) => {
    
    setIsModalOpen(true);
    setWorkerNo(issueToWorkerNo);
    setMachineComments("");
    setMachineStopTime(getCurrentDateTime);
    // TimeDifference();
    setTotalHours(0);
    setTotalMinutes(0);
    api
      .get(
        `IssueToMachine/MachineDataForChangingStoneValueByID?IssueToWorkerNo=${issueToWorkerNo}`
      )
      .then((result) => {
        setOneMachineData(result.data.data);
        TimeDifference();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const TimeDifference = () => {
    setOneMachineData((items) =>
      items.map((item) => {
        const machineTimeDate = new Date();
        const machineTime = new Date(item.machineTime);
        const timeDifference = machineTimeDate - machineTime;

        const totalSeconds = Math.floor(timeDifference / 1000);
        const totalHours = Math.floor(totalSeconds / 3600);
        const formattedMachineTime = machineTime.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true, // Use 12-hour clock with AM/PM
        });
        setTotalHours(totalHours);
        return {
          ...item,
          machineTimeStart: formattedMachineTime,
        };
      })
    );
  };

  const StopMachine = () => {
    ;
    const FinalMinutes = totalHours * 60 + parseInt(totalMinutes);

    if (FinalMinutes === null || FinalMinutes === 0) {
      return Swal.fire("Error", "Please Enter Hours.", "error");
    }

    const Data = {
      issueToWorkerNo: workerNo,
      machineTimeStart: machineStopTime,
      listStone: oneMachineData,
      MachineHours: FinalMinutes,
      Comment: machineComments,
    };
    api
      .post(`IssueToMachine/MachineEndTime`, Data)
      .then((result) => {
        toast.success(result.data.message);
        setIsModalOpen(false);
        setMachineStopTime("");
        setMachineComments("");
        getRunningMachine();
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      });
  };
  // Insert Image
  const popUpForImage = (data) => {
    setImageModalOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    setImageModalOpen(false);
  };
  // Insert Image End

  //Image Viewer Start
  const popUpForViewImage = (batchNo) => {
    setSelectedBatchNo(batchNo);
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
    setSelectedBatchNo(null);
  };
  //Image Viewer End

  return (
    <>
      {isLoading && (
        <>
          <div className="overlay"></div>
          <div className="overlay-content text-center">
            <Spinner animation="" variant="primary">
              <span class="loader"></span>
            </Spinner>
          </div>
        </>
      )}
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>Receive From Machine</span>
        </div>
        <div className="card-body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: "120px" }}>Machine No</th>
                <th style={{ width: "100px" }}>Batch No</th>
                <th style={{ width: "100px" }}>Image</th>
                <th style={{ width: "100px" }}>View</th>
                <th style={{ width: "100px" }}>Status</th>
                <th>Total Pcs</th>
                <th>Machine Start Time</th>
                <th>Avg TotalLength</th>
                <th>Avg TotalHeight</th>
              </tr>
            </thead>
            <tbody>
              {MachineOnData &&
                MachineOnData.map((item) => (
                  <tr key={item.issueToWorkerNo}>
                    <td>{item.machineNo}</td>
                    <td>{item.batchNo}</td>
                    <td>
                      <ion-icon
                        name="attach-outline"
                        class="iconsFonts attachIcon"
                        onClick={() => popUpForImage(item)}
                      ></ion-icon>
                    </td>
                    <td>
                      <ion-icon
                        name="eye-outline"
                        class="iconsFonts attachIcon"
                        onClick={() => popUpForViewImage(item.batchNo)}
                      ></ion-icon>
                    </td>
                    <td>
                      <button
                        className="btn btn-success mb-2"
                        onClick={() => handleStartClick(item.issueToWorkerNo)}
                      >
                        Stop
                      </button>
                    </td>
                    <td>{item.totalPcs}</td>
                    <td>{item.machineTime}</td>
                    <td>{item.avgtotalLength}</td>
                    <td>{item.avgtotalHeight}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* <div
        className={`modal fade ${isModalOpen ? "show d-block" : ""}`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={!isModalOpen}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Machine Stop</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                {oneMachineData.length > 0 && (
                  <>
                    <div className="row col-md-12">
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <label htmlFor="machineStopTime"> Start Time:</label>
                      </div>
                      <div className="col-md-4 col-sm-4 col-lg-4">
                        <input
                          type="text"
                          className="form-control"
                          id="machineStopTime"
                          placeholder="Enter Machine Start Time"
                          value={oneMachineData[0].machineTimeStart}
                          disabled
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row col-md-12 mt-4">
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label htmlFor="machineStopTime"> Stop Time:</label>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="machineStopTime"
                      placeholder="Enter Machine Start Time"
                      value={machineStopTime}
                      onChange={(e) => setMachineStopTime(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row col-md-12 mt-4">
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label htmlFor="MAchineHours">Hrs./Min.</label>
                  </div>
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <input
                      type="number"
                      className="form-control"
                      id="machineStopHours"
                      placeholder="Enter Hours"
                      value={totalHours}
                      onChange={(e) => setTotalHours(e.target.value)}
                    />
                  </div>

                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <input
                      type="number"
                      className="form-control"
                      id="machineStopMinutes"
                      placeholder="Enter Minutes"
                      value={totalMinutes}
                      onChange={(e) => setTotalMinutes(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row col-md-12 mt-4">
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label htmlFor="MachineComment">Comment:</label>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <textarea
                      name="postContent"
                      className="form-control"
                      id="MachineComments"
                      rows={4}
                      placeholder="Comments"
                      value={machineComments}
                      onChange={(e) => setMachineComments(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer margin_top10">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => StopMachine()}
              >
                Stop Machine
              </button>
            </div>
          </div>
        </div>
      </div> */}

<Modal show={isModalOpen} onHide={handleCloseModal}  centered size="xl">
  <Modal.Header closeButton>
    <Modal.Title>Machine Stop</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      {oneMachineData.length > 0 && (
        <Row className="mb-3">
          <Form.Label column md={2}>
            Start Time:
          </Form.Label>
          <Col md={4}>
            <Form.Control
              type="text"
              value={oneMachineData[0].machineTimeStart}
              disabled
            />
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Form.Label column md={2}>
          Stop Time:
        </Form.Label>
        <Col md={4}>
          <Form.Control
            type="datetime-local"
            value={machineStopTime}
            onChange={(e) => setMachineStopTime(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Form.Label column md={2}>
          Hrs./Min.:
        </Form.Label>
        <Col md={2}>
          <Form.Control
            type="number"
            placeholder="Hours"
            value={totalHours}
            onChange={(e) => setTotalHours(e.target.value)}
          />
        </Col>
        <Col md={2}>
          <Form.Control
            type="number"
            placeholder="Minutes"
            value={totalMinutes}
            onChange={(e) => setTotalMinutes(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Form.Label column md={2}>
          Comment:
        </Form.Label>
        <Col md={4}>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Comments"
            value={machineComments}
            onChange={(e) => setMachineComments(e.target.value)}
          />
        </Col>
      </Row>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={StopMachine}>
      Stop Machine
    </Button>
  </Modal.Footer>
</Modal>

      {/* Insert Image */}
      {imageModalOpen && (
        <ImageUploadModalComponent
          showModal={imageModalOpen}
          onClose={closeModal} // Pass the function to close the modal
          bNO={modalData.batchNo}
          sNO={modalData.stoneNo}
          referenceID={modalData.issueToWorkerID}
          type="Machine Stop"
        />
      )}

      {/* Image Viewer */}
      {isImageViewerOpen && (
        <ImageViewerModalComponent
          showModal={isImageViewerOpen}
          onClose={closeImageViewer}
          referenceID={selectedBatchNo}
          refType="Machine Stop"
        />
      )}
    </>
  );
}
