import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from "ag-grid-react";

const AgGridComponent = ({
  grid,
  rowData,
  columnDefs,
  onSelectionChanged,
  autoGroupColumnDef,
  createFooterData,
  gridOptions,
  pinnedBottomRowData,
  onFilterChanged,
  paginationPageSize = 20,
  height = 500,
  loading

}) => {
  return (
    <div className="ag-theme-quartz my-grid-margin" style={{height : height}} >
      <AgGridReact
        ref={grid}
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={true}
        rowSelection="multiple"
        onSelectionChanged={onSelectionChanged}
        animateRows={true}
        gridOptions={gridOptions}
        pinnedBottomRowData={pinnedBottomRowData}
        onFilterChanged={onFilterChanged}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={[paginationPageSize,10, 25, 50,200,500]}
        suppressServerSideFullWidthLoadingRow={true}
        loading={loading}
      ></AgGridReact>
    </div>
  );
};

export default AgGridComponent;
