import React, { useState, useEffect } from "react";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { ISOToDateTime } from "../../Components/Common/Common";
import Spinner from "react-bootstrap/Spinner";
import { Form, Col, Modal, Button, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getCurrentDate } from "../../Utils/commonfunctions";
import Select from "react-select";
import PartyMasterDD from "../PartyMaster/PartyMasterDD";
import Loader from "../../Components/Common/Loader";
import ReactDOM from "react-dom";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import _ from "lodash";

const sumAggFunc = (params) => {
  const values = params.values;
  let sum = 0;

  values.forEach((value) => {
    sum += value;
  });

  return sum;
};

const pinnedBottomRowData = [
  {
    receivedDate: null,
    stoneNo: "",
    stoneType: "TOTAL",
    pcs: 0,
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
  },
];

var selectedBagForCreateJangad = [];
export default function CurrentstockofuserWithOutBag({ subUserID }) {
  const [stoneList, setStoneList] = useState([]);
  const [totalStock, setTotalStock] = useState({
    totalPcs: 0,
    totalLength: 0,
    totalWidth: 0,
    totalHeight: 0,
    totalWeight: 0,
  });
  const [issueToWorkerList, setIssueToWorkerList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [sums, setSums] = useState({
    footerTotalPcs: 0,
    footerTotalLength: 0,
    footerTotalWidth: 0,
    footerTotalHeight: 0,
    footerTotalWeight: 0,
  });

  useEffect(() => {
    getStockList();
    getIssueToWorkerList();
  }, [subUserID]);

  const [selectedBagForJangad, setSelectedBagForJangad] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [partyMasterName, setPartyMasterName] = useState([]);
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [selectedParty, setSelectedParty] = useState("");

  const handlepartymasterChange = (option) => {
    setSelectedParty(option);
  };

  useEffect(() => {
    getAllPartyMsater();
  }, []);

  const getAllPartyMsater = () => {
    return api
      .get("PartyMaster/All")
      .then((result) => {
        setPartyMasterName(result.data.data);
        return result.data.data;
      })
      .catch((error) => {
        console.log(error);
        throw error; // Rethrow the error to propagate it to the caller
      });
  };

  const InsertJangad = () => {
    setIsLoading(true);
    if (!selectedDate) {
      toast.error("Please Select Date.");
      setIsLoading(false);
      return;
    }
    if (!selectedParty) {
      toast.error("Please Select Party.");
      setIsLoading(false);
      return;
    }
    if (selectedBagForCreateJangad.length === 0) {
      toast.error("Please add ASI details.");
      setIsLoading(false);
      return;
    }

    const data = {
      Date: selectedDate,
      PartyMasterID: selectedParty.value,
      BagData: selectedBagForCreateJangad,
    };

    api
      .post(`Jangad/InsertJangadData`, data)
      .then((result) => {
        toast.success(result.data.message);
        setSelectedBagForJangad([]);
        selectedBagForCreateJangad = [];
        setSelectedDate("");
        setSelectedParty(null);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
        setIsLoading(false);
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsLoading(false);
        getStockList();
      });
  };

  const closeModal = () => {
    setSelectedBagForJangad([]);
    selectedBagForCreateJangad = [];
    setSelectedDate("");
    setSelectedParty(null);
    setIsModalOpen(false);
    getStockList();
  };

  const handleCheckboxSelection = (event) => {
    // setSelectedBagForJangad([]);
    const selectedRows = event.api.getSelectedRows();
    const selectedBagRow = Array.isArray(selectedRows) ? selectedRows : [];
    selectedBagForCreateJangad = selectedBagRow;
    console.count(selectedBagForCreateJangad);
    //  setSelectedBagForJangad(selectedBagRow);
  };

  // Code For Add Party MASTER
  const [showPartyMasterModel, setShowPartyMasterModel] = useState(false);

  const selectDDJangadID = (partyMasterID) => {
    getAllPartyMsater()
      .then((data) => {
        const selectedParty = data.find(
          (f) => f.partyMasterID === partyMasterID
        );
        if (selectedParty) {
          var option = {
            value: partyMasterID,
            label: selectedParty.partyName,
          };
          handlepartymasterChange(option);
          setShowPartyMasterModel(false);
        } else {
          console.error("Party master not found for ID:", partyMasterID);
          // Optionally, handle the case where party master is not found
        }
      })
      .catch((error) => {
        console.error("Error fetching party master data:", error);
        // Optionally, handle the error
      });
  };

  const getStockList = () => {
    setIsLoading(true); // Show loader
    api
      .get(`Stone/CurrentStockOfUser?UserID=${subUserID}`)
      .then((result) => {
        setStoneList(result.data.data.listStoneDataModel);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  const getIssueToWorkerList = () => {
    api
      .get(`IssueToWorker/CurrentIssueToWorkOfUser?UserID=${subUserID}`)
      .then((result) => {
        setIssueToWorkerList(result.data.data.list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Data Start
  useEffect(() => {
    updatePinnedBottomRowData();
    setTopTotal();
  }, [stoneList]);

  const setTopTotal = (event) => {
    // Get the calculated sums
    const sums = calculateSums(event);

    // Update the totalStock state with the calculated values
    setTotalStock({
      totalPcs: sums.pcs, // Set total pcs
      totalLength: sums.length.toFixed(2), // Format to 2 decimal places
      totalWidth: sums.width.toFixed(2), // Format to 2 decimal places
      totalHeight: sums.height.toFixed(2), // Format to 2 decimal places
      totalWeight: sums.weight.toFixed(2), // Format to 2 decimal places
    });
  };

  const calculateSums = (event) => {
    let filteredData;

    if (event === undefined) {
      filteredData = stoneList;
    } else {
      filteredData = event.api.getModel().rowsToDisplay.map((row) => row.data);
    }

    const filteredSumPcs = _.sumBy(filteredData, "pcs");

    let totalLength = 0;
    let totalWidth = 0;
    let totalHeight = 0;

    filteredData.forEach((item) => {
      totalLength += item.pcs * item.length;
      totalWidth += item.pcs * item.width;
      totalHeight += item.pcs * item.height;
    });

    const filteredSumLength = parseFloat(
      (totalLength / filteredSumPcs).toFixed(2)
    );
    const filteredSumWidth = parseFloat(
      (totalWidth / filteredSumPcs).toFixed(2)
    );
    const filteredSumHeight = parseFloat(
      (totalHeight / filteredSumPcs).toFixed(2)
    );
    const filteredSumWeight = parseFloat(
      _.sumBy(filteredData, "weight").toFixed(2)
    );

    return {
      pcs: filteredSumPcs,
      length: isNaN(filteredSumLength) ? 0 : filteredSumLength,
      width: isNaN(filteredSumWidth) ? 0 : filteredSumWidth,
      height: isNaN(filteredSumHeight) ? 0 : filteredSumHeight,
      weight: filteredSumWeight,
    };
  };

  const updatePinnedBottomRowData = (event) => {
    const sums = calculateSums(event);

    pinnedBottomRowData[0].pcs = sums.pcs;
    pinnedBottomRowData[0].length = sums.length;
    pinnedBottomRowData[0].width = sums.width;
    pinnedBottomRowData[0].height = sums.height;
    pinnedBottomRowData[0].weight = sums.weight;

    // setTopTotal(); // Uncomment if needed
  };

  const onFilterChanged = (event) => {
    updatePinnedBottomRowData(event);
    if (event) {
      event.api.refreshCells({ force: true, suppressFlash: true });
    }
  };

  // const defaultColDef = {
  //   flex: 1,
  //   minWidth: 100,
  //   filter: true,
  // };

  // Function to export both stoneList and issueToWorkerList to Excel
  // const exportToExcel = () => {
  //   const fileType =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //   const fileExtension = ".xlsx";

  //   // Create a new workbook
  //   const wb = XLSX.utils.book_new();

  //   // Convert stoneList to Excel sheet
  //   const stoneWs = XLSX.utils.json_to_sheet(stoneList);
  //   XLSX.utils.book_append_sheet(wb, stoneWs, "Stock List");

  //   // Convert issueToWorkerList to Excel sheet
  //   const workerWs = XLSX.utils.json_to_sheet(issueToWorkerList);
  //   XLSX.utils.book_append_sheet(wb, workerWs, "Worker List");

  //   // Save the workbook as an Excel file
  //   XLSX.writeFile(wb, `stock_and_worker_lists${fileExtension}`);
  // };

  const exportToExcel = () => {
    const fileName = "stock_and_worker_list.xlsx";

    // Define the columns based on columnDefs for stock list
    const stockColumns = [
      {
        header: "Date",
        key: "receivedDate",
        formatter: (value) => ISOToDateTime(value),
      },
      { header: "No", key: "stoneNo" },
      { header: "CH", key: "ch" },
      { header: "Type", key: "stoneType" },
      { header: "Pcs", key: "pcs" },
      { header: "Length", key: "length" },
      { header: "Width", key: "width" },
      { header: "Height", key: "height" },
      { header: "Weight", key: "weight" },
    ];

    // Define the columns based on columnDefs for worker list
    const workerColumns = [
      { header: "Worker Name", key: "workerName" },
      { header: "StoneNo", key: "stoneNo" },
      { header: "Pcs", key: "pcs" },
      { header: "Length", key: "length" },
      { header: "Width", key: "width" },
      { header: "Height", key: "height" },
      { header: "Weight", key: "weight" },
    ];

    // Format the stock data based on the columns
    const formattedStockData = stoneList.map((item) => {
      const formattedItem = {};
      stockColumns.forEach((col) => {
        formattedItem[col.header] = col.formatter
          ? col.formatter(item[col.key])
          : item[col.key];
      });
      return formattedItem;
    });

    // Format the worker data based on the columns
    const formattedWorkerData = issueToWorkerList.map((item) => {
      const formattedItem = {};
      workerColumns.forEach((col) => {
        formattedItem[col.header] = item[col.key];
      });
      return formattedItem;
    });

    // Calculate totals and averages for stock data
    const stockItemCount = stoneList.length;
    const stockTotalPcs = stoneList.reduce((sum, item) => sum + item.pcs, 0);
    const stockAvgLength = (
      stoneList.reduce((sum, item) => sum + item.length, 0) / stockItemCount
    ).toFixed(2);
    const stockAvgWidth = (
      stoneList.reduce((sum, item) => sum + item.width, 0) / stockItemCount
    ).toFixed(2);
    const stockAvgHeight = (
      stoneList.reduce((sum, item) => sum + item.height, 0) / stockItemCount
    ).toFixed(2);
    const stockTotalWeight = stoneList
      .reduce((sum, item) => sum + item.weight, 0)
      .toFixed(2);

    const stockTotals = {
      Date: "",
      No: "",
      CH: "",
      Type: "Total",
      Pcs: stockTotalPcs,
      Length: stockAvgLength,
      Width: stockAvgWidth,
      Height: stockAvgHeight,
      Weight: stockTotalWeight,
    };

    // Calculate totals and averages for worker data
    const workerItemCount = issueToWorkerList.length;
    const workerTotalPcs = issueToWorkerList.reduce(
      (sum, item) => sum + item.pcs,
      0
    );
    const workerAvgLength = (
      issueToWorkerList.reduce((sum, item) => sum + item.length, 0) /
      workerItemCount
    ).toFixed(2);
    const workerAvgWidth = (
      issueToWorkerList.reduce((sum, item) => sum + item.width, 0) /
      workerItemCount
    ).toFixed(2);
    const workerAvgHeight = (
      issueToWorkerList.reduce((sum, item) => sum + item.height, 0) /
      workerItemCount
    ).toFixed(2);
    const workerTotalWeight = issueToWorkerList
      .reduce((sum, item) => sum + item.weight, 0)
      .toFixed(2);

    const workerTotals = {
      "Worker Name": "",
      StoneNo: "",
      Pcs: workerTotalPcs,
      Length: workerAvgLength,
      Width: workerAvgWidth,
      Height: workerAvgHeight,
      Weight: workerTotalWeight,
    };

    const workbook = new ExcelJS.Workbook();

    // Add stock list worksheet
    const stockWorksheet = workbook.addWorksheet("Stock List");

    // Set the worksheet columns for stock list
    stockWorksheet.columns = stockColumns.map((col) => ({
      header: col.header,
      key: col.header,
      width: 20,
    }));

    // Add the formatted stock data to the worksheet
    stockWorksheet.addRows(formattedStockData);

    // Add the totals row in stock worksheet
    stockWorksheet.addRow(stockTotals);

    // Style the header row in stock worksheet
    const stockHeaderRow = stockWorksheet.getRow(1); // Header row is now the first row
    stockHeaderRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF4F81BD" },
      };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Style the totals row in stock worksheet
    const stockTotalsRow = stockWorksheet.getRow(stockWorksheet.lastRow.number);
    stockTotalsRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Adjust column widths based on content length in stock worksheet
    stockWorksheet.columns.forEach((column) => {
      let maxLength = column.header.length;
      formattedStockData.forEach((row) => {
        const cellValue = row[column.key];
        if (cellValue) {
          maxLength = Math.max(maxLength, cellValue.toString().length);
        }
      });
      const totalsValue = stockTotals[column.key];
      if (totalsValue) {
        maxLength = Math.max(maxLength, totalsValue.toString().length);
      }
      column.width = maxLength + 2; // Add padding
    });

    // Add worker list worksheet
    const workerWorksheet = workbook.addWorksheet("Worker List");

    // Set the worksheet columns for worker list
    workerWorksheet.columns = workerColumns.map((col) => ({
      header: col.header,
      key: col.header,
      width: 20,
    }));

    // Add the formatted worker data to the worksheet
    workerWorksheet.addRows(formattedWorkerData);

    // Add the totals row in worker worksheet
    workerWorksheet.addRow(workerTotals);

    // Style the header row in worker worksheet
    const workerHeaderRow = workerWorksheet.getRow(1);
    workerHeaderRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF4F81BD" },
      };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Style the totals row in worker worksheet
    const workerTotalsRow = workerWorksheet.getRow(
      workerWorksheet.lastRow.number
    );
    workerTotalsRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Adjust column widths based on content length in worker worksheet
    workerWorksheet.columns.forEach((column) => {
      let maxLength = column.header.length;
      formattedWorkerData.forEach((row) => {
        const cellValue = row[column.key];
        if (cellValue) {
          maxLength = Math.max(maxLength, cellValue.toString().length);
        }
      });
      const totalsValue = workerTotals[column.key];
      if (totalsValue) {
        maxLength = Math.max(maxLength, totalsValue.toString().length);
      }
      column.width = maxLength + 2; // Add padding
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);
    });
  };

  //End New Code

  const JangadModelOpen = () => {
    if (selectedBagForCreateJangad.length > 0) {
      setIsModalOpen(true);
    } else {
      toast.error("Select A ASI");
    }
  };

  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      width: 50,
      headerClass: "centered-header",
      pinned: "left",
    },
    {
      headerName: "Date",
      field: "receivedDate",
      sortable: true,
      filter: "agDateColumnFilter",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => {
        if (params.value) {
          return ISOToDateTime(params.value);
        }
        return "";
      },
    },
    {
      headerName: "No",
      field: "stoneNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "CH",
      field: "ch",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      // valueFormatter: ({ value }) => {
      //   if (value > 0) {
      //     return "CH";
      //   }
      //   return "";
      // },
    },
    {
      headerName: "Type",
      field: "stoneType",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pcs",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        if (value === 0) {
          return "0";
        } else {
          return value;
        }
      },
      //
    },
    {
      headerName: "Length",
      field: "length",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Width",
      field: "width",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Height",
      field: "height",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
      // Use the sumAggFunc for aggregation
    },
  ];

  const workerColumnDefs = [
    {
      headerName: "Worker Name",
      field: "workerName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "StoneNo",
      field: "stoneNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pcs",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Length",
      field: "length",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Width",
      field: "width",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Height",
      field: "height",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
  ];

  //Print Section Start
  const PrintPreview = ({ stoneDetails }) => {
    const totals = stoneDetails.reduce(
      (acc, stone) => {
        acc.pcs += stone.pcs;
        acc.length += stone.length;
        acc.width += stone.width;
        acc.height += stone.height;
        acc.weight += stone.weight;
        return acc;
      },
      { pcs: 0, length: 0, width: 0, height: 0, weight: 0 }
    );

    // Calculate average values
    const itemCount = stoneDetails.length;
    const avgLength = itemCount ? (totals.length / itemCount).toFixed(2) : 0;
    const avgWidth = itemCount ? (totals.width / itemCount).toFixed(2) : 0;
    const avgHeight = itemCount ? (totals.height / itemCount).toFixed(2) : 0;

    return (
      <>
        <div style={{ width: "760px" }}>
          <table
            className="table table-bordered"
            style={{
              fontSize: "12px",
              lineHeight: "8px",
              marginTop: "-1px",
              borderColor: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={8}
                  style={{ textAlign: "center", fontSize: "14px" }}
                >
                  Current Stok List
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "left" }}>Date</th>
                <th style={{ textAlign: "center" }}>No</th>
                {/* <th style={{ textAlign: "center" }}>CH</th> */}
                <th style={{ textAlign: "center" }}>Type</th>
                <th style={{ textAlign: "center" }}>PCS</th>
                <th style={{ textAlign: "center" }}>Length</th>
                <th style={{ textAlign: "center" }}>Width</th>
                <th style={{ textAlign: "center" }}>Height</th>
                <th style={{ textAlign: "center" }}>Weight</th>
              </tr>
            </thead>
            <tbody>
              {stoneDetails.map((stone, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "left" }}>
                    {ISOToDateTime(stone.receivedDate)}
                  </td>
                  <td style={{ textAlign: "center" }}>{stone.stoneNo}</td>
                  {/* <td style={{ textAlign: "center" }}>{stone.ch}</td> */}
                  <td style={{ textAlign: "center" }}>{stone.stoneType}</td>
                  <td style={{ textAlign: "center" }}>{stone.pcs}</td>
                  <td style={{ textAlign: "center" }}>{stone.length}</td>
                  <td style={{ textAlign: "center" }}>{stone.width}</td>
                  <td style={{ textAlign: "center" }}>{stone.height}</td>
                  <td style={{ textAlign: "center" }}>{stone.weight}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr style={{ fontWeight: "bold" }}>
                <td colSpan="3" style={{ textAlign: "right" }}>
                  Total
                </td>
                <td style={{ textAlign: "center" }}>{totals.pcs}</td>
                <td style={{ textAlign: "center" }}>{avgLength}</td>
                <td style={{ textAlign: "center" }}>{avgWidth}</td>
                <td style={{ textAlign: "center" }}>{avgHeight}</td>
                <td style={{ textAlign: "center" }}>
                  {totals.weight.toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </>
    );
  };

  const printCurrentReport = (stoneList) => {
    if (selectedBagForCreateJangad.length > 0) {
      const StoneDetailsForPrint = selectedBagForCreateJangad;

      // Log the filtered result to debug
      console.log("StoneDetailsForPrint:", StoneDetailsForPrint);

      const printWindow = window.open("", "_blank");

      printWindow.document.write(
        '<html><head><title>Print Barcode</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"><style>{{width:"760px;"}}</style></head><body>'
      );

      ReactDOM.render(
        <PrintPreview stoneDetails={StoneDetailsForPrint} />,
        printWindow.document.body
      );

      printWindow.document.write("</body></html>");

      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 200);
    } else {
      toast.error("Select A ASI");
    }
  };

  //Print Section End

  return (
    <>
      {isLoading && (
        <>
          <div className="overlay"></div>
          <div className="overlay-content text-center">
            <Spinner animation="" variant="primary">
              <span class="loader"></span>
            </Spinner>
          </div>
        </>
      )}
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-1 col-sm-1 col-lg-1"></div>
              <div className="col-md-2 col-sm-2 col-lg-2 ">
                <div className="dashboardSummaryDiv">
                  <h4>
                    <ion-icon name="diamond-outline"></ion-icon> Total Stone
                  </h4>
                  <h2>{totalStock.totalPcs}</h2>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="dashboardSummaryDiv">
                  <h4>Avg. Length</h4>
                  <h2>{totalStock.totalLength}</h2>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="dashboardSummaryDiv">
                  <h4>Avg. Width</h4>
                  <h2>{totalStock.totalWidth}</h2>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="dashboardSummaryDiv">
                  <h4>Avg. Height</h4>
                  <h2>{totalStock.totalHeight}</h2>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="dashboardSummaryDiv">
                  <h4>Total Weight</h4>
                  <h2>{totalStock.totalWeight}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <strong className="mr-auto">Current Stock List</strong>
            <div className="d-flex">
              <br />
              <Button
                variant="primary"
                className="mr-2"
                onClick={JangadModelOpen}
                style={{ marginRight: "10px" }}
              >
                Create Jangad
              </Button>
              <Button
                variant="success"
                style={{ marginRight: "10px" }}
                onClick={exportToExcel}
              >
                <i class="fa fa-file-excel"></i> Export to Excel
              </Button>
              <Button
                variant="primary"
                onClick={() => printCurrentReport(stoneList)}
              >
                <i class="fa fa-print"></i> Print
              </Button>
            </div>
          </div>

          <div className="card-body">
            <AgGridComponent
              columnDefs={columnDefs}
              rowData={stoneList}
              onSelectionChanged={handleCheckboxSelection}
              onFilterChanged={onFilterChanged}
              pinnedBottomRowData={pinnedBottomRowData}
              paginationPageSize={100}
              height={"70vh"}
            />
          </div>
        </div>
      </div>

      <div className="card mt-10">
        <div className="card-header">
          <span>Worker Stock List</span>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={issueToWorkerList}
            columnDefs={workerColumnDefs}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
            paginationPageSize={100}
            height={"70vh"}
          />
        </div>
      </div>

      <Modal
        show={isModalOpen}
        onHide={closeModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {isLoading && <Loader />}
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            Create Jangad
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Date :
              </Form.Label>
              <Col sm="6">
                <Form.Group controlId="stoneTypeSelect">
                  <Form.Control
                    type="date"
                    name="selectedDate"
                    placeholder="Select Date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter The Value PartyName
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Party :
              </Form.Label>
              <Col sm="6">
                <div className="mb-3 d-flex justify-content-between">
                  <Select
                    value={selectedParty}
                    onChange={handlepartymasterChange}
                    options={partyMasterName.map((option) => ({
                      value: option.partyMasterID,
                      label: option.partyName,
                    }))}
                    placeholder="--Select Party--"
                  />
                  <div className="selectEndIcon input-group-text">
                    <i
                      className="fa fa-plus plusIcon"
                      onClick={() => setShowPartyMasterModel(true)}
                    ></i>
                  </div>
                </div>
              </Col>
            </Form.Group>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={InsertJangad}>Save</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPartyMasterModel}
        onHide={() => setShowPartyMasterModel(false)}
        size="xl"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add PartyMaster Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <Form.Group>
              <PartyMasterDD partyMasterID={selectDDJangadID}></PartyMasterDD>
            </Form.Group>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
