import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import LOGO from "../../assets/Asian Lab Logo.png";
import SearchStonePopup from "../../Pages/StonePopup/SearchStonePopup";
import api from "../../Utils/api";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { ISOToDate } from "../../Components/Common/Common";
import { hr } from "date-fns/locale";

export default function Header({ userName }) {
  const [rolePermissionData, setRolePermissionData] = useState([]);

  useEffect(() => {
    getRolePermission();
    getNotification();

    const interval = setInterval(() => {
      getNotification(); // Refresh notification every 3 seconds
    }, 30000);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  // Fetch all departments
  const getRolePermission = () => {
    api
      .get("RoleTemplateDetail/ViewPermissionWiseFormShow")
      .then((result) => {
        setRolePermissionData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkViewPermission = (permissionName) => {
    return rolePermissionData.some(
      (item) => item.formName === permissionName && item.viewPermission
    );
  };

  const [notification, setNotification] = useState([]);

  const getNotification = () => {
    api
      .get("IssueToDepartment/IssueNotification")
      .then((result) => {
        setNotification(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [navbarStyle, setNavbarStyle] = useState({});
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1500 && window.innerWidth >= 989) {
        setNavbarStyle({ flexDirection: 'column' });
      } else {
        setNavbarStyle({});
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <Navbar expand="lg" className="custom-bg-color">
        <Container fluid>
          <Navbar.Brand href="#home">
            <NavLink
              className="navbar-brand logo_back"
              to="/Currentstockofuser"
            >
              <img
                src={LOGO}
                height="25"
                alt="TAPTI TECHNOLOGY"
                loading="lazy"
              />
            </NavLink>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="navbar-collapse-custom" id="basic-navbar-nav" style={navbarStyle}>
            <Nav className="me-auto">
              <NavDropdown title="Master" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  {checkViewPermission("Department") && (
                    <NavLink to="/Department">Department</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Generate LotNo") && (
                    <NavLink to="/GenerateBatchNo">Generate LotNo</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Generate MixNo") && (
                    <NavLink to="/GenerateMixNo">Generate MixNo</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Generate Bag") && (
                    <NavLink to="/GenerateBag">Generate Bag</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Machine") && (
                    <NavLink to="/Machine">Machine</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Role Template") && (
                    <NavLink to="/RoleTemplate">Role Template</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Stone") && (
                    <NavLink to="/Stone">Stone</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Split Stone") && (
                    <NavLink to="/SplitStone">Split Stone</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Stone Type") && (
                    <NavLink to="/StoneType">Stone Type</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("User") && (
                    <NavLink to="/UserMaster">User</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Worker") && (
                    <NavLink to="/Worker">Worker</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("PartyMaster") && (
                    <NavLink to="/PartyMaster">PartyMaster</NavLink>
                  )}
                </NavDropdown.Item>
                {/* <NavDropdown.Item>
                  {checkViewPermission("Jangad") && (
                    <NavLink to="/Jangad">Jangad</NavLink>
                  )}
                </NavDropdown.Item> */}
                <NavDropdown.Item>
                  {checkViewPermission("SingleStoneDataEntry") && (
                    <NavLink to="/SingleStoneDataEntry">
                      SingleStoneDataEntry
                    </NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("ColorMaster") && (
                    <NavLink to="/ColorMaster">ColorMaster</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("ClarityMaster") && (
                    <NavLink to="/ClarityMaster">ClarityMaster</NavLink>
                  )}
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Issue" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  {checkViewPermission("Issue To Department") && (
                    <NavLink to="/IssueToDepartment">
                      Issue To Department
                    </NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Issue To Machine") && (
                    <NavLink to="/IssueToMachine">Issue To Machine</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Issue To Worker") && (
                    <NavLink to="/IssueToWorker">Issue To Worker</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Transfer To Final") && (
                    <NavLink to="/TransferToFinal">Transfer To Final</NavLink>
                  )}
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Receive" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  {checkViewPermission("Receive From Machine") && (
                    <NavLink to="/ReceiveFromMachine">
                      Receive From Machine
                    </NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Receive From Worker") && (
                    <NavLink to="/ReceiveToWorker">Receive From Worker</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Receive Stone") && (
                    <NavLink to="/Receivewindow">
                      Receive From Department
                    </NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Receive From Final") && (
                    <NavLink to="/ReceiveFromFinal">Receive From Final</NavLink>
                  )}
                </NavDropdown.Item>
              </NavDropdown>
              {checkViewPermission("Machine Dashboard") && (
                <NavLink
                  className="nav-item dropdown nav-link"
                  to="/MachineDashboard"
                >
                  <i class="fas fa-cash-register"></i> Machine Dashboard
                </NavLink>
              )}
              {checkViewPermission("Stock") && (
                <NavLink
                  className="nav-item dropdown nav-link"
                  to="/Currentstockofuser"
                >
                  <i class="fas fa-chart-line"></i> Stock
                </NavLink>
              )}
              {checkViewPermission("Admin Dashboard") && (
                <NavLink
                  className="nav-item dropdown nav-link"
                  to="/AdminDashboard"
                >
                  <i class="fas fa-user"></i> Admin Dashboard
                </NavLink>
              )}

              {/* <NavDropdown title="Final Stone" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  {checkViewPermission("Transfer To Final") && (
                    <NavLink to="/TransferToFinal">Transfer To Final</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Receive From Final") && (
                    <NavLink to="/ReceiveFromFinal">Receive From Final</NavLink>
                  )}
                </NavDropdown.Item>
              </NavDropdown> */}

              <NavDropdown title="Reports" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  {checkViewPermission("Report") && (
                    <NavLink to="/MachineReport">Machine Report</NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Issue And Receive Report") && (
                    <NavLink to="/IssueAndReceiveReport">
                      Issue & Receive Report
                    </NavLink>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {checkViewPermission("Mix Stone Report") && (
                    <NavLink to="/MixStoneReport">Mix Stone Report</NavLink>
                  )}
                </NavDropdown.Item>
              </NavDropdown>
              {checkViewPermission("Jangad") && (
                <NavLink className="nav-item dropdown nav-link" to="/Jangad">
                  <i class="fa fa-diamond"></i> Jangad
                </NavLink>
              )}
              {checkViewPermission("Purchase") && (
                <NavLink className="nav-item dropdown nav-link" to="/Purchase">
                  Purchase
                </NavLink>
              )}
            </Nav>
            <div className="d-flex-550" style={{alignItems:"center"}}>
              <NavLink className="navbar-brand">
                <SearchStonePopup />
              </NavLink>

              {/* Notification DropDown Start*/}
              <Dropdown style={{ marginTop: "4px" }}>
                <Dropdown.Toggle
                  className="me-3 hidden-arrow navFontSize"
                  id="navbarDropdownMenuLink"

                >
                  <i className="fas fa-bell"></i>
                  <span className="badge rounded-pill badge-notification bg-danger">
                    {notification.length}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  aria-labelledby="navbarDropdownMenuLink"
                  style={{ maxHeight: "500px", overflowX: "auto" }}
                >
                  {notification &&
                    notification.length > 0 &&
                    notification.map((item, index) => (
                      <Dropdown.Item key={index}>
                        <NavLink to="/Receivewindow">
                          <div>
                            {" "}
                            {` ${item.userName} - ${item.batchNo} (P - ${item.totalPcs}) - W : ${item.totalWeight}`}
                          </div>
                          <div className="text-center">{`${ISOToDate(
                            item.createdDate
                          )}`}</div>
                        </NavLink>
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              {/* Notification DropDown End*/}

              {userName && (
                <NavLink className="navbar-brand navFontSize" to="UserProfile">
                  {`(${userName})`}
                </NavLink>
              )}
              <NavLink className="navbar-brand navFontSize" to="/Logout">
                <i class="fas fa-sign-out-alt"></i> Logout
              </NavLink>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
