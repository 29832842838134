import React, { useRef, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import api from "../../Utils/api";
import Loader from "../../Components/Common/Loader";

const initialFormData = {
  colorMaster_id: 0,
  colorMaster_Name: "",
};

export default function ColorMasterDD({ colorMasterID }) {
  const [formData, setFormData] = useState(initialFormData);
  const [validated, setValidated] = useState(false);
  const createPartyFormRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const insertOrUpdateColorMaster = (event) => {
    event.preventDefault();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    const data = {
      colorMastedID: formData.colorMaster_id || 0,
      colorMasterName: formData.colorMaster_Name,
    };
    const endpoint =
      formData.colorMaster_id === 0
        ? "ColorMaster/InsertColorMaster"
        : "ColorMaster/EditColorMaster";

    const method = formData.colorMaster_id === 0 ? "post" : "patch";

    api[method](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (colorMasterID) {
            colorMasterID(result.data.data);
          }
          resetForm();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred");
      });
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setValidated(false);
  };

  return (
    <>
      {showLoader && <Loader />}
      <ToastContainer />
      <Form
        noValidate
        validated={validated}
        onSubmit={insertOrUpdateColorMaster}
        ref={createPartyFormRef}
      >
        <Row>
          <Col md={6}>
            <div className="col-md-12 d-flex align-items-center mt-4">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Group controlId="ColorMaster">
                  <Form.Label style={{ marginRight: "20px" }}>
                    Color Name :
                  </Form.Label>
                </Form.Group>
              </div>
              <div className="col-md-8 col-sm-8 col-lg-8">
                <Form.Group controlId="stoneTypeSelect">
                  <Form.Control
                    type="text"
                    name="colorMaster_Name"
                    placeholder="Enter ColorName"
                    value={formData.colorMaster_Name}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter The Value PartyName
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.colorMaster_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
