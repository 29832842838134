import React, { useState, useEffect } from "react";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import { Card } from "react-bootstrap";
import api from "../../Utils/api";
import _ from "lodash";

const sumAggFunc = (params) => {
  const values = params.values;
  let sum = 0;

  values.forEach((value) => {
    sum += value;
  });

  return sum;
};

const pinnedBottomRowData = [
  {
    stoneType: "TOTAL",
    pcs: 0,
    totalweight: 0,
  },
];

export default function AdminDashboardOfTotalFinalStock() {
  const [totalFinalStock, setTotalFinalStock] = useState([]);

  useEffect(() => {
    getTotalStock();
  }, []);

  // Fetch all departments
  const getTotalStock = () => {
    api
      .get("FinalStoneSummary/ALLStockDataByUser")
      .then((result) => {
        setTotalFinalStock(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columnDefs = [
    {
      headerName: "Stone Type",
      field: "stoneType",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc,
    },
    {
      headerName: "PCS",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc,
    },
    {
      headerName: "Total weight",
      field: "totalweight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc,
    },
  ];

  useEffect(() => {
    updatePinnedBottomRowData();
  }, [totalFinalStock]);

  const calculatePcsAndWeight = (data) => {
    ;
    const totalPcs = parseFloat(
      _.sumBy(data, (item) => (item.pcs > 0 ? item.pcs : 0))
    );
    const totalWeight = parseFloat(_.sumBy(data, "totalweight").toFixed(2));
    return { totalPcs, totalWeight };
  };

  const calculateSums = (event) => {
    let filteredData;
    if (event === undefined) {
      filteredData = totalFinalStock; // Assuming totalFinalStock is defined elsewhere
    } else {
      filteredData = event.api.getModel().rowsToDisplay.map((row) => row.data);
    }
  
    if (filteredData !== undefined && filteredData.length > 0) {
      const { totalPcs, totalWeight } = calculatePcsAndWeight(filteredData);
  
      return {
        footerTotalPcs: totalPcs,
        footerTotalWeight: totalWeight,
      };
    }
   
    return {
      footerTotalPcs: 0,
      footerTotalWeight: 0,
    };
  };
  const updatePinnedBottomRowData = (event) => {
    const sums = calculateSums(event);
    pinnedBottomRowData[0].stoneType = "TOTAL";
    pinnedBottomRowData[0].pcs = sums.footerTotalPcs;
    pinnedBottomRowData[0].totalweight = sums.footerTotalWeight;
  };
  
  // Example usage
  const onFilterChanged = (event) => {
    updatePinnedBottomRowData(event);
    if (event) {
      event.api.refreshCells({ force: true, suppressFlash: true });
    }
  };
  

 
  return (
    <>
      <Card>
        <Card.Header>
          <span> Total Final Stock</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={totalFinalStock}
            columnDefs={columnDefs}
            onFilterChanged={onFilterChanged}
            pinnedBottomRowData={pinnedBottomRowData}
            paginationPageSize={100}
            height="70vh"
          />
        </Card.Body>
      </Card>
    </>
  );
}
