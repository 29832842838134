import React from "react";
import AdminDashboardWithOutBag from "./AdminDashboardWithOutBag";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AdminDashboardOfBag from "./AdminDashboardOfBag";
import { ToastContainer } from "react-toastify";
import AdminDashboardOfTotalFinalStock from "./AdminDashboardOfTotalFinalStock";
import "react-toastify/dist/ReactToastify.css";
import { Card} from "react-bootstrap";

export default function AdminDashboard() {
  return (
    <>
      <ToastContainer />

      <Card>
      <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          variant="underline"
        >
          <Tab eventKey="home" title="Total Stone" className="mr-4 ">
          <AdminDashboardWithOutBag />
          </Tab>
          <Tab eventKey="profile2" title="Total Final Stock ">
          <AdminDashboardOfTotalFinalStock />
          </Tab>
          <Tab eventKey="profile" title="Total ASI ">
          <AdminDashboardOfBag />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
}
