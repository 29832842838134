import React, { useEffect, useState, useRef } from "react";
import { useStonesContext } from "../../contextFile/StoneContext/StonesContext";
import api from "../../Utils/api";
import { toast } from "react-toastify"; // Replace with your toast library
import { addDecimalPlaces } from "../../Utils/commonfunctions";
import BarcodeScanner from "../../Components/Common/BarcodeScanner";
import Accordion from "react-bootstrap/Accordion";

function StonesComponent({
  IssueTo,
  findUrlForStone,
  findUrlForBatch,
  showPartNo,
  showWithTextBox,
  showWithoutTextBox,
  showDLCAndLOSS,
  showTotal,
}) {
  const { listStones, setListStones } = useStonesContext();
  const { findNo, setFindNo } = useStonesContext();
  const { batchAvgList, setBatchAvgList } = useStonesContext();
  const [totalPcs, setTotalPcs] = useState();
  const [totalLossPer, setTotalLossPer] = useState(0);
  // Initialize total loss state to 0
  // const [findTotalPCS, setFindTotalPCS] = useState(0);

  // const [oldValueData, setOldValueData] = useState(0);

  // const [boxDisabled, setBoxDisabled] = useState(true);

  useEffect(() => {
    return () => {
      setFindNo("");
      setListStones([]);
      setBatchAvgList([]);
    };
  }, []);

  const inputRef = useRef(null);
  // const [stoneOld, setStoneOld] = useState(0);

  const findStone = (e) => {
    if (e.key === "Enter") {
      getStoneData(findNo);
    }
  };

  const [ab, setAB] = useState(false);

  const getStoneData = (No) => {
    const isLot = No.toUpperCase().startsWith("LOT");
    api
      .get(findUrlForStone + `=${No}`)
      .then((result) => {
        setFindNo("");
        const data = result.data.data;
        // if (isLot) {
        //   setBoxDisabled(false);
        // }
        let isExist = false;
        if (data !== undefined && data !== null) {
          listStones.forEach((item) => {
            if (item.stoneNo === data[0].stoneNo) {
              toast.error("Stone is already in the list");
              isExist = true;
              return;
            }
          });
          if (!isExist) {
            if (data && data.length > 0) {
              data.forEach((item) => {
                item.oldstoneID = item.stoneID;
                item.batchNo = item.batchNo==null?'LOT-0':item.batchNo;
                // item.batchNo = item.batchNo;
                item.oldstoneNo = item.stoneNo;
                item.oldpcs = item.pcs;
                item.oldlength = item.length;
                item.oldwidth = item.width;
                item.oldheight = item.height;
                item.oldweight = item.weight;
                item.oldDlc = item.dlc;
                item.stoneType = item.stoneType;
                if (showWithTextBox) {
                  item.dlc = 0;
                } else {
                  item.dlc = item.dlc;
                }
                if (showWithTextBox) {
                  item.loss = 0;
                } else {
                  item.loss = item.loss;
                }
                item.isBroken = item.isBroken === null ? false : item.isBroken;
                item.isFinal = item.isFinal === null ? false : item.isFinal;
                item.isOut = item.isOut === null ? false : item.isOut;

                item.getFinal = item.isFinal === null ? false : item.isFinal;
                item.getOut = item.isOut === null ? false : item.isOut;
                setListStones((oldArray) => [...oldArray, item]);
              });
            } else {
              toast.error("Stone is Not found");
            }
          }
        } else {
          toast.error("Stone is Not found");
        }
        setFindNo("");
        setAB(true);
        inputRef.current.focus(); // Focus on the input element
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred while fetching data.");
      });
  };

  const removeData = (id) => {
    const updatedStones = listStones.filter((item) => item.stoneID !== id);
    const updatedBatchAvgList = batchAvgList.filter(
      (item) => item.batchNo !== id
    );
    const batchNumberToRemove = batchAvgList.find(
      (item) => item.batchNo === id
    )?.batchNo;
    const updatedStonesAfterBatchRemoval = updatedStones.filter(
      (item) => item.batchNo !== batchNumberToRemove
    );

    setListStones(updatedStonesAfterBatchRemoval);
    setBatchAvgList(updatedBatchAvgList);
  };

  const updateStoneProperty = (stoneID, property, value) => {
    if (value === "") {
      value = 0;
    }
    setListStones((prevStone) =>
      prevStone.map((item) => {
        if (value !== "") {
          if (item.stoneID === stoneID) {
            const parsedValue =
              item.isFinal || item.isBroken || item.isOut
                ? value
                : isNaN(parseFloat(value))
                ? value
                : parseFloat(value);

            return {
              ...item,
              [property]:
                parsedValue === true ? parsedValue : parseFloat(parsedValue),
            };
          }
          return item;
        }
      })
    );

    CountLoss(stoneID);
  };

  const CountLoss = (stoneID) => {
    setListStones((prevListStones) =>
      prevListStones.map((item) => {
        if (item.stoneID === stoneID) {
          let dlc = parseFloat(item.dlc);
          let NewWeight = parseFloat(item.weight);
          let oldWeight = parseFloat(item.oldweight);
          let finalLoss = dlc + NewWeight - oldWeight;
          return {
            ...item,
            loss: parseFloat((Math.round(finalLoss * 100) / 100).toFixed(2)),
          };
        } else {
          return item;
        }
      })
    );
    countLossPer();
  };

  const [totalWeight, setTotalWeight] = useState(0);

  const batchAvg = () => {
    if (listStones.length > 0) {
      const groupedData = listStones.reduce((acc, item) => {
        let batchNo = item.batchNo;
        if (batchNo === null) {
          batchNo = item.stoneNo;
        }
        // const stoneType = item.stoneType; // Include stoneType
        acc[batchNo] = acc[batchNo] || {
          count: 0,
          sum: {},
          pcsSum: 0,
          weightSum: 0,
          oldweightSum: 0,
          olddlcSum: 0,
          oldlengthSum: 0,
          oldheightSum: 0,
          oldwidthSum: 0,
          lengthSum: 0,
          widthSum: 0,
          heightSum: 0,
          countFinal: 0,
          countBroken: 0,
          countOut: 0,
          dlcSum: 0,
          lossSum: 0,
          oldLossSum: 0,
          pcsForDlc: 0,
        };

        Object.keys(item).forEach((key) => {
          if (typeof item[key] === "number") {
            acc[batchNo].sum[key] = (acc[batchNo].sum[key] || 0) + item[key];
          }
        });

        //acc[batchNo].stoneTypes[stoneType] = (acc[batchNo].stoneTypes[stoneType] || 0) + 1; // Count stone types

        acc[batchNo].pcsForDlc +=
          !item.isFinal && !item.isOut ? item.pcs || 0 : 0;
        acc[batchNo].pcsSum += item.pcs || 0;
        acc[batchNo].weightSum +=
          !item.isFinal && !item.isOut ? item.weight || 0 : 0;
        acc[batchNo].oldweightSum +=
          !item.isFinal && !item.isOut ? item.weight || 0 : 0;
        acc[batchNo].olddlcSum +=
          !item.isFinal && !item.isOut ? item.dlc || 0 : 0;
        acc[batchNo].oldLossSumSum +=
          !item.isFinal && !item.isOut ? item.loss || 0 : 0;
        acc[batchNo].oldlengthSum += item.oldlength || 0;
        acc[batchNo].oldheightSum += item.oldheight || 0;
        acc[batchNo].oldwidthSum += item.oldwidth || 0;
        acc[batchNo].lengthSum += item.length || 0;
        acc[batchNo].widthSum += item.width || 0;
        acc[batchNo].heightSum += item.height || 0;
        acc[batchNo].dlcSum += !item.isFinal && !item.isOut ? item.dlc || 0 : 0;
        acc[batchNo].lossSum +=
          !item.isFinal && !item.isOut ? item.loss || 0 : 0;
        acc[batchNo].count += 1;
        acc[batchNo].countFinal += item.isFinal ? 1 : 0;
        acc[batchNo].countBroken += item.isBroken ? 1 : 0;
        acc[batchNo].countOut += item.isOut ? 1 : 0;
        acc[batchNo].stoneType = item.stoneType;
        return acc;
      }, {});

      const averagesByBatchNo = {};
      let totalPcsSum = 0;
      let totalWeightSum = 0;
      let totalPcsFordlc = 0;

      Object.keys(groupedData).forEach((batchNo) => {
        averagesByBatchNo[batchNo] = {
          count: groupedData[batchNo].count,
          pcsSum: groupedData[batchNo].pcsSum,
          weight: groupedData[batchNo].weightSum,
          oldweight: groupedData[batchNo].oldweightSum,
          olddlc: groupedData[batchNo].olddlcSum,
          oldlength: groupedData[batchNo].oldlengthSum,
          oldheight: groupedData[batchNo].oldheightSum,
          oldwidth: groupedData[batchNo].oldwidthSum,
          length: groupedData[batchNo].lengthSum / groupedData[batchNo].count,
          width: groupedData[batchNo].widthSum / groupedData[batchNo].count,
          height: groupedData[batchNo].heightSum / groupedData[batchNo].count,
          dlc: groupedData[batchNo].dlcSum,
          loss: groupedData[batchNo].lossSum,
          oldLoss: groupedData[batchNo].oldLossSum,
          countFinal: groupedData[batchNo].countFinal,
          countBroken: groupedData[batchNo].countBroken,
          countOut: groupedData[batchNo].countOut,
          stoneType: groupedData[batchNo].stoneType, // Include stoneTypes
        };

        totalPcsSum += groupedData[batchNo].pcsSum;
        totalWeightSum += groupedData[batchNo].weightSum;
        totalPcsFordlc += groupedData[batchNo].pcsForDlc;
      });

      const batchAvgListArray = Object.entries(averagesByBatchNo).map(
        ([batchNo, values]) => ({
          batchNo,
          count: values.count,
          countFinal: values.countFinal,
          countBroken: values.countBroken,
          countOut: values.countOut,
          pcsSum: parseInt(values.pcsSum).toString(),
          weight: parseFloat(values.weight).toFixed(2),
          oldweight: parseFloat(values.oldweight).toFixed(2),
          olddlc: parseFloat(values.olddlc).toFixed(2),
          oldlength: parseFloat(values.oldlength).toFixed(2),
          oldheight: parseFloat(values.oldheight).toFixed(2),
          oldwidth: parseFloat(values.oldwidth).toFixed(2),
          length: parseFloat(values.length).toFixed(2),
          width: parseFloat(values.width).toFixed(2),
          height: parseFloat(values.height).toFixed(2),
          dlc: parseFloat(values.dlc).toFixed(2),
          loss: parseFloat(values.loss).toFixed(2),
          oldLoss: parseFloat(values.oldLoss).toFixed(2),
          stoneType: values.stoneType, // Include stoneTypes
          ...Object.fromEntries(
            Object.entries(values)
              .filter(
                ([key, value]) =>
                  typeof value === "string" &&
                  ![
                    "length",
                    "width",
                    "height",
                    "weight",
                    "oldlength",
                    "oldwidth",
                    "oldheight",
                    "olddlc",
                    "dlc",
                    "oldLoss",
                    "loss",
                    "stoneType",
                  ].includes(key)
              )
              .map(([key, value]) => [key, parseFloat(value).toFixed(2)])
          ),
        })
      );

      setBatchAvgList(batchAvgListArray);
      setTotalPcs(totalPcsSum);
      setTotalWeight(totalWeightSum);
    }
  };

  useEffect(() => {
    assignTotalLossCount(); // Call assignTotalLossCount here after updating state
    assignTotalWeightCount();
    countLossPer();
    if (ab) {
      batchAvg();
      setAB(false);
    }
  }, [listStones]);

  const assignTotalLossCount = () => {
    setBatchAvgList((prevList) =>
      prevList.map((itemAvg) => {
        const batchNo = itemAvg.batchNo;
        const totalLoss = listStones.reduce((acc, itemOfStones) => {
          if (itemOfStones.batchNo === batchNo) {
            return acc + itemOfStones.loss;
          }
          return acc;
        }, 0);

        const roundedTotalLoss = parseFloat(totalLoss.toFixed(2));

        return {
          ...itemAvg,
          totalLoss: roundedTotalLoss,
        };
      })
    );
  };

  const assignTotalWeightCount = () => {
    const totalWeightByBatch = {};

    // Calculate total weight for each batch
    listStones.forEach((itemOfStones) => {
      const batchNo = itemOfStones.batchNo;
      const weight = parseFloat(itemOfStones.weight);

      if (!totalWeightByBatch[batchNo]) {
        totalWeightByBatch[batchNo] = 0;
      }

      totalWeightByBatch[batchNo] += weight;
    });

    // Update the batchAvgList with total weights
    const updatedBatchAvgList = batchAvgList.map((item) => ({
      ...item,
      weight: item.weight,
    }));

    setTotalWeight(totalWeightByBatch); // You can use this state to display total weight for each batch
    //setBatchAvgList(updatedBatchAvgList);
  };

  const countLossPer = () => {
    setTotalLossPer(0);

    // Group data by batchNo
    const groupedData = listStones.reduce((acc, item) => {
      const batchNo = item.batchNo;
      acc[batchNo] = acc[batchNo] || {
        totalDlc: 0,
        totalLoss: 0,
        totalWeight: 0,
      };

      // Sum values for each parameter
      acc[batchNo].totalDlc += parseFloat(item.dlc);
      acc[batchNo].totalLoss += parseFloat(item.loss);
      acc[batchNo].totalWeight += parseFloat(item.weight);

      return acc;
    }, {});

    // Calculate totalLossPer for each batch
    Object.keys(groupedData).forEach((batchNo) => {
      const { totalDlc, totalLoss, totalWeight } = groupedData[batchNo];

      if (totalDlc > 0) {
        const lossPer = parseFloat(
          (Math.round((100 / totalWeight) * totalLoss * 100) / 100).toFixed(2)
        );

        // Update totalLossPer for each batch
        setTotalLossPer((prevTotalLossPer) => ({
          ...prevTotalLossPer,
          [batchNo]: lossPer,
        }));
      }
    });
  };

  const updateBatchAvgProperty = (batchNo, oldvalue, property, value) => {
    setBatchAvgList((prevList) =>
      prevList.map((item) => {
        if (item.batchNo === batchNo) {
          const newValue = parseFloat(value);
          setListStones((prevStoneList) =>
            prevStoneList.map((itemOfStone) => {
              if (itemOfStone.batchNo === batchNo) {
                if (
                  itemOfStone.isFinal === false &&
                  itemOfStone.isOut === false
                ) {
                  // if (property === "totalWeight") {
                  if (property === "weight") {
                    const finalDiv = (newValue - oldvalue) / oldvalue || 0;
                    const FinalWeight =
                      itemOfStone.oldweight + finalDiv * itemOfStone.oldweight;
                    const weight =
                      newValue >= 0
                        ? parseFloat(addDecimalPlaces(FinalWeight, 2)).toFixed(
                            2
                          )
                        : itemOfStone.oldweight;
                    setTimeout(() => {
                      CountLoss(itemOfStone.stoneID);
                    }, 900);
                    return { ...itemOfStone, weight: weight };
                    // } else if (property === "avgtotalHeight") {
                  } else if (property === "height") {
                    const finalDiv = (newValue - oldvalue) / oldvalue || 0;
                    const FinalWeight =
                      itemOfStone.oldheight + finalDiv * itemOfStone.oldheight;
                    const height =
                      newValue >= 0
                        ? parseFloat(addDecimalPlaces(FinalWeight, 2)).toFixed(
                            2
                          )
                        : itemOfStone.oldheight;
                    return { ...itemOfStone, height: height };
                    // } else if (property === "avgtotalWidth") {
                  } else if (property === "width") {
                    const finalDiv = (newValue - oldvalue) / oldvalue || 0;
                    const FinalWeight =
                      itemOfStone.oldwidth + finalDiv * itemOfStone.oldwidth;
                    const width =
                      newValue >= 0
                        ? parseFloat(addDecimalPlaces(FinalWeight, 2)).toFixed(
                            2
                          )
                        : itemOfStone.oldwidth;
                    return { ...itemOfStone, width: width };
                    // } else if (property === "avgtotalLength") {
                  } else if (property === "length") {
                    const finalDiv = (newValue - oldvalue) / oldvalue || 0;
                    const FinalWeight =
                      itemOfStone.oldlength + finalDiv * itemOfStone.oldlength;
                    const length =
                      newValue >= 0
                        ? parseFloat(addDecimalPlaces(FinalWeight, 2)).toFixed(
                            2
                          )
                        : itemOfStone.oldlength;
                    return { ...itemOfStone, length: length };
                    // } else if (property === "totalDLC") {
                  } else if (property === "dlc") {
                    const finalDLC =
                      newValue /
                      (item.count - (item.countFinal + item.countOut));
                    const dlc =
                      newValue >= 0
                        ? parseFloat(addDecimalPlaces(finalDLC, 4)).toFixed(4)
                        : itemOfStone.dlc;
                    setTimeout(() => {
                      if (finalDLC > 0) {
                        CountLoss(itemOfStone.stoneID);
                      }
                    }, 900);
                    return { ...itemOfStone, dlc: dlc };
                  }
                }
              }
              return itemOfStone;
            })
          );

          return { ...item, [property]: value };
        }
        return item;
      })
    );
  };

  const handleDataFromChild = (data) => {
    setFindNo(data);
    getStoneData(data);
  };
  const [isBarcodeScanner, setIsBarcodeScanner] = useState(false);
  const toggleBarcodeScanner = () => {
    setIsBarcodeScanner(!isBarcodeScanner);
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span>{IssueTo}</span>
        </div>
        <div className="card-body">
          {isBarcodeScanner && (
            <BarcodeScanner
              sendDataToParent={handleDataFromChild}
            ></BarcodeScanner>
          )}
          <div className="row align-middle d-flex justify-content-center align-items-center">
            <div className="col-md-2 col-sm-2 col-lg-2 d-flex justify-content-end align-items-center">
              <label htmlFor="Find_no">
                <div className="d-flex align-items-center">
                  {" "}
                  <span>StoneNo/LotNo: </span>
                  <button
                    type="button"
                    style={{ marginLeft: "20px" }}
                    className="btn btn-primary"
                    onClick={toggleBarcodeScanner}
                  >
                    {isBarcodeScanner ? (
                      <i className="fa fa-close"></i>
                    ) : (
                      <i className="fa fa-camera"></i>
                    )}
                  </button>
                </div>
              </label>
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4">
              <input
                type="text"
                className="form-control"
                id="Find_no"
                placeholder="Enter BatchNoOrStoneNo"
                value={findNo}
                onChange={(e) => setFindNo(e.target.value)}
                onKeyDown={findStone}
                ref={inputRef} // Assign the ref to the input element
              />
            </div>
            <div className="col-md-1 col-sm-1 col-lg-1 searchBtn">
              <button
                type="button"
                className="align-items-center btn btn-primary"
                onClick={(e) => getStoneData(findNo)}
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <span>Batch AvgList</span>
        </div>
        <div className="card-body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Batch No</th>
                <th>Type</th>
                <th>Total Pcs</th>
                <th>Avgtotal Length</th>
                <th>Avgtotal Width</th>
                <th>Avgtotal Height</th>
                <th>Total Weight</th>
                {showDLCAndLOSS && <th style={{ width: "90px" }}>DLC</th>}
                {showWithTextBox && <th style={{ width: "90px" }}>Loss(%)</th>}
                <th style={{ width: "90px" }}>Loss</th>
                <th>Delete</th>
              </tr>
            </thead>
            {showWithTextBox && (
              <tbody>
                {batchAvgList.map((item, index) => (
                  <tr key={item.batchNo}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>{" "}
                    <td className="noWidth">{item.batchNo}</td>
                    <td>{item.stoneType}</td>
                    <td>{item.pcsSum}</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Avg Length"
                        value={item.length}
                        // // disabled={boxDisabled}
                        onChange={(e) =>
                          updateBatchAvgProperty(
                            item.batchNo,
                            parseFloat(item.oldlength / item.pcsSum).toFixed(2),
                            "length",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Avg Width"
                        value={item.width}
                        // disabled={boxDisabled}
                        onChange={(e) =>
                          updateBatchAvgProperty(
                            item.batchNo,
                            parseFloat(item.oldwidth / item.pcsSum).toFixed(2),
                            "width",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Avg Height"
                        value={item.height}
                        // disabled={boxDisabled}
                        onChange={(e) =>
                          updateBatchAvgProperty(
                            item.batchNo,
                            parseFloat(item.oldheight / item.pcsSum).toFixed(2),
                            "height",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td style={{ display: "flex" }}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Total Weight"
                        value={item.weight}
                        // disabled={boxDisabled}
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          updateBatchAvgProperty(
                            item.batchNo,
                            parseFloat(item.oldweight).toFixed(2),
                            "weight",
                            e.target.value // Parse the input value to a float
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Total DLC"
                        value={item.dlc}
                        style={{ width: "90px" }}
                        // disabled={boxDisabled}
                        onChange={(e) =>
                          updateBatchAvgProperty(
                            item.batchNo,
                            parseFloat(item.dlc).toFixed(2),
                            "dlc",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Total Loss"
                        disabled
                        style={{ width: "90px" }}
                        value={totalLossPer[item.batchNo]}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Total Loss"
                        disabled
                        style={{ width: "90px" }}
                        value={item.totalLoss}
                      />
                    </td>
                    <td className="txtCenter">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => removeData(item.batchNo)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
            {showWithTextBox && batchAvgList.length > 0 && (
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <strong> Total</strong>
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) => total + parseFloat(batch.pcs),
                        0
                      ),
                      2
                    )}
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) =>
                          total + (parseFloat(batch.length) / batch.pcs || 0),
                        0
                      ) / listStones.length,
                      2
                    )}
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) =>
                          total + (parseFloat(batch.width) / batch.pcs || 0),
                        0
                      ) / listStones.length,
                      2
                    )}
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) =>
                          total + (parseFloat(batch.height) / batch.pcs || 0),
                        0
                      ) / listStones.length,
                      2
                    )}
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones
                        .filter((batch) => !batch.isFinal && !batch.isOut)
                        .reduce(
                          (total, batch) =>
                            total + parseFloat(batch.weight || 0),
                          0
                        ),
                      2
                    )}
                  </td>
                  {showDLCAndLOSS && (
                    <>
                      <td>
                        {addDecimalPlaces(
                          listStones
                            .filter((batch) => !batch.isFinal && !batch.isOut)
                            .reduce(
                              (total, batch) =>
                                total + parseFloat(batch.dlc || 0),
                              0
                            ),
                          2
                        )}
                      </td>
                    </>
                  )}
                  <td></td>
                  <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) => total + parseFloat(batch.loss),
                        0
                      ),
                      2
                    )}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            )}
            {showWithoutTextBox && (
              <tbody>
                {batchAvgList.map((item, index) => (
                  <tr key={item.batchNo}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>{" "}
                    <td>{item.batchNo}</td>
                    <td>{item.stoneType}</td>
                    <td>{item.pcsSum}</td>
                    <td>{item.length}</td>
                    <td>{item.width}</td>
                    <td>{item.height}</td>
                    <td>{item.weight}</td>
                    {showDLCAndLOSS && <td>{item.dlc}</td>}
                    <td>{item.loss}</td>
                    <td className="txtCenter">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => removeData(item.batchNo)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
            {showWithoutTextBox && batchAvgList.length > 0 && (
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <strong> Total</strong>
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) => total + parseFloat(batch.pcs),
                        0
                      ),
                      2
                    )}
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) =>
                          total + (parseFloat(batch.length) / batch.pcs || 0),
                        0
                      ) / listStones.length,
                      2
                    )}
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) =>
                          total + (parseFloat(batch.width) / batch.pcs || 0),
                        0
                      ) / listStones.length,
                      2
                    )}
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) =>
                          total + (parseFloat(batch.height) / batch.pcs || 0),
                        0
                      ) / listStones.length,
                      2
                    )}
                  </td>
                  <td>
                    {addDecimalPlaces(
                      listStones
                        .filter((batch) => !batch.isFinal && !batch.isOut)
                        .reduce(
                          (total, batch) =>
                            total + parseFloat(batch.weight || 0),
                          0
                        ),
                      2
                    )}
                  </td>
                  {showDLCAndLOSS && (
                    <>
                      <td>
                        {addDecimalPlaces(
                          listStones
                            .filter((batch) => !batch.isFinal && !batch.isOut)
                            .reduce(
                              (total, batch) =>
                                total + parseFloat(batch.dlc || 0),
                              0
                            ),
                          2
                        )}
                      </td>
                    </>
                  )}
                  <td>
                    {addDecimalPlaces(
                      listStones
                        .filter((batch) => !batch.isFinal && !batch.isOut)
                        .reduce(
                          (total, batch) => total + parseFloat(batch.loss || 0),
                          0
                        ),
                      2
                    )}
                  </td>
                  {/* <td></td> */}
                  {/* <td>
                    {addDecimalPlaces(
                      listStones.reduce(
                        (total, batch) => total + parseFloat(batch.loss),
                        0
                      ),
                      2
                    )}
                  </td> */}
                  <td></td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>

      <div className="card">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <strong> Stone List </strong>
            </Accordion.Header>
            <Accordion.Body>
              <div className="card-body table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>No</th>
                      <th>Broken</th>
                      <th>Final</th>
                      <th>Out</th>
                      <th style={{ width: "72px" }}>Pcs</th>
                      {showPartNo && <th>PartNo</th>}
                      <th>Length</th>
                      <th>Width</th>
                      <th>Height</th>
                      <th>Weight</th>
                      {showDLCAndLOSS && (
                        <th style={{ width: "120px" }}>DLC</th>
                      )}
                      {showDLCAndLOSS && (
                        <th style={{ width: "90px" }}>Loss</th>
                      )}
                      <th>Delete</th>
                    </tr>
                  </thead>
                  {showWithTextBox && (
                    <tbody>
                      {listStones.map((item, index) => (
                        <tr key={item.stoneID}>
                          <td style={{ textAlign: "center" }}>{index + 1}</td>{" "}
                          {/* Serial Number */}
                          <td className="noWidth">
                            {item.stoneNo} / {item.batchNo}
                          </td>
                          {showWithTextBox && (
                            <td>
                              <input
                                type="checkbox"
                                checked={item.isBroken === true}
                                onChange={(e) =>
                                  updateStoneProperty(
                                    item.stoneID,
                                    "isBroken",
                                    e.target.checked ? true : false
                                  )
                                }
                                disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                              />
                            </td>
                          )}
                          {showWithTextBox && (
                            <td>
                              <input
                                type="checkbox"
                                checked={item.isFinal === true}
                                onChange={(e) =>
                                  updateStoneProperty(
                                    item.stoneID,
                                    "isFinal",
                                    e.target.checked ? true : false
                                  )
                                }
                                disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                              />
                            </td>
                          )}
                          {showWithTextBox && (
                            <td>
                              <input
                                type="checkbox"
                                checked={item.isOut === true}
                                onChange={(e) =>
                                  updateStoneProperty(
                                    item.stoneID,
                                    "isOut",
                                    e.target.checked ? true : false
                                  )
                                }
                                disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                              />
                            </td>
                          )}
                          {/* <td>{item.pcs}</td> */}
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Pcs"
                              value={item.pcs}
                              style={{ width: "70px" }}
                              onChange={(e) =>
                                updateStoneProperty(
                                  item.stoneID,
                                  "pcs",
                                  e.target.value
                                )
                              }
                              disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                            />
                          </td>
                          {showPartNo && (
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Length"
                                value={item.partNo}
                                onChange={(e) =>
                                  updateStoneProperty(
                                    item.stoneID,
                                    "partNo",
                                    e.target.value
                                  )
                                }
                                disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                              />
                            </td>
                          )}
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Length"
                              // value={item.length}
                              value={item.length === 0 ? "" : item.length || ""}
                              onChange={(e) =>
                                updateStoneProperty(
                                  item.stoneID,
                                  "length",
                                  e.target.value
                                )
                              }
                              disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Width"
                              // value={item.width}
                              value={item.width === 0 ? "" : item.width || ""}
                              onChange={(e) =>
                                updateStoneProperty(
                                  item.stoneID,
                                  "width",
                                  e.target.value
                                )
                              }
                              disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Height"
                              // value={item.height}
                              value={item.height === 0 ? "" : item.height || ""}
                              onChange={(e) =>
                                updateStoneProperty(
                                  item.stoneID,
                                  "height",
                                  e.target.value
                                )
                              }
                              disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Weight"
                              value={item.weight === 0 ? "" : item.weight || ""}
                              onChange={(e) =>
                                updateStoneProperty(
                                  item.stoneID,
                                  "weight",
                                  e.target.value
                                )
                              }
                              disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter dlc"
                              // value={item.dlc}
                              value={item.dlc}
                              style={{ width: "120px" }}
                              onChange={(e) =>
                                updateStoneProperty(
                                  item.stoneID,
                                  "dlc",
                                  parseFloat(e.target.value).toFixed(4)
                                )
                              }
                              disabled={item.getFinal || item.getOut} // Disable if isFinal or isOut is true
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter dlc"
                              // value={item.loss}
                              value={item.loss === 0 ? "" : item.loss || ""}
                              style={{ width: "90px" }}
                              onChange={(e) =>
                                updateStoneProperty(
                                  item.stoneID,
                                  "loss",
                                  e.target.value
                                )
                              }
                              disabled
                            />
                          </td>
                          <td className="txtCenter">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => removeData(item.stoneID)}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}

                  {showWithoutTextBox && (
                    <tbody>
                      {listStones.map((item, index) => (
                        <tr key={item.stoneID}>
                          <td style={{ textAlign: "center" }}>{index + 1}</td>{" "}
                          {/* Serial Number */}
                          <td>
                            {item.stoneNo} / {item.batchNo}
                          </td>
                          <td>{item.isBroken ? "True" : ""}</td>
                          <td>{item.isFinal ? "True" : ""}</td>
                          <td>{item.isOut ? "True" : ""}</td>
                          <td>{item.pcs}</td>
                          {showPartNo && <td>{item.partNo}</td>}
                          <td>{item.length}</td>
                          <td>{item.width}</td>
                          <td>{item.height}</td>
                          <td>{item.weight}</td>
                          {showDLCAndLOSS && <td>{item.dlc}</td>}
                          {showDLCAndLOSS && <td>{item.loss}</td>}
                          <td className="txtCenter">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => removeData(item.stoneID)}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}

                      {showTotal && (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "right" }}>
                            <strong> Total</strong>
                          </td>
                          <td>
                            {addDecimalPlaces(
                              batchAvgList.reduce(
                                (total, batch) => total + batch.totalPcs,
                                0
                              ),
                              2
                            )}
                          </td>
                          <td>
                            {addDecimalPlaces(
                              batchAvgList.reduce(
                                (total, batch) =>
                                  total + parseFloat(batch.avgtotalLength),
                                0
                              ),
                              2
                            )}
                          </td>
                          <td>
                            {addDecimalPlaces(
                              batchAvgList.reduce(
                                (total, batch) =>
                                  total + parseFloat(batch.avgtotalWidth),
                                0
                              ),
                              2
                            )}
                          </td>
                          <td>
                            {addDecimalPlaces(
                              batchAvgList.reduce(
                                (total, batch) =>
                                  total + parseFloat(batch.avgtotalHeight),
                                0
                              ),
                              2
                            )}
                          </td>
                          <td>
                            {addDecimalPlaces(
                              batchAvgList.reduce(
                                (total, batch) =>
                                  total + parseFloat(batch.totalWeight),
                                0
                              ),
                              2
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default StonesComponent;
