import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Utils/api";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import FindStone from "../../Components/Common/FindStone";
import { useStonesContext } from "../../contextFile/StoneContext/StonesContext";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import {
  PrintStoneSticker,
  PrintBatchSticker,
  PrintStoneStickerFromBatchNo,
} from "../../Utils/commonfunctions";
import ImageUploadModalComponent from "../../Components/Common/ImageUploadModalComponent";
import ImageViewerModalComponent from "../../Components/Common/ImageViewerModalComponent";
import { ISOToDateTime, filterParams } from "../../Components/Common/Common";
import Spinner from "react-bootstrap/Spinner";
import { Button, Col, InputGroup, Form } from "react-bootstrap";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import ReactDOM from "react-dom";
import { getCurrentDate, GetPre7DayDate } from "../../Utils/commonfunctions";

const emtSearchFormData = {
  start_date: GetPre7DayDate(),
  end_date: getCurrentDate(),
};

export default function IssueToDepartment() {
  // State variables
  const { listStones, setListStones } = useStonesContext();
  const { setFindNo } = useStonesContext();
  const { setBatchAvgList } = useStonesContext();
  const [options, setOptions] = useState([]);
  const [useroptions, setUseroptions] = useState([]);
  const [departmentID, setDepartmentID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [issueToDepartmentList, setIssueToDepartmentList] = useState([]);

  //Insert Image
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  //Image Viewer
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedBatchNo, setSelectedBatchNo] = useState(null);

  //Btn Loader
  const [showLoader, setShowLoader] = useState(false);

  const [stoneDataforPrint, setStoneDataforPrint] = useState([]);

  const [formData, setFormData] = useState(emtSearchFormData);

  const [agGridLoadings, setAgGridLoadings] = useState(false);

  useEffect(() => {
    getAllDepartment();
    SearchIssueTODepDataDateFilter(emtSearchFormData);
  }, []);

  const getAllDepartment = () => {
    api
      .get("Department/All")
      .then((result) => {
        setOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fetch users based on the selected department
  useEffect(() => {
    if (departmentID !== null) {
      api
        .get(`User/DepartmentViseUser?departmentID=${departmentID.value}`)
        .then((result) => {
          setUseroptions(result.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [departmentID]);

  // Handle department dropdown selection
  const ddDepartmentNameList = (selectedOption) => {
    setDepartmentID(selectedOption);
  };

  // Handle user dropdown selection
  const ddUserNameList = (selectedOption) => {
    setUserID(selectedOption);
  };

  // Insert data when Save button is clicked
  const InsertIssueToDepartment = () => {
    if (departmentID === null) {
      toast.error("Please Select Department.");
      return;
    }
    if (userID === null) {
      toast.error("Please Select User.");
      return;
    }
    setShowLoader(true);
    const data = {
      DepartmentID: departmentID?.value,
      UserID: userID?.value,
      ListStone: listStones,
    };
    api
      .post(`IssueToDepartment/Insert`, data)
      .then((result) => {
        toast.success(result.data.message);
        // Reset the form fields after a successful API call
        setFindNo("");
        setListStones([]);
        setBatchAvgList([]);
        setDepartmentID(null);
        setUserID(null);
        // getIssueToDepartmentList();
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
        setShowLoader(false);
      })
      .finally(() => {
        SearchIssueTODepDataDateFilter(emtSearchFormData);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert Image
  const popUpForImage = (data) => {
    setImageModalOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    setImageModalOpen(false);
  };
  // Insert Image End

  //Image Viewer Start
  const popUpForViewImage = (batchNo) => {
    setSelectedBatchNo(batchNo);
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
    setSelectedBatchNo(0);
  };

  // export exel function
  const exportToExcel = () => {
    setShowLoader(true);
    setTimeout(() => {
      const fileName = "IssueToDepartment.xlsx";

      // Format the data to be exported, including the date and time
      const formattedData = issueToDepartmentList.map((item) => {
        const date = new Date(item.createdDatetime);
        const formattedDate = date.toLocaleDateString("en-GB"); // Formats the date as dd/mm/yyyy
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedTime = `${
          hours % 12 || 12
        }:${minutes}:${seconds} ${ampm}`;
        const formattedDateTime = `${formattedDate} ${formattedTime}`;

        return {
          Date: formattedDateTime,
          No: item.stoneNo,
          Type: item.stoneType,
          UserName: item.userName,
          DepartmentName: item.departmentName,
          PCS: item.pcs,
          Length: item.length,
          Width: item.width,
          Height: item.height,
          Weight: item.weight,
        };
      });

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Department Data");

      // Set up the columns
      worksheet.columns = [
        { header: "Date", key: "Date", width: 30 },
        { header: "No", key: "No", width: 15 },
        { header: "Type", key: "Type", width: 20 },
        { header: "User Name", key: "UserName", width: 25 },
        { header: "Department Name", key: "DepartmentName", width: 25 },
        { header: "PCS", key: "PCS", width: 10 },
        { header: "Length", key: "Length", width: 15 },
        { header: "Width", key: "Width", width: 15 },
        { header: "Height", key: "Height", width: 15 },
        { header: "Weight", key: "Weight", width: 15 },
      ];

      // Add the rows to the worksheet
      worksheet.addRows(formattedData);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF4F81BD" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Adjust column widths based on content length
      worksheet.columns.forEach((column) => {
        let maxLength = column.header.length;
        formattedData.forEach((row) => {
          const cellValue = row[column.key];
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
        column.width = maxLength + 2; // Add padding
      });

      // Export the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      });

      setShowLoader(false);
    }, 1000);
  };

  //Print Section Start
  const PrintPreview = ({ stoneDetails }) => {
    return (
      <>
        <div style={{}}>
          <table
            className="table table-bordered"
            style={{
              fontSize: "12px",
              lineHeight: "8px",
              marginTop: "-1px",
              borderColor: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={16}
                  style={{ textAlign: "center", fontSize: "14px" }}
                >
                  Final Stock List
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>No</th>
                <th style={{ textAlign: "left", minWidth: "140px" }}>Date</th>
                <th style={{ textAlign: "center", minWidth: "70px" }}>
                  StoneNo
                </th>
                <th style={{ textAlign: "center" }}>Type</th>
                <th style={{ textAlign: "center" }}>UserName</th>
                <th style={{ textAlign: "center" }}>DepartmentName</th>
                <th style={{ textAlign: "center" }}>PCS</th>
                <th style={{ textAlign: "center" }}>Length</th>
                <th style={{ textAlign: "center" }}>Width</th>
                <th style={{ textAlign: "center" }}>Height</th>
                <th style={{ textAlign: "center" }}>Weight</th>
              </tr>
            </thead>
            <tbody>
              {stoneDetails.map((stone, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td style={{ textAlign: "left", minWidth: "140px" }}>
                    {ISOToDateTime(stone.createdDatetime)}
                  </td>
                  <td style={{ textAlign: "center", minWidth: "70px" }}>
                    {stone.stoneNo}
                  </td>
                  <td style={{ textAlign: "center" }}>{stone.stoneType}</td>
                  <td style={{ textAlign: "center" }}>{stone.userName}</td>
                  <td style={{ textAlign: "center" }}>
                    {stone.departmentName}
                  </td>
                  <td style={{ textAlign: "center" }}>{stone.pcs}</td>
                  <td style={{ textAlign: "center" }}>{stone.length}</td>
                  <td style={{ textAlign: "center" }}>{stone.width}</td>
                  <td style={{ textAlign: "center" }}>{stone.height}</td>
                  <td style={{ textAlign: "center" }}>{stone.weight}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const printissuedepartmentreport = (listStones) => {
    const StoneDetailsForPrint = stoneDataforPrint;

    // Log the filtered result to debug
    console.log("StoneDetailsForPrint:", StoneDetailsForPrint);

    const printWindow = window.open("", "_blank");

    printWindow.document.write(
      '<html><head><title>Print Barcode</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"><style>{{width:"760px;"}}</style></head><body>'
    );

    ReactDOM.render(
      <PrintPreview stoneDetails={StoneDetailsForPrint} />,
      printWindow.document.body
    );

    printWindow.document.write("</body></html>");

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200);
  };

  const onFilterChanged = (event) => {
    // updatePinnedBottomRowData(event);
    // if (event) {
    //   event.api.refreshCells({ force: true, suppressFlash: true });
    // }
    var filteredData = event.api
      .getModel()
      .rowsToDisplay.map((row) => row.data);
    setStoneDataforPrint(filteredData);
  };

  const handleCheckboxSelection = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIssuetodepRow = Array.isArray(selectedRows)
      ? selectedRows
      : [];
    setStoneDataforPrint(selectedIssuetodepRow);
  };

  const SearchIssueTODepDataDateFilter = () => {
    setAgGridLoadings(true);
    api
      .post(
        `Stone/StoneListOfIssuetoDepartment?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}`
      )
      .then((response) => {
        setIssueToDepartmentList(response.data.data);
        setStoneDataforPrint(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching Jangad data:", error);
      })
      .finally(() => {
        setAgGridLoadings(false);
      });
  };

  const columnDef = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      width: 50,
      headerClass: "centered-header",
      pinned: "left",
    },
    {
      headerName: "Date",
      field: "createdDatetime",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      // headerClass: "widthTableColum",
      width: "200px",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      valueFormatter: (params) => ISOToDateTime(params.value),
    },
    {
      headerName: "No",
      field: "stoneNo",
      sortable: true,
      filter: true,
      // headerClass: "widthTableColum",
      width: "120px",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Type",
      field: "stoneType",
      sortable: true,
      filter: true,
      // headerClass: "widthTableColum",
      width: "120px",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "User Name",
      field: "userName",
      // headerClass: "widthTableColum",
      width: "120px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Department Name",
      field: "departmentName",
      // headerClass: "widthTableColum",
      width: "180px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Pcs",
      field: "pcs",
      // headerClass: "widthTableColum",
      width: "80px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Length",
      field: "length",
      // headerClass: "widthTableColum",
      width: "100px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Width",
      field: "width",
      // headerClass: "widthTableColum",
      width: "100px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Height",
      field: "height",
      // headerClass: "widthTableColum",
      width: "100px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Weight",
      field: "weight",
      // headerClass: "widthTableColum",
      width: "110px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Attached",
      field: "batchNo",
      width: "100px",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="attach-outline"
          class="iconsFonts attachIcon"
          onClick={() => popUpForImage(data)}
        ></ion-icon>
      ),
    },
    {
      headerName: "View",
      field: "batchNo",
      width: "100px",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="eye-outline"
          class="iconsFonts attachIcon"
          onClick={() => popUpForViewImage(data.stoneNo)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Print",
      field: "stoneNo",
      width: "100px",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value, data }) => {
        if (value.split("-")[0] === "LOT") {
          return (
            <ion-icon
              name="print-outline"
              class="iconsFonts"
              onClick={() => PrintBatchSticker(value, data.stoneProcessNo)}
            ></ion-icon>
          );
        } else {
          return (
            <ion-icon
              name="print-outline"
              class="iconsFonts"
              onClick={() => PrintStoneSticker(value, data.stoneProcessNo)}
            ></ion-icon>
          );
        }
      },
    },
    {
      headerName: "StonesByLot",
      field: "stoneNo",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      width: "150px",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value, data }) => {
        if (value.split("-")[0] === "LOT") {
          return (
            <ion-icon
              name="print-outline"
              class="iconsFonts"
              onClick={() =>
                PrintStoneStickerFromBatchNo(value, data.stoneProcessNo)
              }
            ></ion-icon>
          );
        } else {
          return (
            <p>
              {" "}
              <b>Not!</b>
            </p>
          );
        }
      },
    },
  ];
  return (
    <>
      {showLoader && (
        <>
          <div className="overlay"></div>
          <div className="overlay-content text-center">
            <Spinner animation="" variant="primary">
              <span class="loader"></span>
            </Spinner>
          </div>
        </>
      )}
      <ToastContainer />
      <FindStone
        IssueTo={"Issue To Department"}
        findUrlForStone={
          "Stone/ByBatchNoOrSotneNoForCueerntUser?BatchNoOrStoneNo"
        }
        findUrlForBatch={
          "Stone/BatchAvgListByBatchNoOrStoneNo?BatchNoOrStoneNo"
        }
        showPartNo={false}
        showWithoutTextBox={false}
        showWithTextBox={true}
        showDLCAndLOSS={true}
        showTotal={true}
      />

      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="departmentDropdown">Department:</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Select
                  id="departmentDropdown"
                  value={departmentID}
                  onChange={ddDepartmentNameList}
                  options={options.map((option) => ({
                    value: option.departmentID,
                    label: option.departmentName,
                  }))}
                  placeholder="--Select Department--"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="userDropdown">User:</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Select
                  id="userDropdown"
                  value={userID}
                  onChange={ddUserNameList}
                  options={useroptions.map((useroption) => ({
                    value: useroption.userID,
                    label: useroption.userName,
                  }))}
                  placeholder="--Select User--"
                />
              </div>
            </div>
          </div>
          <div className="card-footer margin_top10">
            <button
              type="button"
              className="align-items-center btn btn-primary mb-20px"
              onClick={InsertIssueToDepartment}
              loading={showLoader}
              disabled={showLoader}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="card mt-10">
        <div className="card-header d-flex justify-content-between align-items-center">
          {/* Title on the left */}
          <div className="fw-bold">
            <span>Issue To Department</span>
          </div>

          {/* Centered Date Inputs */}
          <div className="d-flex justify-content-center flex-grow-1">
            <Col md={6} lg={3} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={3} sm={12} className="my-0.5 mx-2">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={formData.end_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Button
              variant="primary"
              onClick={() => SearchIssueTODepDataDateFilter()}
            >
              <i className="fa fa-search"></i> Search
            </Button>
          </div>

          {/* Buttons on the right */}
          <div className="d-flex align-items-center">
            <Button
              type="button"
              variant="success"
              className="btn btn-primary me-2"
              onClick={exportToExcel}
            >
              <i className="fa fa-file-excel"></i> Export to Excel
            </Button>
            <Button
              variant="primary"
              onClick={() => printissuedepartmentreport(listStones)}
            >
              <i className="fa fa-print"></i> Print
            </Button>
          </div>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={issueToDepartmentList}
            onSelectionChanged={handleCheckboxSelection}
            columnDefs={columnDef}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
            onFilterChanged={onFilterChanged}
            paginationPageSize={100}
            height="70vh"
            loading={agGridLoadings}
          />
        </div>
      </div>

      {/* Insert Image */}
      {imageModalOpen && (
        <ImageUploadModalComponent
          showModal={imageModalOpen}
          onClose={closeModal} // Pass the function to close the modal
          bNO={modalData.stoneNo}
          sNO={modalData.stoneNo}
          referenceID={modalData.stoneID}
          type="Machine Start"
        />
      )}

      {/* Image Viewer */}
      {isImageViewerOpen && (
        <ImageViewerModalComponent
          showModal={isImageViewerOpen}
          onClose={closeImageViewer}
          referenceID={selectedBatchNo}
          refType="Machine Start"
        />
      )}
    </>
  );
}
