import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";

export default function Worker() {
  const [workerData, setWorkerData] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getWorker();
    getDepartmentName();
  }, []);

  const getDepartmentName = () => {
    api
      .get("Department/All")
      .then((result) => {
        setOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fetch all departments
  const getWorker = () => {
    api
      .get("Worker/All")
      .then((result) => {
        setWorkerData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Insert & Update Data
  const formik = useFormik({
    initialValues: {
      worker_id: 0,
      worker_Name: "",
      mobile_no: "",
      department_id: "",
      isActive: false,
    },
    validationSchema: Yup.object({
      worker_Name: Yup.string()
        .max(15, "Worker Name must be 15 characters or less")
        .min(3, "Worker Name must be at least 3 characters")
        .required("Enter Worker Name"),
      department_id: Yup.string().required("Select Department Name"),
    }),
    validateOnChange: true, // Enable validation on change
    validateOnBlur: true, // Enable validation on blur
    onSubmit: (values, { resetForm }) => {
      const data = {
        workerID: values.worker_id,
        workerName: values.worker_Name,
        departmentID: values.department_id,
        workerMobileNumber: values.mobile_no,
        isActive: values.isActive,
      };

      const endpoint = values.worker_id === 0 ? "Worker/Insert" : "Worker/Edit";
      api[values.worker_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          getWorker();
          values.worker_id === 0
            ? toast.success(result.data.message)
            : toast.success(result.data.message);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
      resetForm();
    },
  });

  // Get data of a single WorkerMaster
  const getSingleWorker = (id) => {
    api
      .get(`Worker/ByID?WorkerID=${id}`)
      .then((result) => {
        const WorkerMasterData = result.data.data;
        setTimeout(() => {
          formik.setFieldValue("worker_id", WorkerMasterData.workerID);
          formik.setFieldValue("worker_Name", WorkerMasterData.workerName);
          formik.setFieldValue(
            "mobile_no",
            WorkerMasterData.workerMobileNumber
          );
          formik.setFieldValue("department_id", WorkerMasterData.departmentID);
          formik.setFieldValue("isActive", WorkerMasterData.isActive);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete WorkerMaster
  const deleteWorker = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Worker/Delete?WorkerID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getWorker();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "Worker Name",
      field: "workerName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Edit",
      width: 100,
      field: "workerID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleWorker(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 100,
      field: "workerID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteWorker(value)}
        ></ion-icon>
      ),
    },
  ];

  const ddDepartmentName = (e) => {
    formik.setFieldValue("department_id", e.target.value);
  };

  return (
    <>
      <ToastContainer />

      <div className="card">
        <div className="card-header">
          <span>New Worker</span>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="department_id" className="form-label">
                  Department:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="input-group">
                  <select
                    className="form-control"
                    name="department_id"
                    value={formik.values.department_id}
                    onBlur={formik.handleBlur}
                    onChange={ddDepartmentName}
                  >
                    <option value="">--Select department Name--</option>
                    {options.map((option) => (
                      <option
                        key={option.departmentID}
                        value={option.departmentID}
                      >
                        {option.departmentName}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.touched.department_id &&
                  formik.errors.department_id && (
                    <div className="text-danger">
                      {formik.errors.department_id}
                    </div>
                  )}
              </div>
            </div>

            <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="worker_Name" className="form-label">
                  Worker Name:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  id="worker_Name"
                  placeholder="Worker Name"
                  {...formik.getFieldProps("worker_Name")}
                />
                {formik.touched.worker_Name && formik.errors.worker_Name && (
                  <div className="text-danger">{formik.errors.worker_Name}</div>
                )}
              </div>
              <label id="worker_Name"></label>
            </div>

            <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="mobile_no" className="form-label">
                  Mobile No:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <input
                  type="tel"
                  className="form-control"
                  id="mobile_no"
                  placeholder="Enter Mobile No"
                  {...formik.getFieldProps("mobile_no")}
                />
              </div>
            </div>

            <div className="row col-md-12 mb-3">
              <div className="col-md-4 offset-md-2 col-sm-4 offset-sm-2 col-lg-1 offset-lg-2">
                <div className="form-check" style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    checked={formik.values.isActive}
                    name="isActive"
                    onChange={formik.handleChange}
                    id="isActive"
                  />
                  {"     "}
                  <strong style={{ marginLeft: "10px" }}>Active</strong>
                  {formik.touched.isActive && formik.errors.isActive && (
                    <div className="text-danger">{formik.errors.isActive}</div>
                  )}
                  <label htmlFor="isActive" className="form-label"></label>
                </div>
              </div>
            </div>
            <div className="card-footer margin_top10">
              <button type="submit" className="btn btn-primary mb-20px">
                {formik.values.worker_id > 0 ? "Update" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <span>Worker Master List</span>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={workerData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="70vh"
          ></AgGridComponent>
        </div>
      </div>
    </>
  );
}
