import { React, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Utils/api";
import FindStone from "../../Components/Common/FindStone";
import { useStonesContext } from "../../contextFile/StoneContext/StonesContext";

export default function ReceiveToWorker() {
  const { listStones, setListStones } = useStonesContext();
  const { setFindNo } = useStonesContext();
  const { setBatchAvgList } = useStonesContext();

  //Btn Loader
  const [showLoader, setShowLoader] = useState(false);

  const InsertIssueToWorker = () => {
    setShowLoader(true);
    
    api
      .post(`IssueToWorker/StoneReceiveToWorker`, listStones)
      .then((result) => {
        toast.success(result.data.message);
        // Reset the form fields after a successful API call
        setFindNo("");
        setListStones([]);
        setBatchAvgList([]);
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
        setShowLoader(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <FindStone
        IssueTo={"Receive From Worker"}
        findUrlForStone={
          "IssueToWorker/ByBatchNoOrStoneNoForCurrentUser?BatchNoOrStoneNo"
        }
        findUrlForBatch={
          "IssueToWorker/BatchAvgListByBatchNoOrStoneNo?BatchNoOrStoneNo"
        }
        showPartNo={true}
        showWithoutTextBox={true}
        showWithTextBox={false}
        showDLCAndLOSS={true}
        showTotal={false}
      />

      <div className="card text-center">
        <div className="card-body d-flex justify-content-center ">
          <button
            type="button"
            className="align-items-center btn btn-primary "
            onClick={InsertIssueToWorker}
            loading={showLoader}
            disabled={showLoader}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}
