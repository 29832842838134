import React, { useRef, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import api from "../../Utils/api";
import Loader from "../../Components/Common/Loader";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import { PrintBagSticker } from "../../Utils/commonfunctions";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const emptyNewBagData = {
  StoneID: 0,
  Pcs: "",
  Weight: "",
  StoneType: "",
  StoneTypeForBag: "",
  ShapeAndColorRemarks: "",
  WeightRemarks: "",
};

export default function GenerateBag() {
  const [formData, setFormData] = useState(emptyNewBagData);
  const [validated, setValidated] = useState(false);
  const GenerateNewBagFormRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [StoneSummaryData, setStoneSummaryData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [GeneratedBagData, setGeneratedBagData] = useState([]);

  useEffect(() => {
    getStoneTypeName();
    getGeneratedBagDataList();
  }, []);

  const getGeneratedBagDataList = () => {
    api
      .get("Stone/GeneratedBags")
      .then((result) => {
        setGeneratedBagData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStoneTypeName = () => {
    api
      .get("StoneType/GetStoneTypeName")
      .then((result) => {
        setOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DataOfStoneSummary = (type) => {
    api
      .get(`FinalStoneSummary/UserAndStoneTypeWiseData?StoneType=${type}`)
      .then((result) => {
        const data = result?.data?.data;
        if (data && typeof data === "object" && Object.keys(data).length > 0) {
          setStoneSummaryData(data);
        } else {
          toast.error("This Stone Type Not In Final Stone!");
          setStoneSummaryData(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "StoneType") {
      DataOfStoneSummary(value);
    }
  };

  const GenerateNewBag = (e) => {
    setValidated(true);
    e.preventDefault();
    const form = GenerateNewBagFormRef.current;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    // let SumTotalData = parseFloat(StoneSummaryData.totalweight);
    // let FormWight = parseFloat(formData.Weight);

    // let FinalSumTotalData = SumTotalData !== null ? SumTotalData.toFixed(2) : 0;
    // let FinalFormWight = FormWight !== null ? FormWight.toFixed(2) : 0;

    // if (FinalSumTotalData > FinalFormWight) {
    //   toast.error("Select valid  Weight");
    //   return;
    // }
    // if (parseInt(formData.Pcs) > StoneSummaryData.pcs) {
    //   toast.error("Select valid Pcs ");
    //   return;
    // }
    if (formData.StoneID === 0) {
      const sumTotalWeight = parseFloat(StoneSummaryData.totalweight) || 0;
      const formWeight = parseFloat(formData.Weight) || 0;

      if (formWeight > sumTotalWeight.toFixed(2)) {
        toast.error("Select valid Weight");
        return;
      }

      if (parseInt(formData.Pcs) > StoneSummaryData.pcs) {
        toast.error("Select valid Pcs");
        return;
      }
    }

    const data = {
      StoneID: formData?.StoneID || 0,
      StoneType: formData.StoneType,
      StoneTypeForBag: formData.StoneTypeForBag,
      Pcs: formData.Pcs,
      Weight: formData.Weight,
      ShapeAndColorRemarks: formData.ShapeAndColorRemarks,
      WeightRemarks: formData.WeightRemarks,
    };
    setShowLoader(true);
    const endpoint =
      formData.StoneID === 0
        ? "Stone/GenerateNewBag"
        : "Stone/EditGeneratedBag";

    api[formData.StoneID === 0 ? "post" : "patch"](endpoint, data)
      // api
      //   .post(`Stone/GenerateNewBag`, data)
      .then((result) => {
        toast.success(result.data.message);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      })
      .finally(() => {
        setValidated(false);
        setFormData(emptyNewBagData);
        setShowLoader(false);
        setStoneSummaryData(null);
        getGeneratedBagDataList();
      });
  };

  const getSingleASI = async (id) => {
    await api
      .get(`Stone/ByID?StoneID=${id}`)
      .then((result) => {
        const ASIData = result.data.data;
        const selectedType = options.find((f) => f.text === ASIData.stoneType);
        setFormData({
          StoneID: ASIData.stoneID,
          Pcs: ASIData.pcs,
          Weight: ASIData.weight,
          StoneTypeForBag: selectedType ? selectedType.text : "",
          ShapeAndColorRemarks: ASIData.shapeAndColorRemarks,
          WeightRemarks: ASIData.weightRemarks,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // for export excel
  const exportToExcel = () => {
    setShowLoader(true);
  
    setTimeout(async () => {
      try {
        const fileName = "GenerateBag.xlsx";
  
        // Format the data to be exported
        const formattedData = GeneratedBagData.map((item) => ({
          BagNo: item.stoneNo,
          Pcs: item.pcs,
          StoneType: item.stoneType,
          Weight: item.weight,
          ShapeAndColorRemarks: item.shapeAndColorRemarks,
          WeightRemarks: item.weightRemarks,
        }));
  
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Issue Machine List");
  
        // Set up the columns
        worksheet.columns = [
          { header: "Bag No", key: "BagNo", width: 25 },
          { header: "PCS", key: "Pcs", width: 10 },
          { header: "Stone Type", key: "StoneType", width: 15 },
          { header: "Weight", key: "Weight", width: 15 },
          { header: "Shape and Color Remarks", key: "ShapeAndColorRemarks", width: 25 },
          { header: "Weight Remarks", key: "WeightRemarks", width: 25 },
        ];
  
        // Add the rows to the worksheet
        worksheet.addRows(formattedData);
  
        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF4F81BD" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });
  
        // Adjust column widths based on content length
        worksheet.columns.forEach((column) => {
          let maxLength = column.header.length;
          formattedData.forEach((row) => {
            const cellValue = row[column.key];
            if (cellValue) {
              maxLength = Math.max(maxLength, cellValue.toString().length);
            }
          });
          column.width = maxLength + 2; // Add padding
        });
  
        // Export the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        // Handle any error here, such as showing an error message to the user
      } finally {
        setShowLoader(false);
      }
    }, 1000);
  };
  

  const columnDefs = [
    {
      headerName: "BagNo",
      field: "stoneNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pcs",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Stone Type",
      field: "stoneType",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
    },
    {
      headerName: "ShapeAndColorRemarks",
      field: "shapeAndColorRemarks",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "WeightRemarks",
      field: "weightRemarks",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Edit",
      width: 100,
      field: "stoneID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleASI(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Print",
      width: 100,
      field: "stoneNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="print-outline"
          class="iconsFonts"
          onClick={() => PrintBagSticker(data.stoneNo, data.stoneProcessNo)}
        ></ion-icon>
      ),
    },
  ];

  return (
    <>
      {showLoader && <Loader />}
      <ToastContainer />
      <Card>
        <Card.Header>
          <span>Generate Bag</span>
        </Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
              if (e) {
                e.preventDefault();
              }

              if (GenerateNewBagFormRef.current && e) {
                if (e.target.name === "GenerateNewBag") {
                  GenerateNewBag(e);
                }
              }
            }}
            ref={GenerateNewBagFormRef}
            name="GenerateNewBag"
          >
            <Row>
              <Col md={6}>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="stoneType">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Type
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Select
                        value={formData.StoneType}
                        onChange={handleInputChange}
                        name="StoneType"
                        required
                      >
                        <option value="">-- Select Stone Type --</option>
                        {options.map((option) => (
                          <option key={option.text} value={option.text}>
                            {option.text}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please select a stone type.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="stoneType">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Stone Type
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Select
                        value={formData.StoneTypeForBag}
                        onChange={handleInputChange}
                        name="StoneTypeForBag"
                        required
                      >
                        <option value="">-- Select Stone Type --</option>
                        {options.map((option) => (
                          <option key={option.text} value={option.text}>
                            {option.text}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please select a stone type.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="stoneType">
                      <Form.Label style={{ marginRight: "20px" }}>
                        No.Pcs
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="number"
                        name="Pcs"
                        placeholder="Enter Number Of Pcs"
                        value={formData.Pcs}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the number of pieces.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="stoneType">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Total Weight
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="number"
                        name="Weight"
                        placeholder="Enter Weight"
                        value={formData.Weight}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide the total weight.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="stoneType">
                      <Form.Label style={{ marginRight: "20px" }}>
                        ShapeAndColor Remarks
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="text"
                        name="ShapeAndColorRemarks"
                        placeholder="Enter ShapeAndColor Remarks"
                        value={formData.ShapeAndColorRemarks}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="stoneType">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Weight Remarks
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="text"
                        name="WeightRemarks"
                        placeholder="Enter WeightRemarks"
                        value={formData.WeightRemarks}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                {StoneSummaryData != null && (
                  <Card border="primary">
                    <Card.Body>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>StoneType</th>
                              <th>Pcs</th>
                              <th>Total Weight</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{StoneSummaryData.stoneType}</td>
                              <td>{StoneSummaryData.pcs}</td>
                              <td>
                                {StoneSummaryData.totalweight !== null &&
                                  StoneSummaryData.totalweight !== 0 &&
                                  StoneSummaryData.totalweight.toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
            <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                {formData.StoneID === 0 ? "Save" : "Update"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header className="d-flex justify-content-between">
          <span>Generated Bags</span>
          <Button
            type="button"
            variant="success"
            className="btn btn-primary mb-2"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </Card.Header>
        <Card.Footer>
          <AgGridComponent
            rowData={GeneratedBagData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="80vh"
          ></AgGridComponent>
        </Card.Footer>
      </Card>
    </>
  );
}
