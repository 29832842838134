export const ISOToDateTime = (apiDate) => {
    const dateObj = new Date(apiDate);

    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const amPm = hours >= 12 ? 'PM' : 'AM';
  
    const formattedDate = `${padZero(dateObj.getDate())}-${padZero(dateObj.getMonth() + 1)}-${dateObj.getFullYear()} ${padZero(hours % 12 || 12)}:${padZero(minutes)} ${amPm}`;
  
    return formattedDate;
  };
  
  export const ISOToDate = (apiDate) => {
    const dateObj = new Date(apiDate);
    const formattedDate = `${padZero(dateObj.getDate())}-${padZero(dateObj.getMonth() + 1)}-${dateObj.getFullYear()}`;
  
    return formattedDate;
  };

  const padZero = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  export const filterParams = {
    comparator: function(filterLocalDateAtMidnight, cellValue) {
      if (!cellValue) return -1;
      const cellDate = new Date(cellValue);
      if (isNaN(cellDate)) return -1;
      const cellDateMidnight = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
      const filterDate = new Date(filterLocalDateAtMidnight.getFullYear(), filterLocalDateAtMidnight.getMonth(), filterLocalDateAtMidnight.getDate());
      if (filterDate.getTime() === cellDateMidnight.getTime()) {
        return 0;
      }
      if (cellDateMidnight < filterDate) {
        return -1;
      }
      if (cellDateMidnight > filterDate) {
        return 1;
      }
      return 0;
    },
    minValidYear: new Date().getFullYear() - 100, // Current year minus 100
    maxValidYear: new Date().getFullYear() + 100,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };