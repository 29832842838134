import React, { useState } from "react";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import "./SearchStonePopup.css"; // Import your custom CSS file
import { ISOToDateTime } from "../../Components/Common/Common";
import UserSetting from "../../UserSettings.json";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import StoneHistorypopup from "../Stone History Popup/StoneHistorypopup";

function SearchStonePopup() {
  const [findNo, setFindNo] = useState("");
  const [findNoLabel, setFindNoLabel] = useState("");
  const [listStones, setListStones] = useState([]);
  const [listStonesForHistory, setListStonesForHistory] = useState([]);
  const [stoneHistory, setStoneHistory] = useState([]);

  const [isModalOpenStone, setIsModalOpenStone] = useState(false);
  const [isModalStonLotData, setIsModalStonLotData] = useState(false);

  //Image Viewer
  const [imageViewer, setImageViewer] = useState(null);
  const [imageShow, setImageShow] = useState(false);
  const [BatchNo, setBatchNo] = useState("");
  const [StoneProcessNo, setStoneProcessNo] = useState("");

  const handleCloseModal = () => {
    setIsModalOpenStone(false);
    setListStones([]);
    setListStonesForHistory([]);
    setImageViewer(null);
    setImageShow(false);
  };

  const handleSearchInputChange = (e) => {
    setFindNo(e.target.value);
    setFindNoLabel(e.target.value);
  };

  const FindStone = (e) => {
    setListStones([]);
    setListStonesForHistory([]);
    if (e.key === "Enter") {
      api
        .get(
          `Stone/ByBatchNoOrSotneNoForCurrentPosition?BatchNoOrStoneNo=${findNo}`
        )
        .then((result) => {
          const data = result.data.data;
          if (data[0] !== undefined && data[0] !== null) {
            setListStones(data);
          }
        })
        .catch((error) => {
          toast.error(error);
        });

      api
        .get(`Stone/ByBatchNoOrSotneNoForHistory?BatchNoOrStoneNo=${findNo}`)
        .then((result) => {
          const data = result.data.data;
          if (data[0] !== undefined && data[0] !== null) {
            setListStonesForHistory(data);
          }
        })
        .catch((error) => {
          toast.error(error);
        });

      //Image API
      api
        .get(`ImagesForAll/ViewImagesInSearchPopUp?BatchNo=${findNo}`)
        .then((result) => {
          const data = result.data.data;

          const sortedData = [...data].sort(
            (a, b) => new Date(b.createDate) - new Date(a.createDate)
          );

          if (data[0] !== undefined && data[0] !== null) {
            setImageShow(true);
            const groupedImages = sortedData.reduce((acc, item) => {
              const createdDate = item.createDate;
              if (!acc[createdDate]) {
                acc[createdDate] = [];
              }
              acc[createdDate].push(item);
              return acc;
            }, {});
            setImageViewer(groupedImages);
          }
        })
        .catch((error) => {
          toast.error(error);
        });

      setIsModalOpenStone(true);
      setFindNo("");
    }
  };

  const onHide = () => {
    setIsModalStonLotData(false);
  };

  const HandlePop = (batchNo, stoneProcessNo) => {
    setBatchNo(batchNo);
    setStoneProcessNo(stoneProcessNo);
    setIsModalStonLotData(true);
  };

  return (
    <>
      <div className={`container ${isModalOpenStone ? "blur" : ""}`}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="text"
                className="form-control-ofNavSearch  rounded-pill form-"
                placeholder="Search for Stone.."
                value={findNo}
                onChange={handleSearchInputChange}
                onKeyDown={FindStone}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isModalOpenStone}
        onHide={handleCloseModal}
        dialogClassName="modal-dialog-scrollable"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>Stone Detail: {findNoLabel}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0">
            <div className="col-md-6 mt-4">
              <div>
                <h5 className="text-center currentTitle mb-3">
                  <i className="fa fa-diamond"></i> Current Status of Stone{" "}
                  <i className="fa fa-diamond"></i>
                </h5>
                {listStones.map((item, innerIndex) => (
                  <div className="card currentCard mb-3">
                    <div
                      className="card-header bg-transparent border-info d-flex"
                      style={{
                        justifyContent: "space-between",
                        fontSize: "18px",
                      }}
                    >
                      <div>
                        <i className="fa fa-building buildingCLR"></i>{" "}
                        <strong>{item.departmentName}</strong>
                      </div>
                      <div>
                        <i className=""></i>{" "}
                        <strong>S.P:{item.stoneProcessNo}</strong>
                      </div>
                      <div>
                        {item.lotType === "Mix" && (
                          <span>
                            <i className="fa fa-cube buildingCLR"></i>{" "}
                            <strong>{item.stoneNo}</strong>
                          </span>
                        )}
                      </div>
                      <div>
                        {item.batchNo != null && item.batchNo !== "" && (
                          <span>
                            <i className="fa fa-diamond buildingCLR"></i>{" "}
                            <strong> {item.batchNo}</strong>
                          </span>
                        )}
                      </div>
                      <div>
                        <i className="fa fa-calendar buildingCLR"></i>{" "}
                        {ISOToDateTime(item.createdDatetime)}
                      </div>
                    </div>
                    <div className="card-body" style={{ fontSize: "18px" }}>
                      <div className="arrange">
                        <div>
                          <span>Type:</span> {item.stoneType}
                        </div>
                        <div>
                          <span>Pcs:</span> {item.pcs}
                        </div>
                      </div>
                      <div className="arrange">
                        <div>
                          <span>Length:</span> {item.length.toFixed(2)}
                        </div>
                        <div>
                          <span>Width:</span> {item.width.toFixed(2)}
                        </div>
                        <div>
                          <span>Height:</span> {item.height.toFixed(2)}
                        </div>
                        <div>
                          <span>Weight:</span> {item.weight.toFixed(2)}
                        </div>
                        <div
                          style={
                            item.loss > 0
                              ? {
                                  background: "green",
                                  padding: "2px 8px",
                                  borderRadius: "6px",
                                  color: "#fff",
                                  textAlign: "center",
                                }
                              : item.loss < 0
                              ? {
                                  background: "red",
                                  padding: "2px 8px",
                                  borderRadius: "6px",
                                  color: "#fff",
                                  textAlign: "center",
                                }
                              : null
                          }
                        >
                          {item.loss > 0 ? (
                            <span>Grow: </span>
                          ) : (
                            <span>Loss: </span>
                          )}

                          <span>{item.loss.toFixed(2)}</span>
                        </div>

                        <div>
                          <span>DLC:</span> {item.dlc}
                        </div>
                      </div>
                      {item.remarks !== null && item.remarks !== "" && (
                        <div>
                          <span className="remarkslbl">{item.remarks}</span>
                        </div>
                      )}
                    </div>
                    <div
                      className="card-footer bg-transparent border-info userF-Size d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <i className="fa fa-user"></i>{" "}
                        <strong>Received By: {item.userName}</strong>
                      </div>
                      <div>
                        <i className="fa fa-user"></i>{" "}
                        <strong>Created By: {item.createdUserName}</strong>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-12 mt-4">
                {imageShow && (
                  <>
                    <hr className="hr" />
                    <h5 className="text-center historyTitle">
                      <i className="fa fa-image"></i> Images{" "}
                      <i className="fa fa-image"></i>
                    </h5>
                    <div className="row mb-3">
                      {Object.entries(imageViewer).map(
                        ([createdDate, images]) => (
                          <div key={createdDate}>
                            <div>
                              <div className="text-center imgDate">
                                <strong>{createdDate}</strong>
                              </div>
                              <div className="box">
                                <div className="box-header with-border">
                                  <div className="box-body">
                                    <SlideshowLightbox
                                      className="row"
                                      // className="container grid grid-cols-3 gap-2 mx-auto"
                                      showThumbnails={true}
                                    >
                                      {images.map(
                                        (item, index) =>
                                          item.imagePath !== null && (
                                            <img
                                              className="w-full rounded lightgalleryimage"
                                              src={
                                                UserSetting.imgURL +
                                                item.imagePath
                                              }
                                              alt=""
                                            />
                                          )
                                      )}
                                    </SlideshowLightbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <h5 className="text-center historyTitle mb-3">
                <i className="fa fa-diamond"></i> History of Stone{" "}
                <i className="fa fa-diamond"></i>
              </h5>
              <div>
                {listStonesForHistory.map((item, innerIndex) => (
                  <div key={innerIndex} className={`card historyCard mb-3  }`}>
                    <div
                      className="card-header bg-transparent border-primary-history d-flex"
                      style={{
                        justifyContent: "space-between",
                        fontSize: "18px",
                      }}
                    >
                      <div>
                        <i className="fa fa-building historyCLR"></i>{" "}
                        <strong> {item.departmentName}</strong>
                      </div>
                      <div>
                        <strong>S.P:{item.stoneProcessNo}</strong>
                      </div>
                      <div>
                        {item.batchNo != null && item.batchNo !== "" && (
                          <span style={{ cursor: "pointer" }}>
                            <i className="fa fa-diamond historyCLR"></i>{" "}
                            <strong
                              onClick={() =>
                                HandlePop(item.batchNo, item.stoneProcessNo)
                              }
                            >
                              {" "}
                              {item.batchNo}
                            </strong>
                          </span>
                        )}
                      </div>
                      <div>
                        {item.lotType === "Mix" && (
                          <span>
                            <i className="fa fa-cube historyCLR"></i>{" "}
                            <strong> {item.stoneNo}</strong>
                          </span>
                        )}
                      </div>
                      <div>
                        <i className="fa fa-calendar historyCLR"></i>{" "}
                        {ISOToDateTime(item.createdDatetime)}
                      </div>
                    </div>
                    <div className="card-body" style={{ fontSize: "18px" }}>
                      <div className="arrange">
                        <div>
                          <span>Type:</span> {item.stoneType}
                        </div>
                        <div>
                          <span>Pcs:</span> {item.pcs}
                        </div>
                      </div>
                      <div className="arrange">
                        <div>
                          <span> Length:</span> {item.length.toFixed(2)}
                        </div>
                        <div>
                          <span>Width:</span> {item.width.toFixed(2)}
                        </div>
                        <div>
                          <span> Height:</span> {item.height.toFixed(2)}
                        </div>
                        <div>
                          <span>Weight:</span> {item.weight.toFixed(2)}
                        </div>
                        <div
                          style={
                            item.loss > 0
                              ? {
                                  background: "green",
                                  padding: "2px 8px",
                                  borderRadius: "6px",
                                  color: "#fff",
                                  textAlign: "center",
                                }
                              : item.loss < 0
                              ? {
                                  background: "red",
                                  padding: "2px 8px",
                                  borderRadius: "6px",
                                  color: "#fff",
                                  textAlign: "center",
                                }
                              : null
                          }
                        >
                          {item.loss > 0 ? (
                            <span>Grow: </span>
                          ) : (
                            <span>Loss: </span>
                          )}
                          <span>{item.loss.toFixed(2)}</span>
                        </div>
                        <div>
                          <span>DLC:</span> {item.dlc}
                        </div>
                      </div>
                      {item.remarks !== null && item.remarks !== "" && (
                        <div>
                          <span className="remarkslbl">{item.remarks}</span>
                        </div>
                      )}
                    </div>
                    <div
                      className="card-footer bg-transparent border-primary-history userF-Size d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      {item.received == false && (
                        <div style={{ color: "red" }}>
                          <i className="fa fa-user "></i>{" "}
                          <strong>Not Received By: {item.userName}</strong>
                        </div>
                      )}
                      {item.received == true && (
                        <div>
                          <i className="fa fa-user historyCLR"></i>{" "}
                          <strong> Received By: {item.userName}</strong>
                        </div>
                      )}
                      <div>
                        <i className="fa fa-user historyCLR"></i>{" "}
                        <strong>Created By: {item.createdUserName}</strong>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <StoneHistorypopup
        show={isModalStonLotData}
        onHide={onHide}
        BatchNo={BatchNo}
        stoneProcessNo={StoneProcessNo}
      />
    </>
  );
}

export default SearchStonePopup;