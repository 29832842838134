import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
// import { toBeChecked } from "@testing-library/jest-dom/matchers";

export default function UserMaster() {
  const [departmentData, setDepartmentData] = useState([]);
  const [options, setOptions] = useState([]);
  const [Temp_option, setTempOptions] = useState([]);
  const [department, setDepartmentOptions] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listOfMachine, setListOfMachine] = useState(false);
  const [machineIdList, setMachineIdList] = useState([]);
  const [userIDofMachine, setUserIDofMachine] = useState("");

  useEffect(() => {
    getUser();
    getUserTypeName();
    getTemplateName();
    getDepartmentName();
  }, []);

  const handleStartClick = (data) => {
    setIsModalOpen(true);
    getMachineData(data);
    setUserIDofMachine(data);
  };

  const getUserTypeName = () => {
    api
      .get("UserType/All")
      .then((result) => {
        setOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTemplateName = () => {
    api
      .get("RoleTemplate/All")
      .then((result) => {
        setTempOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDepartmentName = () => {
    api
      .get("Department/All")
      .then((result) => {
        setDepartmentOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fetch all departments
  const getUser = () => {
    api
      .get("User/All")
      .then((result) => {
        setDepartmentData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Insert & Update Data
  const formik = useFormik({
    initialValues: {
      user_id: 0,
      user_Name: "",
      password: "",
      mobile_no: "",
      userType_id: "",
      department_id: "",
      headUserID : 0 ,
      roleTemplate_id: "",
      isActive: false,
      isPermission: false,
    },
    validationSchema: Yup.object({
      user_Name: Yup.string()
        .max(15, "User Name must be 15 characters or less")
        .min(3, "User Name must be at least 3 characters")
        .required("Enter User Name"),
      password: Yup.string()
        .min(3, "Password must be at least 3 Character ")
        .required("Enter Password"),
      department_id: Yup.string().required("Select Department Name"),
      userType_id: Yup.string().required("Select User Type"),
      roleTemplate_id: Yup.string().required("Select Role Template Name"),
    }),
    validateOnChange: true, // Enable validation on change
    validateOnBlur: true, // Enable validation on blur
    onSubmit: (values, { resetForm }) => {
      const data = {
        userID: values.user_id,
        userName: values.user_Name,
        password: values.password,
        mobileNumber: values.mobile_no,
        userTypeID: values.userType_id,
        roleTemplateID: values.roleTemplate_id,
        departmentID: values.department_id,
        isActive: values.isActive,
        isViewImagePermission: values.isPermission,
        headUserID : values.headUserID,
      };

      const endpoint = values.user_id === 0 ? "User/Insert" : "User/Edit";
      api[values.user_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          getUser();
          values.user_id === 0
            ? toast.success(result.data.message)
            : toast.success(result.data.message);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
      resetForm();
    },
  });

  const getMachineData = (id) => {
    ;
    api
      .get(`UserWiseMachine/ByID?UserID=${id}`)
      .then((result) => {
        const userWiseMachineData = result.data.data;
        const uniqueMachineIDs = [
          ...new Set(userWiseMachineData.map((machine) => machine.machineID)),
        ];

        api.get(`Machine/All`).then((machineResult) => {
          const allMachine = machineResult.data.data;
          // Filter out machines that are not in userWiseMachineData
          const missingMachines = allMachine.filter(
            (machine) => !uniqueMachineIDs.includes(machine.machineID)
          );
          const updatedListOfMachines = userWiseMachineData.map((machine) => ({
            userWiseID: machine.userWiseID, // Replace userWiseID with userWiseID
            machineID: machine.machineID,
            userID: machine.userID,
            machineNo: machine.machineNo,
          }));

          // Add missing machines to the updated list
          updatedListOfMachines.push(
            ...missingMachines.map((machine) => ({
              userWiseID: 0, // Replace userWiseID with userWiseID
              machineID: machine.machineID,
              machineNo: machine.machineNo,
            }))
          );

          // Sort the updatedListOfMachines by machineNo in alphabetical order
          updatedListOfMachines.sort((a, b) => {
            const machineNoA = a.machineNo || ""; // Use an empty string if machineNo is undefined
            const machineNoB = b.machineNo || ""; // Use an empty string if machineNo is undefined
            return machineNoA.localeCompare(machineNoB);
          });
          setTimeout(() => {
            setListOfMachine(updatedListOfMachines);
            setMachineIdList(userWiseMachineData);
          }, 400);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get data of a single UserMaster
  const getSingleUser = (id) => {
    ;
    api
      .get(`User/ByID?UserID=${id}`)
      .then((result) => {
        const UserMasterData = result.data.data;
        setTimeout(() => {
          formik.setFieldValue("user_id", UserMasterData.userID);
          formik.setFieldValue("user_Name", UserMasterData.userName);
          formik.setFieldValue("password", UserMasterData.password);
          formik.setFieldValue("mobile_no", UserMasterData.mobileNumber);
          formik.setFieldValue("userType_id", UserMasterData.userTypeID);
          formik.setFieldValue("department_id", UserMasterData.departmentID);
          formik.setFieldValue(
            "roleTemplate_id",
            UserMasterData.roleTemplateID
          );
          formik.setFieldValue("isActive", UserMasterData.isActive);
          formik.setFieldValue(
            "isPermission",
            UserMasterData.isViewImagePermission
          );
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete UserMaster
  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`User/Delete?UserID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getUser();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const [SubUserListOfHeadUser, setSubUserListOfHeadUser] = useState([]);
  const [SubUserIdList, setSubUserIdList] = useState([]);
  const [IsSubUserModelOpen, setIsSubUserModelOpen] = useState(false);
  const [HeadUserIdOfColumn, setHeadUserIdOfColumn] = useState(0);

  const handleSubUserSelection = (id) => {
    setHeadUserIdOfColumn(id);
    api
      .get(`UserAssigment/HeadIdWishData?HeadUserID=${id}`)
      .then((result) => {
        const UserWishSubUserData = result.data.data;
        const uniqueUserIDs = [
          ...new Set(UserWishSubUserData.map((user) => user.subUserID)),
        ];

        api.get(`User/All`).then((UserResult) => {
          const allUser = UserResult.data.data;
          // Filter out machines that are not in userWiseMachineData
          const MissingSubUser = allUser.filter(
            (SubUser) => !uniqueUserIDs.includes(SubUser.userID)
          );
          var updatedListOfUser = UserWishSubUserData.map((User) => {
            // Find the user object in allUser array that matches the subUserID
            const user = allUser.find((user) => user.userID === User.subUserID);
            
            // Check if user exists before accessing properties
            const SubUserName = user ? user.userName : 'Unknown'; // Fallback to 'Unknown' if user not found
            const DepartmentName = user ? user.departmentName : 'Unknown'; // Fallback to 'Unknown' if user not found
          
            return {
              UserAssigmentID: User.userAssigmentID,
              HeadUserID: User.headUserID,
              SubUserID: User.subUserID,
              SubUserName: SubUserName,
              DepartmentName: DepartmentName
            };
          });

          // Add missing machines to the updated list
          updatedListOfUser.push(
            ...MissingSubUser.map((data) => ({
              UserAssigmentID: 0,
              HeadUserID: 0, // Replace userWiseID with userWiseID
              SubUserID: data.userID,
              SubUserName: data.userName,
              DepartmentName: data.departmentName,
            }))
          );
 
          updatedListOfUser = updatedListOfUser.filter((item) => item.SubUserID !== id);

          updatedListOfUser.sort((a, b) => a.SubUserName.localeCompare(b.SubUserName));
        
          setTimeout(() => {
            setSubUserListOfHeadUser(updatedListOfUser);
            setSubUserIdList(UserWishSubUserData);
          }, 400);
          setIsSubUserModelOpen(true);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "User Name",
      field: "userName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "SubUser",
      width: 100,
      field: "userID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="people-circle-outline"
          class="iconsFonts editIcon"
          onClick={() => handleSubUserSelection(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "UserMachine",
      width: 100,
      field: "userID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="diamond-outline"
          class="iconsFonts editIcon"
          onClick={() => handleStartClick(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Edit",
      width: 100,
      field: "userID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleUser(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 100,
      field: "userID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteUser(value)}
        ></ion-icon>
      ),
    },
  ];

  const ddUserTypeName = (e) => {
    formik.setFieldValue("userType_id", e.target.value);
  };

  const ddRoleTemplateName = (e) => {
    formik.setFieldValue("roleTemplate_id", e.target.value);
  };

  const ddDepartmentName = (e) => {
    formik.setFieldValue("department_id", e.target.value);
    getUserAsDepartment(e.target.value)
  };

  const ddHeadUserID = (e) => {
    formik.setFieldValue("headUserID", e.target.value);
  };  


  const handleMachineList = (MID) => {
    ;
    const isMachineSelected = machineIdList.some(
      (item) => item.machineID === MID
    );

    if (isMachineSelected) {
      // If the machine is already selected, remove it from the list
      setMachineIdList((prevList) =>
        prevList.filter((item) => item.machineID !== MID)
      );
    } else {
      // If the machine is not selected, add it to the list
      setMachineIdList((prevList) => [
        ...prevList,
        { machineID: MID, userID: userIDofMachine, userWiseID: 0 },
      ]);
    }
  };
  const handleSubUserList = (UID) => {
    ;
    const IsUserSelect = SubUserIdList.some((item) => item.subUserID === UID);

    if (IsUserSelect) {
      // If the machine is already selected, remove it from the list
      setSubUserIdList((prevList) =>
        prevList.filter((item) => item.subUserID !== UID)
      );
    } else {
      // If the machine is not selected, add it to the list
      setSubUserIdList((prevList) => [
        ...prevList,
        { subUserID: UID, UserAssigmentID: 0, HeadUserID: 0 },
      ]);
    }
  };

  const InsertUserWithMachine = () => {
    // const data = {
    //   userID: userIDofMachine,
    //   list: machineIdList,
    // };
    api
      .post(`UserWiseMachine/Insert`, machineIdList)
      .then((result) => {
        toast.success(result.data.message);
        setIsModalOpen(false);
        setMachineIdList([]);
        setSubUserListOfHeadUser([]);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      });
  };

  const InsertUserWithSubUser = () => {
    const data = {
      HeadUserId: HeadUserIdOfColumn,
      list: SubUserIdList,
    };
    api
      .post(`UserAssigment/InsertSubUserAsHeadUser`, data)
      .then((result) => {
        toast.success(result.data.message);
        setIsSubUserModelOpen(false);
        setSubUserIdList([]);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      });
  };


const [UserDataAsPerDepartment,setUserDataAsPerDepartment]=useState([]);

  const getUserAsDepartment = (id)=>{
    api
    .get(`User/DepartmentViseUser?departmentID=${id}`)
    .then((result) => {
      setUserDataAsPerDepartment(result.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <>
      <ToastContainer />

      <div className="card">
        <div className="card-header">
          <span>New User</span>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="department_id" className="form-label">
                  Department:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="input-group">
                  <select
                    className="form-control"
                    name="department_id"
                    value={formik.values.department_id}
                    onBlur={formik.handleBlur}
                    onChange={ddDepartmentName}
                  >
                    <option value="">--Select department Name--</option>
                    {department.map((option) => (
                      <option
                        key={option.departmentID}
                        value={option.departmentID}
                      >
                        {option.departmentName}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.touched.department_id &&
                  formik.errors.department_id && (
                    <div className="text-danger">
                      {formik.errors.department_id}
                    </div>
                  )}
              </div>
            </div>

        { formik.values.user_id === 0 &&   <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="department_id" className="form-label">
                 Head User:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="input-group">
                  <select
                    className="form-control"
                    name="headUserID"
                    value={formik.values.headUserID}
                    onBlur={formik.handleBlur}
                    onChange={ddHeadUserID}
                  >
                    <option value="">--Select Head User--</option>
                    {UserDataAsPerDepartment.map((option) => (
                      <option
                        key={option.userID}
                        value={option.userID}
                      >
                        {option.userName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>}

            <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="user_Name" className="form-label">
                  UserName:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  id="user_Name"
                  placeholder="UserName"
                  {...formik.getFieldProps("user_Name")}
                />
                {formik.touched.user_Name && formik.errors.user_Name && (
                  <div className="text-danger">{formik.errors.user_Name}</div>
                )}
              </div>
              <label id="user_Name"></label>
            </div>

            <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="password" className="form-label">
                  Password:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Enter Password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="text-danger">{formik.errors.password}</div>
                )}
              </div>
              <label id="password"></label>
            </div>

            <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="mobile_no" className="form-label">
                  Mobile No:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <input
                  type="tel"
                  className="form-control"
                  id="mobile_no"
                  placeholder="Enter Mobile No"
                  {...formik.getFieldProps("mobile_no")}
                />
              </div>
            </div>

            <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="userType" className="form-label">
                  User Type:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="input-group">
                  <select
                    className="form-control"
                    name="userType_id"
                    value={formik.values.userType_id}
                    onBlur={formik.handleBlur}
                    onChange={ddUserTypeName}
                  >
                    <option value="">--Select User Type--</option>
                    {options.map((options) => (
                      <option
                        key={options.userTypeID}
                        value={options.userTypeID}
                      >
                        {options.userTypeName}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.touched.userType_id && formik.errors.userType_id && (
                  <div className="text-danger">{formik.errors.userType_id}</div>
                )}
              </div>
            </div>

            <div className="row col-md-12 mb-3">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="template" className="form-label">
                  Template:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="input-group">
                  <select
                    className="form-control"
                    name="roleTemplate_id"
                    value={formik.values.roleTemplate_id}
                    onBlur={formik.handleBlur}
                    onChange={ddRoleTemplateName}
                  >
                    <option value="">--Select Template Name--</option>
                    {Temp_option.map((option) => (
                      <option
                        key={option.roleTemplateID}
                        value={option.roleTemplateID}
                      >
                        {option.roleTemplateName}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.touched.roleTemplate_id &&
                  formik.errors.roleTemplate_id && (
                    <div className="text-danger">
                      {formik.errors.roleTemplate_id}
                    </div>
                  )}
              </div>
            </div>
            <div className="row col-md-12 mb-3">
              <div className="col-md-10 offset-md-2 col-sm-10 offset-sm-2 col-lg-10 offset-lg-2">
                <div className="form-check" style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    checked={formik.values.isActive}
                    name="isActive"
                    onChange={formik.handleChange}
                    id="isActive"
                  />
                  {"        "}
                  <strong style={{ marginLeft: "10px" }}>Active</strong>
                  {formik.touched.isActive && formik.errors.isActive && (
                    <div className="text-danger">{formik.errors.isActive}</div>
                  )}
                  <label htmlFor="isActive" className="form-label"></label>
                </div>
              </div>
            </div>
            <div className="row col-md-12 mb-3">
              <div className="col-md-10 offset-md-2 col-sm-10 offset-sm-2 col-lg-10 offset-lg-2">
                <div className="form-check" style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    checked={formik.values.isPermission}
                    name="isPermission"
                    onChange={(e) =>
                      formik.setFieldValue("isPermission", e.target.checked)
                    }
                    id="isPermission"
                  />
                  {"        "}
                  <strong style={{ marginLeft: "10px" }}>
                    Image View Permission
                  </strong>
                  {formik.touched.isPermission &&
                    formik.errors.isPermission && (
                      <div className="text-danger">
                        {formik.errors.isPermission}
                      </div>
                    )}
                  <label htmlFor="isPermission" className="form-label"></label>
                </div>
              </div>
            </div>
            <div className="card-footer margin_top10">
              <button type="submit" className="btn btn-primary mb-20px">
                {formik.values.user_id > 0 ? "Update" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <span>User Master List</span>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={departmentData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="70vh"
          ></AgGridComponent>
        </div>
      </div>

      {/* <div
        className={`modal fade ${isModalOpen ? "show d-block" : ""}`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={!isModalOpen}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">UserMachine</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsModalOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <tbody>
                {listOfMachine &&
                  listOfMachine.map((item) => (
                    <tr key={item.machineID}>
                      <td>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={item.machineID}
                          checked={machineIdList.some(
                            (machine) => machine.machineID === item.machineID
                          )}
                          onChange={() => handleMachineList(item.machineID)}
                        />
                        {"      "}
                        {item.machineNo}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="align-items-center btn btn-primary"
                onClick={InsertUserWithMachine}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>UserMachine</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Select</th>
                <th>Machine No</th>
              </tr>
            </thead>
            <tbody>
              {listOfMachine &&
                listOfMachine.map((item) => (
                  <tr key={item.machineID}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={item.machineID}
                        checked={machineIdList.some(
                          (machine) => machine.machineID === item.machineID
                        )}
                        onChange={() => handleMachineList(item.machineID)}
                       
                      />
                    </td>
                    <td>
                     {item.machineNo}
                      </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={InsertUserWithMachine}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={IsSubUserModelOpen}
        onHide={() => setIsSubUserModelOpen(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Sub User 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Select</th>
                <th>User Name</th>
                <th>Department Name</th>
              </tr>
            </thead>
            <tbody>
              {SubUserListOfHeadUser &&
                SubUserListOfHeadUser.map((item) => (
                  <tr key={item.SubUserID}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={item.SubUserID}
                        checked={SubUserIdList.some(
                          (subUser) => subUser.subUserID === item.SubUserID
                        )}
                        onChange={() => handleSubUserList(item.SubUserID)}
                       
                      />
                    </td>
                    <td> {item.SubUserName}</td>
                    <td>{item.DepartmentName}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={InsertUserWithSubUser}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
