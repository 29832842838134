import React from "react";
import QRCode from "react-qr-code";

export default function PrintSingleStoneDataEntry({ stoneDetail }) {
  return (
    <div
      style={{
        textAlign: "center",
        width: "206px",
        height: "141px",
        fontSize: "13px",
        color: "black",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>
        <strong style={{ fontSize: "13px"}}>
          {stoneDetail.stoneNo}{" "}
          {stoneDetail.colorMasterName
            ? `| ${stoneDetail.colorMasterName}`
            : ""}{" "}
          {stoneDetail.clarityMsaterName
            ? `| ${stoneDetail.clarityMsaterName}`
            : ""}
        </strong>
      </div>

      <span>
        <strong>Rough Weight:</strong>{" "}
        {stoneDetail.rW_Weight ? stoneDetail.rW_Weight.toFixed(2) : "0.00"}
      </span>

      <div style={{ display: "flex" }}>
        <div
          style={{
            justifyContent: "start",
            alignItems: "start",
            marginRight: "45px",
          }}
        >
          <div style={{ marginRight: "5px" }}>
            <strong>RD:</strong>{" "}
            {stoneDetail.r_Weight ? stoneDetail.r_Weight.toFixed(2) : "0.00"}
          </div>
          <div style={{ marginRight: "5px" }}>
            <strong>OV:</strong>{" "}
            {stoneDetail.oV_Weight ? stoneDetail.oV_Weight.toFixed(2) : "0.00"}
          </div>
          <div style={{ marginRight: "5px" }}>
            <strong>CU:</strong>{" "}
            {stoneDetail.cU_Weight ? stoneDetail.cU_Weight.toFixed(2) : "0.00"}
          </div>
          <div style={{ marginRight: "5px" }}>
            <strong>EM:</strong>{" "}
            {stoneDetail.eM_Weight ? stoneDetail.eM_Weight.toFixed(2) : "0.00"}
          </div>
          <div style={{ marginRight: "5px" }}>
            <strong>PN:</strong>{" "}
            {stoneDetail.pN_Weight ? stoneDetail.pN_Weight.toFixed(2) : "0.00"}
          </div>
        </div>

        <div
          style={{
            width: "80px",
            marginTop:"8px"
          }}
        >
          <QRCode
            style={{
              height: "auto",
              width: "100%", 
            }}
            value={stoneDetail.stoneNo}
          />
        </div>
      </div>
    </div>
  );
}
