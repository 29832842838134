import React, { useRef, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import api from "../../Utils/api";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import Swal from "sweetalert2";
import Loader from "../../Components/Common/Loader";

const emptyPartyName = {
  colorMaster_id: 0,
  colorMaster_Name: "",
};

export default function GenerateBag() {
  const [formData, setFormData] = useState(emptyPartyName);
  const [validated, setValidated] = useState(false);
  const CreateParty = useRef(null);
  const [colorMasterData, setColorMasterData] = useState([]);
  const [editColorMasterData, setEditColorMasterData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getColorMasterData();
  }, []);

  useEffect(() => {
    if (editColorMasterData) {
      setFormData({
        colorMaster_id: editColorMasterData.colorMastedID,
        colorMaster_Name: editColorMasterData.colorMasterName,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editColorMasterData]);

  const getColorMasterData = () => {
    api
      .get("ColorMaster/All")
      .then((result) => {
        setColorMasterData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const InsertColorMaster = (event) => {
    ;
    setValidated(true);
    if (event) {
      event.preventDefault();
    }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    } else {
      const data = {
        colorMastedID: formData?.colorMaster_id || 0,
        colorMasterName: formData.colorMaster_Name,
      };

      const endpoint =
        formData.colorMaster_id === 0
          ? "ColorMaster/InsertColorMaster"
          : "ColorMaster/EditColorMaster";

      api[formData.colorMaster_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            getColorMasterData(); // Refresh party data after successful insertion
            resetForm(); // Reset form after successful insertion
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
    }
  };

  const getColorMaster = (id) => {
    ;
    api
      .get(`ColorMaster/ByIDColorMaster?ColorMastedID=${id}`)
      .then((result) => {
        setEditColorMasterData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DeleteColorMaster = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        
        api
          .patch(`ColorMaster/DeleteColorMaster?ColorMasterID=${id}`) 
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getColorMasterData();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const resetForm = () => {
    setFormData(emptyPartyName);
    setValidated(false);
  };

  const columnDefs = [
    {
      headerName: "Color Name",
      field: "colorMasterName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Edit",
      width: 100,
      field: "colorMastedID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getColorMaster(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 80,
      field: "colorMastedID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => DeleteColorMaster(value)}
        ></ion-icon>
      ),
      pinned: "right",
    },
  ];

  return (
    <>
      {showLoader && <Loader />}
      <ToastContainer />
      <Card>
        <Card.Header>
          <span>Clarity Master</span>
        </Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={InsertColorMaster}
            ref={CreateParty}
          >
            <Row>
              <Col md={6}>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <Form.Group controlId="PartyName">
                      <Form.Label style={{ marginRight: "20px" }}>
                        Clarity Name :
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="text"
                        name="colorMaster_Name"
                        placeholder="Enter ClarityName"
                        value={formData.colorMaster_Name}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value Color
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                {formData?.colorMaster_id > 0 ? "Update" : "Save"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <span>Color Data</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={colorMasterData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="55vh"
          />

        </Card.Body>
      </Card>
    </>
  );
}
