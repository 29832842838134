import { React, useState, useEffect } from "react";
import Swal from "sweetalert2";
import api from "../../Utils/api";
import { getCurrentDateTime, formatDate } from "../../Utils/commonfunctions";
import Spinner from "react-bootstrap/Spinner";
import { ISOToDateTime } from "../../Components/Common/Common";
import { addDecimalPlaces } from "../../Utils/commonfunctions";
import * as XLSX from "xlsx";

const MixStoneReport = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [mixStoneSourceData, setMixStoneSourceData] = useState([]);
  const [mixStoneGeneratedData, setMixStoneGeneratedData] = useState([]);
  const [chocolateDataList, setChocolateDataList] = useState([]);
  const [searchMixNo, setSearchMixNo] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [dlcData, setDLCData] = useState([]);
  const [lossData, setLossData] = useState([]);

  useEffect(() => {
    setStartDate(getCurrentDateTime);
    setEndDate(getCurrentDateTime);
    setMixStoneSourceData([]);
    setMixStoneGeneratedData([]);
    setDLCData([]);
    setLossData([]);
    setSearchMixNo("");
  }, []);

  const PrintReportData = () => {
    PrintMixStoneSourceReport();
  };

  //Data Start For Mix Stone Source
  const PrintMixStoneSourceReport = () => {
    debugger
    setIsLoading(true); // Show loader
    if (startDate === "" || startDate === null) {
      return Swal.fire("Error", "Please Select Start Date", "error");
    }

    if (endDate === "" || endDate === null) {
      return Swal.fire("Error", "Please Select End Date", "error");
    }

    // Reset the data and loader for each search
    setMixStoneSourceData([]);
    setDLCData([]);
    setLossData([]);

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    api
      .get(
        `Report/MixStoneReport?START_DATE=${formattedStartDate}&END_DATE=${formattedEndDate}&MixNO=${searchMixNo}`
      )
      .then((result) => {
        ;
        const groupBySourcesList = result.data.data.groupBySourcesList;
        const groupByGeneratedList = result.data.data.groupByGeneratedList;

        const chocolateList = result.data.data.chocolateData;

        setMixStoneSourceData(groupBySourcesList);
        setMixStoneGeneratedData(groupByGeneratedList);

        setChocolateDataList(chocolateList);

        const dlcData = result.data.data.finalDlc;
        const updateDLCData = dlcData.map((item) => ({
          ...item,
          batchNo: item.batchNo,
          departmentName: item.departmentName,
          finalTotalDLC: item.finalTotalDLC,
        }));
        setDLCData((oldList) => [...oldList, ...updateDLCData]);

        const lossData = result.data.data.finalLoss;

        const updateLossData = lossData.map((item) => ({
          ...item,
          batchNo: item.batchNo,
          departmentName: item.departmentName,
          finalTotalLOSS: item.finalTotalLOSS,
        }));
        setLossData((oldList) => [...oldList, ...updateLossData]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };
  //End New Code

  const MixStoneSourceIsVerify = (MNO) => {
    if (MNO === 0 && MNO === null) {
      return Swal.fire("Error", "Mix No is 0 here", "error");
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be Verify This Record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post(`Report/UpdateMixStoneSourceVerify?MNO=${MNO}`)
          .then((result) => {
            if (result.data.statusCode === 200) {
              PrintMixStoneSourceReport();
            } else {
              Swal.fire(result.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const SearchMixStoneData = (e) => {
    if (e.key === "Enter") {
      PrintMixStoneSourceReport();
    }
  };

  const exportToExcelSummaryReport = () => {
    const fileName = "SummaryData.xlsx";

    // Extracting table data
    const table = document.getElementById("summaryTable");
    const headers = Array.from(table.querySelectorAll("thead th")).map((th) =>
      th.textContent.trim()
    );
    const rows = Array.from(table.querySelectorAll("tbody tr")).map((row) =>
      Array.from(row.querySelectorAll("td")).map((td) => td.textContent.trim())
    );
    const wsData = [headers, ...rows];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Summary");
    XLSX.writeFile(wb, fileName);
  };

  //Mix Source Data
  const extractTableData = (tableElement) => {
    const tableRows = tableElement.querySelectorAll("tr");
    const data = [];
    let totalColumnsCovered = 0;
    let grandTotalColumnsCovered = 0;
    let sourceMixColumnsCovered = 0;
    let generatedMixColumnsCovered = 0;

    // Extract data from each row
    tableRows.forEach((row) => {
      const rowData = [];
      const cells = row.querySelectorAll("th, td");
      cells.forEach((cell) => {
        const colspan = parseInt(cell.getAttribute("colspan")) || 1;
        const cellData = cell.textContent.trim();

        // Check if the cell is part of a "Total" row
        if (cellData === "Total") {
          for (let i = 0; i < colspan; i++) {
            if (totalColumnsCovered === colspan - 1) {
              rowData.push(cellData);
            } else {
              rowData.push("");
            }
            totalColumnsCovered++;
          }
        }
        // Check if the cell is part of a "Grand Total" row
        else if (cellData === "Grand Total") {
          for (let i = 0; i < colspan; i++) {
            if (grandTotalColumnsCovered === colspan - 1) {
              rowData.push(cellData);
            } else {
              rowData.push("");
            }
            grandTotalColumnsCovered++;
          }
        }
        // Check if the cell is part of a "Source Mix" row
        else if (cellData === "Source Mix") {
          for (let i = 0; i < colspan; i++) {
            if (sourceMixColumnsCovered === 0) {
              rowData.push(cellData);
            } else {
              rowData.push("");
            }
            sourceMixColumnsCovered++;
          }
        }
        // Check if the cell is part of a "Generated Mix" row
        else if (cellData === "Generated Mix") {
          for (let i = 0; i < colspan; i++) {
            if (generatedMixColumnsCovered === 0) {
              rowData.push(cellData);
            } else {
              rowData.push("");
            }
            generatedMixColumnsCovered++;
          }
        }
        // For other cells
        else {
          for (let i = 0; i < colspan; i++) {
            rowData.push(cellData);
          }
        }
      });
      data.push(rowData);
    });

    return data;
  };

  // Function to export table data to Excel
  const exportToExcel = (tableData, fileName) => {
    const flattenedData = tableData.flat();
    const worksheet = XLSX.utils.aoa_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleExport = () => {
    const mixStoneTableElements = document.querySelectorAll("#mixStoneTable");
    const generatedMixTableElements =
      document.querySelectorAll("#generatedMixTable");

    const mixStoneTableDataArray = [];
    const generatedMixTableDataArray = [];
    const combinedDataArray = [];
    const sheetNamesArray = [];

    mixStoneTableElements.forEach((mixStoneTableElement, index) => {
      const mixStoneTableData = extractTableData(mixStoneTableElement);
      mixStoneTableDataArray.push(mixStoneTableData);

      const sheetName = "Mix Stone Table " + (index + 1);
      sheetNamesArray.push(sheetName);
    });

    generatedMixTableElements.forEach((generatedMixTableElement, idx) => {
      const generatedMixTableData = extractTableData(generatedMixTableElement);
      generatedMixTableDataArray.push(generatedMixTableData);
    });

    mixStoneTableDataArray.forEach((mixStoneTableData, index) => {
      const combinedRow = mixStoneTableData.map((sourceMixRow, rowIndex) => {
        return sourceMixRow.concat(
          generatedMixTableDataArray[index][rowIndex] ||
          Array(generatedMixTableDataArray[index][0].length).fill("")
        ); // Fill with empty strings if generated mix row is shorter
      });
      combinedDataArray.push(combinedRow);
    });
    exportToExcel(combinedDataArray, "MixStoneReportData");
  };

  // LOSS CALCULATIONS START

  const calculateFinalCalculation = ({ group, chocolateDataList, dlcData }) => {
    // Calculate totalWeightSum
    const totalWeightSum = chocolateDataList.reduce((total, chocolateItem) => {
      if (group.some((item) => item.batchNo === chocolateItem.stoneID)) {
        return total + (chocolateItem.totalWeight || 0);
      }
      return total;
    }, 0);

    // Calculate totalOutWeight
    const totalOutWeight = group.reduce(
      (sum, item) => sum + (item.isOut ? parseFloat(item.weight) : 0),
      0
    );

    // Calculate totalFinalWeight
    const totalFinalWeight = group.reduce(
      (sum, item) => sum + (item.isFinal ? parseFloat(item.weight) : 0),
      0
    );

    // Calculate totalDLC
    const totalDLC = dlcData.reduce((sum, item) => {
      if (group.some((batch) => batch.batchNo === item.batchNo)) {
        return sum + item.finalTotalDLC;
      }
      return sum;
    }, 0);

    // Calculate totalWeight
    const totalWeight = group.reduce(
      (sum, item) =>
        sum + (!item.isOut && !item.isFinal ? parseFloat(item.weight) : 0),
      0
    );

    // Final calculation
    const finalCalculation =
      totalWeightSum -
      totalFinalWeight -
      totalOutWeight -
      totalDLC -
      totalWeight;

    return addDecimalPlaces(finalCalculation, 2);
  };
  // LOSS CALCULATION END

  //Summary Loss Calculation Start
  // const calculateSummaryLossCalculation = ({
  //   lossData,
  //   mixStoneSourceData,
  //   dlcData,
  // }) => {
  //   // Calculate total growth stone weight
  //   const totalGrowthStoneWeight = mixStoneSourceData
  //     .flat()
  //     .map((item) => item.growthStoneWeight || 0)
  //     .reduce((sum, value) => sum + value, 0);

  //   // Calculate total final weight of stones
  //   const totalFinalWeight = mixStoneSourceData
  //     .flat()
  //     .filter((item) => item.isFinal === true)
  //     .reduce((sum, item) => sum + (parseFloat(item.weight) || 0), 0);

  //   // Calculate total weight of stones out
  //   const totalOutWeight = mixStoneSourceData
  //     .flat()
  //     .filter((item) => item.isOut === true)
  //     .reduce((sum, item) => sum + (parseFloat(item.weight) || 0), 0);

  //   // Calculate total DLC
  //   const totalDLC = dlcData
  //     .flat()
  //     .map((item) => item.finalTotalDLC || 0)
  //     .reduce((sum, value) => sum + value, 0);

  //   // Calculate total weight of all stones
  //   const totalStoneWeight = mixStoneSourceData
  //     .flat()
  //     .map((item) => item.weight || 0)
  //     .reduce((sum, value) => sum + value, 0);

  //   // Final calculation
  //   const finalCalculation =
  //     totalGrowthStoneWeight -
  //     totalFinalWeight -
  //     totalOutWeight -
  //     totalDLC -
  //     totalStoneWeight;

  //   return addDecimalPlaces(finalCalculation, 2);
  // };
  //Summary Loss Calculation End

  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <div>
            <span>Mix Stone Report</span>
          </div>
          <div>
            <input
              type="number"
              className="form-control"
              id="searchMicNo"
              placeholder="Search Mix No"
              value={searchMixNo}
              onChange={(e) => {
                setSearchMixNo(e.target.value);
                // PrintMixStoneSourceReport();
              }}
              onKeyDown={SearchMixStoneData}
            />
          </div>
        </div>
        <div className="card-body">
          <div
            className="row col-md-12 mb-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="machineStartDate">Start Date:</strong>
              <input
                type="datetime-local"
                className="form-control"
                id="machineStartDate"
                placeholder="Enter Machine Start Time"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="machineEndDate">End Date:</strong>
              <input
                type="datetime-local"
                className="form-control"
                id="machineEndDate"
                placeholder="Enter Machine Start Time"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <br />
              <button
                type="submit"
                className="btn btn-primary"
                onClick={PrintReportData}
              >
                <i class="fa fa-search"></i>
                {"  "}
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <span>Summary Report</span>
        </div>
        <div className="card-body">
          {mixStoneSourceData.length > 0 && (
            <div style={{ display: "flex", justifyContent: "start" }}>
              <button
                className="btn btn-primary"
                onClick={exportToExcelSummaryReport}
              >
                <i class="fa fa-file-excel"></i> Export to Excel
              </button>
            </div>
          )}
          <div className="row">
            <div
              className="col-md-12 mt-2 summaryTable"
              style={{ overflowX: "auto" }}
            >
              {mixStoneSourceData.length > 0 && (
                <>
                  <table className="table table-bordered" id="summaryTable">
                    <thead>
                      <tr>
                        <th>G.Pcs</th>
                        <th>G.Rate</th>
                        <th>G.Height</th>
                        <th>G.Weight</th>
                        <th>F</th>
                        <th>F. W</th>
                        <th>O</th>
                        <th>O. W</th>
                        <th>Pcs</th>
                        <th>DLC</th>
                        <th>Loss</th>
                        <th>Weight</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ fontWeight: "bold" }}>
                        {/* <td>
                          {mixStoneSourceData
                            .flat()
                            .map((item) => {
                              return item.originPCS || 0;
                            })
                            .reduce((sum, value) => {
                              return sum + value;
                            }, 0)}
                        </td> */}
                        <td className="fw-bold">
                          {addDecimalPlaces(
                            chocolateDataList.reduce((sum, item) => {
                              return (
                                sum +
                                (item.totalPcs ? parseFloat(item.totalPcs) : 0)
                              );
                            }, 0),
                            2
                          )}
                        </td>
                        <td>
                          {(
                            Object.values(
                              mixStoneSourceData
                                .flat()
                                .filter((item) => item.originPCS > 0)
                                .reduce((acc, item) => {
                                  if (!acc[item.mNo]) {
                                    acc[item.mNo] = {
                                      sum: 0,
                                      count: 0,
                                    };
                                  }
                                  acc[item.mNo].sum +=
                                    parseFloat(item.machineGrowthRate) || 0;
                                  acc[item.mNo].count++;
                                  return acc;
                                }, {})
                            ).reduce(
                              (sum, { sum: groupSum, count }) =>
                                sum + groupSum / count,
                              0
                            ) /
                            Object.keys(
                              mixStoneSourceData
                                .flat()
                                .filter((item) => item.originPCS > 0)
                                .reduce((acc, item) => {
                                  if (!acc[item.mNo]) {
                                    acc[item.mNo] = {
                                      sum: 0,
                                      count: 0,
                                    };
                                  }
                                  acc[item.mNo].sum +=
                                    parseFloat(item.machineGrowthRate) || 0;
                                  acc[item.mNo].count++;
                                  return acc;
                                }, {})
                            ).length
                          ).toFixed(2)}
                        </td>
                        <td>
                          {(
                            mixStoneSourceData.flat().reduce((sum, item) => {
                              return (
                                sum + (parseFloat(item.growthStoneHeight) || 0)
                              );
                            }, 0) /
                            mixStoneSourceData
                              .flat()
                              .map((item) => item.originPCS || 0)
                              .reduce((sum, value) => sum + value, 0)
                          ).toFixed(2)}
                        </td>

                        {/* <td>
                          {addDecimalPlaces(mixStoneSourceData
                            .flat()
                            .map((item) => item.growthStoneWeight || 0)
                            .reduce((sum, value) => sum + value, 0), 2)}
                        </td> */}

                        {/* <td>
                          {addDecimalPlaces(
                            mixStoneSourceData
                              .flat()
                              .reduce((sum, item) => {
                                const value = parseFloat(item.growthStoneWeight);
                                return !isNaN(value) ? sum + value : sum;
                              }, 0),
                            2
                          )}
                        </td> */}
                       <td className="fw-bold">
                          {addDecimalPlaces(
                            chocolateDataList.reduce((sum, item) => {
                              return (
                                sum +
                                (item.totalWeight ? parseFloat(item.totalWeight) : 0)
                              );
                            }, 0),
                            2
                          )}
                        </td>


                        <td>
                          {
                            mixStoneSourceData.flat().filter((item) => {
                              return item.isFinal === true;
                            }).length
                          }
                        </td>
                        <td>
                          {mixStoneSourceData
                            .flat()
                            .filter((item) => item.isFinal === true)
                            .reduce(
                              (sum, item) =>
                                sum + (parseFloat(item.weight) || 0),
                              0
                            )
                            .toFixed(2)}
                        </td>
                        <td>
                          {
                            mixStoneSourceData
                              .flat()
                              .filter((item) => item.isOut === true).length
                          }
                        </td>
                        <td>
                          {mixStoneSourceData
                            .flat()
                            .filter((item) => item.isOut === true)
                            .reduce(
                              (sum, item) =>
                                sum + (parseFloat(item.weight) || 0),
                              0
                            )
                            .toFixed(2)}
                        </td>
                        <td>
                          {mixStoneSourceData
                            .flat()
                            .map((item) => item.pcs || 0)
                            .reduce((sum, value) => sum + value, 0)
                            .toFixed(2)}
                        </td>

                        {/* <td>
                          {mixStoneSourceData
                            .flat()
                            .map((item) => item.finalTotalDLC || 0)
                            .reduce((sum, value) => sum + value, 0)
                            .toFixed(2)}
                        </td>
                        <td>
                          {mixStoneSourceData
                            .flat()
                            .map((item) => item.finalTotalLOSS || 0)
                            .reduce((sum, value) => sum + value, 0)
                            .toFixed(2)}
                        </td> */}
                        <td>
                          {dlcData
                            .flat()
                            .map((item) => item.finalTotalDLC || 0)
                            .reduce((sum, value) => sum + value, 0)
                            .toFixed(2)}
                        </td>
                        {/* <td>
                          {lossData
                            .flat()
                            .map((item) => item.finalTotalLOSS || 0)
                            .reduce((sum, value) => sum + value, 0)
                            .toFixed(2)}
                        </td> */}
                        {/* <td>
                          {calculateSummaryLossCalculation({
                            lossData,
                            mixStoneSourceData,
                            dlcData,
                          })}
                        </td> */}
                        {/* <td>
                          {mixStoneSourceData.reduce(
                            (sum, group) =>
                              sum +
                              calculateFinalCalculation({
                                group,
                                chocolateDataList,
                                dlcData,
                              }),
                            0
                          )}
                        </td> */}
                        <td>
                          {addDecimalPlaces(
                            mixStoneSourceData.reduce(
                              (sum, group) =>
                                sum +
                                calculateFinalCalculation({
                                  group,
                                  chocolateDataList,
                                  dlcData,
                                }),
                              0
                            ),
                            2 // Specify the number of decimal places
                          )}
                        </td>
                        <td>
                          {mixStoneSourceData
                            .flat()
                            .map((item) => item.weight || 0)
                            .reduce((sum, value) => sum + value, 0)
                            .toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        {isLoading && (
          <>
            <div className="overlay"></div>
            <div className="overlay-content text-center">
              <Spinner animation="" variant="primary">
                <span class="loader"></span>
              </Spinner>
            </div>
          </>
        )}

        <div className="card-header">
          <span>Mix Stone Report</span>
        </div>
        <div className="card-body">
          <>
            {mixStoneSourceData.length > 0 && (
              <div style={{ display: "flex", justifyContent: "start" }}>
                <button className="btn btn-primary" onClick={handleExport}>
                  <i class="fa fa-file-excel"></i> Export to Excel
                </button>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="card-body table-responsive mixStoneReport">
                  {mixStoneSourceData &&
                    mixStoneSourceData.map((group, groupIndex) => (
                      <>
                        <div
                          className="row mt-4"
                          style={{ border: "2px solid #2a3367" }}
                        >
                          <div className="d-flex mixTableHeader">
                            <strong className="align-self-center">
                              Mix No : {group[0].mNo}
                            </strong>
                            <strong className="align-self-center">
                              Date : {ISOToDateTime(group[0].createdDatetime)}
                            </strong>
                            <div>
                              {group[0].isVerify ? (
                                <div className="fw-bold">Verified</div>
                              ) : (
                                <button
                                  className="btn btn-info float-right fw-bold"
                                  onClick={() =>
                                    MixStoneSourceIsVerify(group[0].mNo)
                                  }
                                >
                                  Verify
                                </button>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-md-8 mt-2"
                            style={{ overflowX: "auto" }}
                          >
                            <table
                              className="table table-bordered"
                              id="mixStoneTable"
                            >
                              <thead>
                                <tr>
                                  <th colSpan={15} className="text-center">
                                    Source Mix
                                  </th>
                                </tr>
                                <tr>
                                  <th>Sr.No.</th>
                                  <th>Batch No</th>
                                  <th>Type</th>
                                  <th>Pcs</th>
                                  <th>Rate</th>
                                  <th>Height</th>
                                  <th>Weight</th>
                                  <th>F</th>
                                  <th>F. W</th>
                                  <th>O</th>
                                  <th>O. W</th>
                                  <th>Pcs</th>
                                  <th>DLC</th>
                                  <th>Loss</th>
                                  <th>Weight</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.from(
                                  new Set(group.map((item) => item.batchNo))
                                ).map((batchNo, index) => {
                                  const batchItems = group.filter(
                                    (item) => item.batchNo === batchNo
                                  );

                                  const totalPcs = batchItems.reduce(
                                    (sum, item) => sum + item.pcs,
                                    0
                                  );

                                  const totalWeight = batchItems.reduce(
                                    (sum, item) =>
                                      sum +
                                      (!item.isOut && !item.isFinal
                                        ? parseFloat(item.weight)
                                        : 0),
                                    0
                                  );
                                  const totalWeightPerBatch = [];
                                  batchItems.forEach((batchItem) => {
                                    const chocolateItem =
                                      chocolateDataList.find(
                                        (item) =>
                                          batchItem.batchNo === item.stoneID
                                      );

                                    if (chocolateItem) {
                                      totalWeightPerBatch.push(
                                        addDecimalPlaces(
                                          chocolateItem.totalWeight,
                                          2
                                        )
                                      );
                                    } else {
                                      totalWeightPerBatch.push(0);
                                    }
                                  });

                                  const totalFinalWeight = batchItems.reduce(
                                    (sum, item) =>
                                      sum +
                                      (item.isFinal
                                        ? parseFloat(item.weight)
                                        : 0),
                                    0
                                  );
                                  const totalOutWeight = batchItems.reduce(
                                    (sum, item) =>
                                      sum +
                                      (item.isOut
                                        ? parseFloat(item.weight)
                                        : 0),
                                    0
                                  );

                                  const countFinal = batchItems.filter(
                                    (item) => item.isFinal
                                  ).length;

                                  const countOut = batchItems.filter(
                                    (item) => item.isOut
                                  ).length;

                                  const totalPcsPerBatch = [];
                                  batchItems.forEach((batchItem) => {
                                    const chocolateItem =
                                      chocolateDataList.find(
                                        (item) =>
                                          item.stoneID === batchItem.batchNo
                                      );
                                    if (chocolateItem) {
                                      totalPcsPerBatch.push(
                                        chocolateItem.totalPcs || 0
                                      );
                                    } else {
                                      totalPcsPerBatch.push(0);
                                    }
                                  });

                                  const filteredBatchItems = batchItems.filter(
                                    (item) =>
                                      parseFloat(item.machineGrowthRate) > 0
                                  );

                                  // const totalOriginPCS =
                                  //   filteredBatchItems.length !== 0
                                  //     ? batchItems.reduce((sum, item) => {
                                  //         if (
                                  //           parseFloat(item.originPCS) > 0 &&
                                  //           parseFloat(item.originPCS) > 0
                                  //         ) {
                                  //           return (
                                  //             sum + parseFloat(item.originPCS)
                                  //           );
                                  //         }
                                  //         return sum;
                                  //       }, 0)
                                  //     : 0;

                                  const totalMachineGrowthRate =
                                    filteredBatchItems.length !== 0
                                      ? batchItems.reduce((sum, item) => {
                                        if (
                                          parseFloat(item.machineGrowthRate) >
                                          0
                                        ) {
                                          return (
                                            sum +
                                            parseFloat(item.machineGrowthRate)
                                          );
                                        }
                                        return sum;
                                      }, 0) / filteredBatchItems.length
                                      : 0;
                                  // const totalGrowthStoneWeight =
                                  //   batchItems.reduce(
                                  //     (sum, item) =>
                                  //       sum +
                                  //       (item.growthStoneWeight
                                  //         ? parseFloat(item.growthStoneWeight)
                                  //         : 0),
                                  //     0
                                  //   );
                                  const totalGrowthStoneHeight =
                                    batchItems.reduce(
                                      (sum, item) =>
                                        sum +
                                        (item.growthStoneHeight
                                          ? parseFloat(item.growthStoneHeight)
                                          : 0),
                                      0
                                    );
                                  const averageMachineGrowthHeight =
                                    totalGrowthStoneHeight > 0
                                      ? totalGrowthStoneHeight /
                                      batchItems.filter(
                                        (item) =>
                                          parseFloat(item.growthStoneHeight) >
                                          0
                                      ).length
                                      : 0;
                                  //DLC
                                  const batchTotalDLC = dlcData
                                    .filter(
                                      (iteminner) =>
                                        iteminner.batchNo === batchNo
                                    )
                                    .reduce(
                                      (total, iteminner) =>
                                        total + iteminner.finalTotalDLC,
                                      0
                                    );
                                  //Loss
                                  // const batchTotalLoss = lossData
                                  //   .filter(
                                  //     (itemLoss) => itemLoss.batchNo === batchNo
                                  //   )
                                  //   .reduce(
                                  //     (total, itemLoss) =>
                                  //       total + itemLoss.finalTotalLOSS,
                                  //     0
                                  //   );
                                  return (
                                    <tr key={index}>
                                      <td className="text-center">
                                        {index + 1}
                                      </td>
                                      <td>{batchNo}</td>
                                      <td>
                                        {batchItems.length > 0
                                          ? batchItems[0].stoneType
                                          : ""}
                                      </td>
                                      {/* <td>{totalOriginPCS}</td> */}
                                      <td>{totalPcsPerBatch[0]}</td>
                                      <td>
                                        {totalMachineGrowthRate.toFixed(2)}
                                      </td>
                                      <td>
                                        {averageMachineGrowthHeight.toFixed(2)}
                                      </td>
                                      <td>{totalWeightPerBatch[0]}</td>

                                      {/* <td>
                                        {totalGrowthStoneWeight.toFixed(2)}
                                      </td> */}
                                      <td>{countFinal}</td>
                                      <td>{totalFinalWeight.toFixed(2)}</td>
                                      <td>{countOut}</td>
                                      <td>{totalOutWeight.toFixed(2)}</td>
                                      <td>{totalPcs}</td>

                                      <td>
                                        {batchTotalDLC > 0 && (
                                          <div>
                                            <span>
                                              {addDecimalPlaces(
                                                batchTotalDLC,
                                                2
                                              )}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                      {/* <td>
                                        {batchTotalLoss < 0 && (
                                          <div>
                                            <span>
                                              {addDecimalPlaces(
                                                batchTotalLoss,
                                                2
                                              )}
                                            </span>
                                          </div>
                                        )}
                                      </td> */}
                                      <td>
                                        <div>
                                          <span>
                                            {addDecimalPlaces(
                                              totalWeightPerBatch[0] -
                                              totalFinalWeight -
                                              totalOutWeight -
                                              batchTotalDLC -
                                              totalWeight,
                                              2
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{totalWeight.toFixed(2)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={3} className="text-end fw-bold">
                                    Total
                                  </td>
                                  {/* <td className="fw-bold">
                                    {group.reduce(
                                      (sum, item) =>
                                        sum +
                                        (item.originPCS > 0
                                          ? item.originPCS
                                          : 0),
                                      0
                                    )}
                                  </td> */}
                                  <td className="fw-bold">
                                    {addDecimalPlaces(
                                      (() => {
                                        let totalPcsSum = 0;
                                        for (
                                          let i = 0;
                                          i < chocolateDataList.length;
                                          i++
                                        ) {
                                          const chocolateItem =
                                            chocolateDataList[i];
                                          if (
                                            group.some(
                                              (item) =>
                                                item.batchNo ===
                                                chocolateItem.stoneID
                                            )
                                          ) {
                                            totalPcsSum +=
                                              chocolateItem.totalPcs || 0;
                                          }
                                        }
                                        return totalPcsSum;
                                      })(),
                                      2
                                    )}
                                  </td>
                                  <td className="fw-bold">
                                    {addDecimalPlaces(
                                      group.filter(
                                        (item) =>
                                          parseFloat(item.machineGrowthRate) > 0
                                      ).length > 0
                                        ? group.reduce(
                                          (sum, item) =>
                                            sum +
                                            (parseFloat(
                                              item.machineGrowthRate
                                            ) > 0
                                              ? parseFloat(
                                                item.machineGrowthRate
                                              )
                                              : 0),
                                          0
                                        ) /
                                        group.filter(
                                          (item) =>
                                            item.machineGrowthRate > 0
                                        ).length
                                        : 0,
                                      2
                                    )}
                                  </td>
                                  <td className="fw-bold">
                                    {group.filter(
                                      (item) =>
                                        parseFloat(item.growthStoneHeight) > 0
                                    ).length !== 0
                                      ? addDecimalPlaces(
                                        group.reduce(
                                          (sum, item) =>
                                            sum +
                                            (item.growthStoneHeight
                                              ? parseFloat(
                                                item.growthStoneHeight
                                              )
                                              : 0),
                                          0
                                        ) /
                                        group.filter(
                                          (item) =>
                                            parseFloat(
                                              item.growthStoneHeight
                                            ) > 0
                                        ).length,
                                        2
                                      )
                                      : 0}
                                  </td>
                                  <td className="fw-bold">
                                    {addDecimalPlaces(
                                      (() => {
                                        let totalWeightSum = 0;
                                        for (
                                          let i = 0;
                                          i < chocolateDataList.length;
                                          i++
                                        ) {
                                          const chocolateItem =
                                            chocolateDataList[i];
                                          if (
                                            group.some(
                                              (item) =>
                                                item.batchNo ===
                                                chocolateItem.stoneID
                                            )
                                          ) {
                                            totalWeightSum +=
                                              chocolateItem.totalWeight || 0;
                                          }
                                        }
                                        return totalWeightSum;
                                      })(),
                                      2
                                    )}
                                  </td>
                                  {/* <td className="fw-bold">
                                    {addDecimalPlaces(
                                      group.reduce(
                                        (sum, item) =>
                                          sum +
                                          (item.growthStoneWeight
                                            ? parseFloat(item.growthStoneWeight)
                                            : 0),
                                        0
                                      ),
                                      2
                                    )}
                                  </td> */}

                                  <td className="fw-bold">
                                    {group.reduce(
                                      (sum, item) =>
                                        sum + (item.isFinal ? 1 : 0),
                                      0
                                    )}
                                  </td>
                                  <td className="fw-bold">
                                    {addDecimalPlaces(
                                      group.reduce(
                                        (sum, item) =>
                                          sum +
                                          (item.isFinal
                                            ? parseFloat(item.weight)
                                            : 0),
                                        0
                                      ),
                                      2
                                    )}
                                  </td>
                                  <td className="fw-bold">
                                    {group.reduce(
                                      (sum, item) => sum + (item.isOut ? 1 : 0),
                                      0
                                    )}
                                  </td>
                                  <td className="fw-bold">
                                    {addDecimalPlaces(
                                      group.reduce(
                                        (sum, item) =>
                                          sum +
                                          (item.isOut
                                            ? parseFloat(item.weight)
                                            : 0),
                                        0
                                      ),
                                      2
                                    )}
                                  </td>
                                  <td className="fw-bold">
                                    {group.reduce(
                                      (sum, item) => sum + item.pcs,
                                      0
                                    )}
                                  </td>
                                  {/* <td className="fw-bold">
                                    {calculateTotalPcs(group)}
                                  </td> */}

                                  <td className="fw-bold">
                                    {addDecimalPlaces(
                                      dlcData
                                        .filter((item) =>
                                          group.some(
                                            (batch) =>
                                              batch.batchNo === item.batchNo
                                          )
                                        )
                                        .reduce(
                                          (sum, item) =>
                                            sum + item.finalTotalDLC,
                                          0
                                        ),
                                      2
                                    )}
                                  </td>
                                  {/* <td className="fw-bold">
                                    {addDecimalPlaces(
                                      lossData
                                        .filter((item) =>
                                          group.some(
                                            (batch) =>
                                              batch.batchNo === item.batchNo
                                          )
                                        )
                                        .reduce(
                                          (sum, item) =>
                                            sum + item.finalTotalLOSS,
                                          0
                                        ),
                                      2
                                    )}
                                  </td> */}
                                  <td className="fw-bold">
                                    {calculateFinalCalculation({
                                      group,
                                      chocolateDataList,
                                      dlcData,
                                    })}
                                  </td>
                                  <td className="fw-bold">
                                    {addDecimalPlaces(
                                      group.reduce(
                                        (sum, item) =>
                                          sum +
                                          (!item.isOut && !item.isFinal
                                            ? parseFloat(item.weight)
                                            : 0),
                                        0
                                      ),
                                      2
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={14} className="text-end fw-bold">
                                    Grand Total
                                  </td>
                                  {/* <td className="fw-bold">
                                    {addDecimalPlaces(
                                      (() => {
                                        let totalWeightSum = 0;
                                        for (
                                          let i = 0;
                                          i < chocolateDataList.length;
                                          i++
                                        ) {
                                          const chocolateItem =
                                            chocolateDataList[i];
                                          if (
                                            group.some(
                                              (item) =>
                                                item.batchNo ===
                                                chocolateItem.stoneID
                                            )
                                          ) {
                                            totalWeightSum +=
                                              chocolateItem.totalWeight || 0;
                                          }
                                        }
                                        const sumOfWeights = group.reduce(
                                          (sum, item) => {
                                            return (
                                              sum +
                                              (item.isFinal
                                                ? parseFloat(item.weight)
                                                : 0) +
                                              (item.isOut
                                                ? parseFloat(item.weight)
                                                : 0)
                                            );
                                          },
                                          0
                                        );
                                        totalWeightSum += sumOfWeights;
                                        return totalWeightSum;
                                      })(),
                                      2
                                    )}
                                  </td> */}

                                  <td className="fw-bold">
                                    {addDecimalPlaces(
                                      group.reduce(
                                        (sum, item) =>
                                          sum +
                                          (!item.isOut && !item.isFinal
                                            ? parseFloat(item.weight)
                                            : 0) +
                                          (item.isFinal
                                            ? parseFloat(item.weight)
                                            : 0) +
                                          (item.isOut
                                            ? parseFloat(item.weight)
                                            : 0),
                                        0
                                      ),
                                      2
                                    )}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div className="col-md-4 mt-2">
                            <table
                              className="table table-bordered"
                              id="generatedMixTable"
                            >
                              <thead>
                                <tr>
                                  <th colSpan={5} className="text-center">
                                    Generated Mix
                                  </th>
                                </tr>
                                <tr>
                                  <th>Sr.No.</th>
                                  <th>Mix No</th>
                                  <th>Stone Type</th>
                                  <th>Pcs</th>
                                  <th>Weight</th>
                                </tr>
                              </thead>
                              <tbody>
                                {mixStoneGeneratedData &&
                                  mixStoneGeneratedData
                                    .filter(
                                      (groupGeneratedData) =>
                                        groupGeneratedData[0].mNo ===
                                        group[0].mNo
                                    )
                                    .map(
                                      (
                                        filteredGroup,
                                        groupIndexGeneratedData
                                      ) =>
                                        filteredGroup.map((item, index) => (
                                          <tr key={index}>
                                            <td className="text-center">
                                              {index + 1}
                                            </td>

                                            <td>{item.mixNo}</td>
                                            <td>{item.stoneType}</td>
                                            <td>{item.pcs}</td>
                                            <td>
                                              {parseFloat(item.weight).toFixed(
                                                2
                                              )}
                                            </td>
                                          </tr>
                                        ))
                                    )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={3} className="text-end fw-bold">
                                    Total
                                  </td>
                                  <td className="fw-bold">
                                    {mixStoneGeneratedData &&
                                      mixStoneGeneratedData
                                        .filter(
                                          (groupGeneratedData) =>
                                            groupGeneratedData[0].mNo ===
                                            group[0].mNo
                                        )
                                        .reduce(
                                          (sum, group) =>
                                            group.reduce(
                                              (total, item) => total + item.pcs,
                                              0
                                            ) + sum,
                                          0
                                        )}
                                  </td>
                                  <td className="fw-bold">
                                    {mixStoneGeneratedData &&
                                      addDecimalPlaces(
                                        mixStoneGeneratedData
                                          .filter(
                                            (groupGeneratedData) =>
                                              groupGeneratedData[0].mNo ===
                                              group[0].mNo
                                          )
                                          .reduce(
                                            (sum, group) =>
                                              group.reduce(
                                                (total, item) =>
                                                  total + item.weight,
                                                0
                                              ) + sum,
                                            0
                                          ),
                                        2
                                      )}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default MixStoneReport;
