import React, { Component } from 'react';

class TableWithInputFocus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        { id: 1, name: 'John', age: 25 },
        { id: 2, name: 'Alice', age: 30 },
        { id: 3, name: 'Bob', age: 35 },
        // Add more rows as needed
      ],
      focusedRow: null,
      focusedColumn: null,
    };

    this.inputRefs = []; // Initialize as a two-dimensional array
  }

  componentDidMount() {
    // Create refs for each input element
    this.state.data.forEach((row) => {
      this.inputRefs[row.id] = [];
      Object.keys(row).forEach((key, index) => {
        if (key !== 'id') {
          this.inputRefs[row.id][index] = React.createRef();
        }
      });
    });
  }

  handleKeyPress = (event) => {
    const { focusedRow, focusedColumn } = this.state;
    const numRows = this.state.data.length;
    const numColumns = this.state.data[0] ? Object.keys(this.state.data[0]).length - 1 : 0;

    if (event.key === 'ArrowUp' && focusedRow > 1) {
      this.setState({ focusedRow: focusedRow - 1 });
    } else if (event.key === 'ArrowDown' && focusedRow < numRows) {
      this.setState({ focusedRow: focusedRow + 1 });
    } else if (event.key === 'ArrowRight' && focusedColumn < numColumns) {
      this.setState({ focusedColumn: focusedColumn + 1 });
    } else if (event.key === 'ArrowLeft' && focusedColumn > 1) {
      this.setState({ focusedColumn: focusedColumn - 1 });
    }

    // Set focus to the input element
    if (this.inputRefs[focusedRow] && this.inputRefs[focusedRow][focusedColumn]) {
      this.inputRefs[focusedRow][focusedColumn].current.focus();
    }
  };

  render() {
    const { data, focusedRow, focusedColumn } = this.state;

    return (
      <table tabIndex="0" onKeyDown={this.handleKeyPress}>
        <thead>
          <tr>
            {Object.keys(data[0])
              .filter((key) => key !== 'id')
              .map((key) => (
                <th key={key}>{key}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {Object.keys(row)
                .filter((key) => key !== 'id')
                .map((key, index) => (
                  <td key={key}>
                    <input
                      type="text"
                      value={row[key]}
                      onFocus={() =>
                        this.setState({ focusedRow: row.id, focusedColumn: index + 1 })
                      }
                      readOnly={focusedRow !== row.id || focusedColumn !== index + 1}
                      ref={this.inputRefs[row.id][index]}
                    />
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default TableWithInputFocus;
