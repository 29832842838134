import React, { useState, useEffect } from "react";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";

const sumAggFunc = (params) => {
    const values = params.values;
    let sum = 0;
  
    values.forEach((value) => {
      sum += value;
    });
  
    return sum;
  };

  const pinnedBottomRowData = [
    {
      departmentName: "TOTAL",
      totalPsc: 0,
      totalLength: 0,
      totalWidth: 0,
      totalHeight: 0,
      totalWeight: 0,
    },
  ];

export default function AdminDashboardWithOutBag() {

    const [stoneList, setStoneList] = useState([]);
  const [totalStock, setTotalStock] = useState({
    totalPcs: 0,
    totalLength: 0,
    totalWidth: 0,
    totalHeight: 0,
    totalWeight: 0,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departmentDetailsData, setDepartmentDetailsData] = useState([]);

  const [issueToWorkerList, setIssueToWorkerList] = useState([]);

  const [sumsForDepart, setSumsForDepartment] = useState({
    departmentFooterTotalPcs: 0,
    departmentFooterTotalLength: 0,
    departmentFooterTotalWidth: 0,
    departmentFooterTotalHeight: 0,
    departmentFooterTotalWeight: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStockList();
    getIssueToWorkerList();
  }, []);

  const getStockList = () => {
    setIsLoading(true); // Show loader
    api
      .get("AdminDashboard/CurrentStockOfAll")
      .then((result) => {
        setStoneList(result.data.data.list);
        // setTotalStock({
        //   totalPcs: result.data.data.totalStoneSummary.pcs,
        //   totalLength: result.data.data.totalStoneSummary.length,
        //   totalWidth: result.data.data.totalStoneSummary.width,
        //   totalHeight: result.data.data.totalStoneSummary.height,
        //   totalWeight: result.data.data.totalStoneSummary.weight,
        // });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  const getIssueToWorkerList = () => {
    api
      .get("AdminDashboard/CurrentIssueToWorkForAll")
      .then((result) => {
        setIssueToWorkerList(result.data.data.list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetDepartmentDetail = (DepartmentID) => {
    api
      .get(
        "AdminDashboard/CurrentStockOfDepartment?DepartmentID=" + DepartmentID
      )
      .then((result) => {
        ;
        setIsModalOpen(true);
        setDepartmentDetailsData(result.data.data.listStoneDataModel);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columnDefs = [
    {
      headerName: "Department",
      field: "departmentName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ data, value }) => (
        <strong
          style={{ cursor: "pointer" }}
          onClick={() => GetDepartmentDetail(data.departmentID)}
        >
          {value}
        </strong>
      ),
    },
    {
      headerName: "Pcs",
      field: "totalPsc",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Length",
      field: "totalLength",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Width",
      field: "totalWidth",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Height",
      field: "totalHeight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Weight",
      field: "totalWeight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
  ];

  const workerColumnDefs = [
    {
      headerName: "Worker Name",
      field: "workerName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pcs",
      field: "totalPsc",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Length",
      field: "totalLength",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Width",
      field: "totalWidth",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Height",
      field: "totalHeight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Weight",
      field: "totalWeight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const DepartmentDetailsColumnDefs = [
    {
      headerName: "No",
      field: "stoneNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      field: "stoneType",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "pcs",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc,
    },
    {
      headerName: "Length",
      field: "length",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc,
    },
    {
      headerName: "Width",
      field: "width",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc,
    },
    {
      headerName: "Height",
      field: "height",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc,
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc,
    },
  ];

  //Data Start
  useEffect(() => {
    updatePinnedBottomRowData();
    setTopTotal(); 
  }, [stoneList]);

  const calculateSums = (event) => {
    var filteredData;
    if (event === undefined) {
      filteredData = stoneList;
    } else {
      filteredData = event.api.getModel().rowsToDisplay.map((row) => row.data);
    }
    
    if (filteredData !== undefined && filteredData.length > 0) {
      const filteredSumPcs = _.sumBy(filteredData, (item) =>
        item.totalPsc > 0 ? item.totalPsc : 0
      );
  
      var totalLength = 0;
      var totalWidth = 0;
      var totalHeight = 0;
      filteredData.forEach((item) => {
        totalLength += item.totalPsc * item.totalLength;
        totalWidth += item.totalPsc * item.totalWidth;
        totalHeight += item.totalPsc * item.totalHeight;
      });
  
      const filteredSumLength = parseFloat(
        (totalLength / filteredSumPcs).toFixed(2)
      );
      const filteredSumWidth = parseFloat(
        (totalWidth / filteredSumPcs).toFixed(2)
      );
      const filteredSumHeight = parseFloat(
        (totalHeight / filteredSumPcs).toFixed(2)
      );
      const filteredSumWeight = parseFloat(
        _.sumBy(filteredData, "totalWeight").toFixed(2)
      );
  
      // Update the sums state with the filtered sums
      const updatedSums = {
        footerTotalPcs: filteredSumPcs,
        footerTotalLength: filteredSumLength,
        footerTotalWidth: filteredSumWidth,
        footerTotalHeight: filteredSumHeight,
        footerTotalWeight: filteredSumWeight,
      };
  
      return updatedSums;
    }
  
    // Return default values if no data
    return {
      footerTotalPcs: 0,
      footerTotalLength: 0,
      footerTotalWidth: 0,
      footerTotalHeight: 0,
      footerTotalWeight: 0,
    };
  };
  
  const setTopTotal = (event) => {
    const sums = calculateSums(event);
    setTotalStock({
      totalPcs: sums.footerTotalPcs, // Set total pcs
      totalLength: sums.footerTotalLength.toFixed(2), // Format to 2 decimal places
      totalWidth: sums.footerTotalWidth.toFixed(2), // Format to 2 decimal places
      totalHeight: sums.footerTotalHeight.toFixed(2), // Format to 2 decimal places
      totalWeight: sums.footerTotalWeight.toFixed(2), // Format to 2 decimal places
    });
  };

  const updatePinnedBottomRowData = (event) => {
    const sums = calculateSums(event);
    pinnedBottomRowData[0].totalPsc = sums.footerTotalPcs;
  pinnedBottomRowData[0].totalLength = sums.footerTotalLength;
  pinnedBottomRowData[0].totalWidth = sums.footerTotalWidth;
  pinnedBottomRowData[0].totalHeight = sums.footerTotalHeight;
  pinnedBottomRowData[0].totalWeight = sums.footerTotalWeight;
  };

  const onFilterChanged = (event) => {
    updatePinnedBottomRowData(event);
    if (event) {
      event.api.refreshCells({ force: true, suppressFlash: true });
    }
  };
  


  //End New Code

  // new Department
  useEffect(() => {
    calculateSumsForDepartment();
  }, [departmentDetailsData]);

  const calculateSumsForDepartment = (event) => {
    ;
    var filteredData;
    if (event === undefined) {
      filteredData = departmentDetailsData;
    } else {
      filteredData = event.api.getModel().rowsToDisplay.map((row) => row.data);
    }
    if (filteredData.length > 0) {
      const filteredSumPcs = _.sumBy(filteredData, "pcs");

      var totalLength = 0;
      var totalWidth = 0;
      var totalHeight = 0;
      filteredData.forEach((item) => {
        totalLength += item.pcs * item.length;
        totalWidth += item.pcs * item.width;
        totalHeight += item.pcs * item.height;
      });

      const filteredSumLength = parseFloat(
        (totalLength / filteredSumPcs).toFixed(2)
      );
      const filteredSumWidth = parseFloat(
        (totalWidth / filteredSumPcs).toFixed(2)
      );
      const filteredSumHeight = parseFloat(
        (totalHeight / filteredSumPcs).toFixed(2)
      );
      const filteredSumWeight = parseFloat(
        _.sumBy(filteredData, "weight").toFixed(2)
      );

      // Update the sums state with the filtered sums
      setSumsForDepartment({
        departmentFooterTotalPcs: filteredSumPcs,
        departmentFooterTotalLength: filteredSumLength,
        departmentFooterTotalWidth: filteredSumWidth,
        departmentFooterTotalHeight: filteredSumHeight,
        departmentFooterTotalWeight: filteredSumWeight,
      });
    }
  };

  const onFilterChangedForDepartment = (event) => {
    calculateSumsForDepartment(event);
  };

  const pinnedBottomRowDataForDepartment = [
    {
      stoneType: "TOTAL",
      pcs: sumsForDepart.departmentFooterTotalPcs,
      length: sumsForDepart.departmentFooterTotalLength,
      width: sumsForDepart.departmentFooterTotalWidth,
      height: sumsForDepart.departmentFooterTotalHeight,
      weight: sumsForDepart.departmentFooterTotalWeight,
    },
  ];
  return (
    <>
      {isLoading && (
        <>
          <div className="overlay"></div>
          <div className="overlay-content text-center">
            <Spinner animation="" variant="primary">
              <span class="loader"></span>
            </Spinner>
          </div>
        </>
      )}
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-1 col-sm-1 col-lg-1"></div>
              <div className="col-md-2 col-sm-2 col-lg-2 ">
                <div className="dashboardSummaryDiv">
                  <h4>
                    <ion-icon name="diamond-outline"></ion-icon> Total Stone
                  </h4>
                  <h2>{totalStock.totalPcs}</h2>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="dashboardSummaryDiv">
                  <h4>Avg. Length</h4>
                  <h2>{totalStock.totalLength}</h2>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="dashboardSummaryDiv">
                  <h4>Avg. Width</h4>
                  <h2>{totalStock.totalWidth}</h2>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="dashboardSummaryDiv">
                  <h4>Avg. Height</h4>
                  <h2>{totalStock.totalHeight}</h2>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="dashboardSummaryDiv">
                  <h4>Total Weight</h4>
                  <h2>{totalStock.totalWeight}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <span>Current Stock List</span>
          </div>
          <div className="card-body">
            <AgGridComponent
              rowData={stoneList}
              columnDefs={columnDefs}
              onFilterChanged={onFilterChanged}
              pinnedBottomRowData={pinnedBottomRowData}
              paginationPageSize={100}
              height="70vh"
            />
          </div>
        </div>
      </div>

      <div className="card mt-10">
        <div className="card-header">
          <span>Worker Stock List</span>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={issueToWorkerList}
            columnDefs={workerColumnDefs}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();

            }}
            paginationPageSize={100}
              height="70vh"
          />
        </div>
      </div>

      <div
        className={`modal modal-fullscreen fade ${
          isModalOpen ? "show d-block" : ""
        }`}
        id="exampleModalCenter"
        tabIndex="-1"
        aria-labelledby="example-custom-modal-styling-title"
        aria-hidden={!isModalOpen}
      >
        <div style={{ maxHeight: "100vh" }}>
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#263369", color: "#fff" }}
            >
              <h5 className="modal-title">
                <strong> Stone Details</strong>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsModalOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="card-body">
                <AgGridComponent
                  rowData={departmentDetailsData}
                  columnDefs={DepartmentDetailsColumnDefs}
                  onFilterChanged={onFilterChangedForDepartment}
                  pinnedBottomRowData={pinnedBottomRowDataForDepartment}
                />
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  )
}
