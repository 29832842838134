import React, { useState, useRef, useEffect } from "react";
import "./PhotoGallery.css";
import PropTypes from "prop-types";
import refresh from "./assets/refresh.png"
import ZoomIn from "./assets/zoom-in.png"
import ZoomOut from "./assets/zoom-out.png"
import Close from "./assets/icons8-close-94.png"

export default function PhotoGallery({
  className,
  fullScreen,
  showThumbnails,
  children,
}) {
  const [slideIndex, setSlideIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const scrollContainerRef = useRef(null);
  const currentImageRef = useRef(null);
  const [imagesList, setImagesList] = useState([]);

  const extractImages = (children) => {
    const images = [];
    React.Children.forEach(children, (child) => {
      if (child.type === "img") {
        images.push(child.props.src);
      } else if (child.props && child.props.children) {
        // Recursively search for images within nested children
        images.push(...extractImages(child.props.children));
      }
    });
    return images;
  };

  useEffect(() => {
    if (isFullScreen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isFullScreen]);

  // useEffect(() => {
  //   const imagesList = extractImages(children);
  //   setImagesList(imagesList);
  // }, [children,extractImages]);

  const handleClickImage = (index) => {
    if (fullScreen) {
      setSlideIndex(index);
      setIsFullScreen(true);
      const imagesList = extractImages(children);
      setImagesList(imagesList);
    }
  };

  useEffect(() => {
    // Center the active image in the scroll container when the slideIndex changes
    if (scrollContainerRef.current) {
      const containerWidth = scrollContainerRef.current.offsetWidth;
      const scrollPosition =
        (scrollContainerRef.current.scrollWidth / imagesList.length) *
          slideIndex -
        containerWidth / 2;
      scrollContainerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [slideIndex, imagesList.length]);

  const handleKeyPress = (event) => {
    if (event.keyCode === 37) {
      setCurrentSlide(slideIndex - 1);
    } else if (event.keyCode === 39) {
      setCurrentSlide(slideIndex + 1);
    }
  };

  // Add event listener for key press events
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [slideIndex, imagesList.length]);

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  const setCurrentSlide = (index) => {
    let newIndex = index;
    if (newIndex < 0) {
      newIndex = imagesList.length - 1; // Go to the last image if at the beginning
    } else if (newIndex >= imagesList.length) {
      newIndex = 0; // Go to the first image if at the end
    }
    setSlideIndex(newIndex);
    resetZoom();
  };

  const resetZoom = () => {
    setScale(1);
    setTranslateX(0);
    setTranslateY(0);
  };

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 1));
  };

  const handleMouseDown = (event) => {
    if (scale > 1) {
      setIsDragging(true);
      setStartX(event.clientX - translateX);
      setStartY(event.clientY - translateY);
    }
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      setTranslateX(event.clientX - startX);
      setTranslateY(event.clientY - startY);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWheel = (event) => {
    // event.preventDefault();
    if (event.deltaY < 0) {
      zoomIn();
    } else {
      zoomOut();
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -150, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 150, behavior: "smooth" });
    }
  };

  const wrapImagesWithClickHandler = (children) => {
    let imageIndex = 0;

    const recursiveWrapper = (children) => {
      return React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return child;

        if (child.type === "img") {
          const currentIndex = imageIndex;
          imageIndex++;
          return React.cloneElement(child, {
            onClick: () => handleClickImage(currentIndex),
          });
        } else if (child.props && child.props.children) {
          return React.cloneElement(child, {
            ...child.props,
            children: recursiveWrapper(child.props.children),
          });
        }
        return child;
      });
    };

    return recursiveWrapper(children);
  };

  const wrappedChildren = wrapImagesWithClickHandler(children);

  return (
    <>
      {showThumbnails ? <div>{wrappedChildren}</div> : children}
      {isFullScreen && (
        <div className="PhotoGallery-modal-overlay">
          <div className="PhotoGallery-modal-content">
            <div className="PhotoGallery-zoom-buttons">
              {/* <button  onClick={resetZoom}><img src={refresh} alt="refresh"/></button> */}
              <img src={refresh} alt="refresh" onClick={resetZoom}/>
              {/* <button className="PhotoGallery-Top-buttons" onClick={zoomIn}><img src={ZoomIn} /></button> */}
              <img src={ZoomIn} alt="ZoomIN" onClick={zoomIn}/>
              {/* <button className="PhotoGallery-Top-buttons" onClick={zoomOut}><img src={ZoomOut}/></button> */}
              <img src={ZoomOut} alt="ZoomOut" onClick={zoomOut}/>
              {/* <button className="PhotoGallery-Top-buttons" onClick={handleCloseFullScreen}><img src={Close}/></button> */}
              <img src={Close} alt="Close" onClick={handleCloseFullScreen}/>

            </div>

            <div
              className="PhotoGallery-slide-wrapper"
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              {imagesList.map((image, index) => (
                <div
                  key={index}
                  className="PhotoGallery-mySlides"
                  style={{ display: index === slideIndex ? "flex" : "none" }}
                >
                  <img
                    src={image}
                    ref={index === slideIndex ? currentImageRef : null}
                    className="PhotoGallery-zoomable"
                    style={{
                      width: "100%",
                      transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
                    }}
                    onMouseDown={handleMouseDown}
                    onWheel={handleWheel}
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              ))}
              <a
                className="PhotoGallery-prev"
                 role="button" // Adds button role for accessibility
  tabIndex="0"
                onClick={() => setCurrentSlide(slideIndex - 1)}
              >
                &#10094;
              </a>
              <a
                className="PhotoGallery-next"
                 role="button" // Adds button role for accessibility
  tabIndex="0"
                onClick={() => setCurrentSlide(slideIndex + 1)}
              >
                &#10095;
              </a>
            </div>

            <div className="PhotoGallery-scroll-container-wrapper">
              <a className="PhotoGallery-scroll-left"  role="button" // Adds button role for accessibility
  tabIndex="0" onClick={scrollLeft}>
                &#10094;
              </a>
              <div
                className="PhotoGallery-scroll-container"
                ref={scrollContainerRef}
              >
                {imagesList.map((image, index) => (
                  <img
                    key={index}
                    className={`PhotoGallery-demo cursor ${
                      index === slideIndex ? "PhotoGallery-active" : ""
                    }`}
                    src={image}
                    onClick={() => setCurrentSlide(index)}
                    alt={`Slide ${index + 1}`}
                  />
                ))}
              </div>
              <a className="PhotoGallery-scroll-right"  role="button" // Adds button role for accessibility
  tabIndex="0" onClick={scrollRight}>
                &#10095;
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

PhotoGallery.propTypes = {
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  showThumbnails: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

PhotoGallery.defaultProps = {
  className: "",
  fullScreen: false,
  showThumbnails: false,
};
