import React, { useEffect } from 'react'
import Login from '../Login/Login'

export default function Logout() {
    
    useEffect(() => {
        localStorage.setItem('token', '');
        window.location.assign("/");
        <Login />
    })
}
