import React, { useState, useRef, useCallback } from "react";
import Swal from "sweetalert2";
import api from "../../Utils/api";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 400,
  facingMode: "environment",
};

const ImageUploadModalComponent = ({
  showModal,
  onClose,
  bNO,
  sNO,
  referenceID,
  type,
}) => {
  const [files, setFiles] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0);

  const webcamRef = useRef(null);
  const [screenshots, setScreenshots] = useState([]);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const capturePhoto = useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setScreenshots((prevScreenshots) => [...prevScreenshots, imageSrc]);
    }
  }, [webcamRef]);

  const onUserMedia = (e) => {
    console.log(e);
  };

  // Helper function to convert data URL to Blob
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleUpload = () => {
    ;
    if (files.length === 0 && screenshots.length === 0) {
      Swal.fire(
        "Error",
        "Please Choose at least one Image or capture a screenshot.",
        "error"
      );
    } else {
      const form_data = new FormData();
      form_data.append("ImagesForAllID", 0);
      form_data.append("StoneNo", sNO);
      form_data.append("BatchNo", bNO);
      form_data.append("ReferenceID", referenceID);
      form_data.append("ReferenceType", type);

      // Append captured screenshots
      for (let i = 0; i < screenshots.length; i++) {
        const screenshotBlob = dataURLtoBlob(screenshots[i]);
        form_data.append(
          `Screenshots[${i}]`,
          screenshotBlob,
          `screenshot_${i}.jpg`
        );
      }

      for (let i = 0; i < files.length; i++) {
        form_data.append(`Files[${i}]`, files[i]);
      }

      api
        .post(`ImagesForAll/InsertImageForAll`, form_data)
        .then((result) => {
          Swal.fire("Success", "Images uploaded successfully", "success");
          onClose();
          setFileInputKey(fileInputKey + 1);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire("Error", "An error occurred", "error");
        });
    }
  };

  return (
    <div
      className={`modal fade ${showModal ? "show d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden={!showModal}
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Attach Images</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group">
              <div className="row col-md-12">
                <div className="col-md-2 col-sm-2 col-lg-2">
                  <label htmlFor="machineStartTime">Image:</label>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4">
                  <Webcam
                    ref={webcamRef}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    onUserMedia={onUserMedia}
                    mirrored={false}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={capturePhoto}
                    style={{ marginRight: "10px" }}
                  >
                    Capture
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => setScreenshots([])}
                  >
                    Refresh
                  </button>
                  <div style={{ marginTop: "10px" }}>
                    {screenshots.map((screenshot, index) => (
                      <div key={index}>
                        <img
                          src={screenshot}
                          alt={`Screenshot ${index + 1}`}
                          style={{
                            width: "200px",
                            height: "auto",
                            margin: "5px",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  {/* {url && (
                    <div style={{ marginTop: "10px" }}>
                      {" "}
                      <img src={url} alt="" />
                    </div>
                  )} */}
                  <input
                    key={fileInputKey}
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ marginTop: "10px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer margin_top10">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleUpload}
            >
              Upload Image
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadModalComponent;
