import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import {
  PrintStoneSticker,
  PrintPreviewStoneDetails,
} from "../../Utils/commonfunctions";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { getCurrentDate, formatDate } from "../../Utils/commonfunctions";
import _ from "lodash";
// import QRCode from "react-qr-code";
// import { ISOToDateTime } from "../../Components/Common/Common";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { Modal, Button, Form } from "react-bootstrap";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const sumAggFunc = (params) => {
  const values = params.values;
  let sum = 0;

  values.forEach((value) => {
    sum += value;
  });

  return sum;
};

const pinnedBottomRowData = [
  {
    stoneNo: "",
    batchNo: "",
    stoneType: "TOTAL",
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    pcs: 0,
  },
];

const emptySearchStone = {
  start_date: getCurrentDate(),
  end_date: getCurrentDate(),
  searchStone: "",
};

var selectedRowIDForPrint = [];
export default function Stone() {
  const [StoneData, setStoneData] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsMix, setOptionsMixNo] = useState([]);
  const [selectedRowID, setSelectedRowID] = useState([]); // Selected Row IDs state
  // Selected Row IDs state
  const [numOfRows, setNumOfRows] = useState([]);
  const [stoneList, setStoneList] = useState([]);
  const [stoneType, setStoneType] = useState("");
  const [mixNo, setMixNo] = useState(0);
  const [mixNoData, setMixNoData] = useState([]);

  //edit stone Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stoneDataForPopup, setStoneDataForPopup] = useState([]);
  const [showMixStoneDetails, setShowMixStoneDetails] = useState(false);

  const [searchStoneData, setSearchStoneData] = useState(emptySearchStone);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getMixNo();
    getStoneTypeName();
    getStone();
    setShowMixStoneDetails(false);
    setNumOfRows([]);
    setStoneType("");
  }, []);

  //Fetch Stone Type Name For DD
  const getStoneTypeName = () => {
    api
      .get("StoneType/GetStoneTypeName")
      .then((result) => {
        setOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStone = () => {
    const formattedStartDate = formatDate(searchStoneData.start_date);
    const formattedEndDate = formatDate(searchStoneData.end_date);
    api
      .get(
        `Stone/AllStones?START_DATE=${formattedStartDate}&END_DATE=${formattedEndDate}&StoneNo=${searchStoneData.searchStone}`
      )
      .then((result) => {
        setStoneData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Fetch MixNo
  const getMixNo = () => {
    api
      .get("Stone/MixNoForDropDown")
      .then((result) => {
        setOptionsMixNo(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowInputChange = (index, field, value) => {
    const updatedStoneList = [...stoneList];
    updatedStoneList[index] = {
      ...updatedStoneList[index],
      [field]: value,
      mixNo: mixNo,
      stoneType: stoneType,
    };
    setStoneList(updatedStoneList);
  };

  // Insert & Update Data
  const formik = useFormik({
    initialValues: {
      stone_id: 0,
      stone_Type: stoneType,
      length: 0.0,
      width: 0.0,
      height: 0.0,
      weight: 0.0,
      in_stock: false,
      received: false,
      pcs: 0,
      lot_type: "",
      mix_no: "",
    },
    validationSchema: Yup.object({
      stone_Type: Yup.string().required("Select Stone Type"),
      lot_type: Yup.string().required("Select Lot Type"),
    }),
    validateOnChange: true, // Enable validation on change
    validateOnBlur: true, // Enable validation on blur
  });

  // Resetting form and related data
  const restData = () => {
    setStoneList([]);
    setMixNo(0);
    setStoneType("");
    setNumOfRows([]);
    setMixNoData([]);
    setMixNoData([]);
    setShowMixStoneDetails(false);
  };

  // Inserting stone data
  const InsertStone = () => {
    setIsLoading(true); // Show loader
    api
      .post(`Stone/Insert`, stoneList)
      .then((result) => {
        toast.success(result.data.message);
        getStone();
        restData();
        printNewInsertedSticker(result.data.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };
  // Delete Stone
  const deleteStone = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Stone/Delete?StoneID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getStone();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // for export excel
  const exportToExcel = () => {
    setIsLoading(true);

    setTimeout(async () => {
      try {
        const fileName = "Stone.xlsx";

        // Format the data to be exported
        const formattedData = StoneData.map((item) => ({
          StoneNo: item.stoneNo,
          LotNo: item.batchNo,
          StoneType: item.stoneType,
          Length: item.length,
          Width: item.width,
          Height: item.height,
          Weight: item.weight,
          Pcs: item.pcs,
        }));

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Issue Machine List");

        // Set up the columns
        worksheet.columns = [
          { header: "Stone No", key: "StoneNo", width: 20 },
          { header: "Lot No", key: "LotNo", width: 20 },
          { header: "Stone Type", key: "StoneType", width: 20 },
          { header: "Length", key: "Length", width: 15 },
          { header: "Width", key: "Width", width: 15 },
          { header: "Height", key: "Height", width: 15 },
          { header: "Weight", key: "Weight", width: 15 },
          { header: "PCS", key: "Pcs", width: 10 },
        ];

        // Add the rows to the worksheet
        worksheet.addRows(formattedData);

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF4F81BD" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Adjust column widths based on content length
        worksheet.columns.forEach((column) => {
          let maxLength = column.header.length;
          formattedData.forEach((row) => {
            const cellValue = row[column.key];
            if (cellValue) {
              maxLength = Math.max(maxLength, cellValue.toString().length);
            }
          });
          column.width = maxLength + 2; // Add padding
        });

        // Export the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        // Handle any error here, such as showing an error message to the user
      } finally {
        setIsLoading(false);
      }
    }, 1000);
  };

  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      width: 50,
      headerClass: "centered-header",
      pinned: "left",
    },
    {
      headerName: "Stone No",
      field: "stoneNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Lot No",
      field: "batchNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Stone Type",
      field: "stoneType",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Length",
      field: "length",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      //cellRenderer: ({ value, data }) => value.toFixed(2),
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Width",
      field: "width",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      // cellRenderer: ({ value, data }) => value.toFixed(2),
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Height",
      field: "height",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      //cellRenderer: ({ value, data }) => value.toFixed(2),
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Pcs",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },

    {
      headerName: "Print",
      width: 100,
      field: "stoneNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value, data }) =>
        data.stoneType !== "TOTAL" ? (
          <ion-icon
            name="print-outline"
            class="iconsFonts"
            onClick={() => PrintStoneSticker(data.stoneNo, data.stoneProcessNo)}
          ></ion-icon>
        ) : null,
    },
    {
      headerName: "Edit",
      width: 100,
      field: "stoneID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ data, value }) =>
        data.stoneType !== "TOTAL" ? (
          <ion-icon
            name="create-outline"
            class="iconsFonts editIcon"
            onClick={() => editStoneModal(value)}
          ></ion-icon>
        ) : null,
    },
    {
      headerName: "Delete",
      width: 100,
      field: "stoneID",
      pinned: "right",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ data, value }) =>
        data.stoneType !== "TOTAL" ? (
          <ion-icon
            name="trash-outline"
            class="iconsFonts deleteIcon"
            onClick={() => deleteStone(value)}
          ></ion-icon>
        ) : null,
    },
  ];

  //stone edit start
  const editStoneModal = (id) => {
    api
      .get(`Stone/ByID?StoneID=${id}`)
      .then((result) => {
        const StoneData = result.data.data;
        setStoneDataForPopup(StoneData);
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const updateStoneDetails = () => {
    api
      .patch(`Stone/Edit`, stoneDataForPopup)
      .then((result) => {
        getStone();
        setIsModalOpen(false);
        toast.success(result.data.message);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while updating the stone");
      });
  };
  //stone edit end

  const handleCheckboxSelection = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedRowIDs = Array.isArray(selectedRows)
      ? selectedRows.map((row) => row.stoneNo)
      : [];
    selectedRowIDForPrint = selectedRowIDs;
  };

  const printSelectedRows = () => {
    api
      .post(`Stone/PrintMultiStoneSticker`, selectedRowIDForPrint)
      .then((result) => {
        const selectedStonesData = result.data.data;
        HandelPrintStone(selectedStonesData);
        setSelectedRowID([]);
        getStone();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const printNewInsertedSticker = (data) => {
    api
      .post(`Stone/PrintMultiStoneSticker`, data)
      .then((result) => {
        const selectedStonesData = result.data.data;
        HandelPrintStone(selectedStonesData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandelPrintStone = (selectedStonesData) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    iframeDocument.write(
      '<html><head><style>@page{margin: 0;}</style><title>Report</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"></head><body>'
    );
    ReactDOM.render(
      <>
        {selectedStonesData.map((stoneDetail, index) => (
          <React.Fragment key={index}>
            <PrintPreviewStoneDetails stoneDetail={stoneDetail} />
          </React.Fragment>
        ))}
      </>,
      iframeDocument.body
    );
    iframeDocument.write("</body></html>");
    iframeDocument.close();
    setTimeout(() => {
      iframe.contentWindow.print();
    }, 400);
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };

  const MixNoSetAndGetData = (e) => {
    const selectedMixNo = e.value;
    setMixNo(selectedMixNo);
    api
      .get(`Stone/ByID?StoneID=${selectedMixNo}`)
      .then((result) => {
        setMixNoData(result.data.data);
        setStoneType(result.data.data.stoneType);
        setShowMixStoneDetails(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchStoneData({ ...searchStoneData, [name]: value });
    // getStone();
  };

  const showSearchStone = () => {
    getStone();
  };

  //Data Start
  useEffect(() => {
    updatePinnedBottomRowData();
  }, [StoneData]);

  const calculateSums = (event) => {
    let filteredData;
    if (event === undefined) {
      filteredData = StoneData;
    } else {
      filteredData = event.api.getModel().rowsToDisplay.map((row) => row.data);
    }

    const filteredSumPcs = _.sumBy(filteredData, "pcs");
    const filteredSumLength = parseFloat(
      (_.sumBy(filteredData, "length") / filteredSumPcs).toFixed(2)
    );
    const filteredSumWidth = parseFloat(
      (_.sumBy(filteredData, "width") / filteredSumPcs).toFixed(2)
    );
    const filteredSumHeight = parseFloat(
      (_.sumBy(filteredData, "height") / filteredSumPcs).toFixed(2)
    );
    const filteredSumWeight = parseFloat(
      _.sumBy(filteredData, "weight").toFixed(2)
    );

    // Return the calculated sums as an object
    return {
      footerTotalPcs: filteredSumPcs,
      footerTotalLength: filteredSumLength,
      footerTotalWidth: filteredSumWidth,
      footerTotalHeight: filteredSumHeight,
      footerTotalWeight: filteredSumWeight,
    };
  };

  const updatePinnedBottomRowData = (event) => {
    const sums = calculateSums(event);
    pinnedBottomRowData[0].stoneNo = "";
    pinnedBottomRowData[0].batchNo = "";
    pinnedBottomRowData[0].stoneType = "TOTAL";
    pinnedBottomRowData[0].length = sums.footerTotalLength;
    pinnedBottomRowData[0].width = sums.footerTotalWidth;
    pinnedBottomRowData[0].height = sums.footerTotalHeight;
    pinnedBottomRowData[0].weight = sums.footerTotalWeight;
    pinnedBottomRowData[0].pcs = sums.footerTotalPcs;
  };

  // Example usage
  const onFilterChanged = (event) => {
    updatePinnedBottomRowData(event);
    updatePinnedBottomRowData(event);
    if (event) {
      event.api.refreshCells({ force: true, suppressFlash: true });
    }
  };

  //End New Code

  return (
    <>
      {isLoading && (
        <>
          <div className="overlay"></div>
          <div className="overlay-content text-center">
            <Spinner animation="" variant="primary">
              <span class="loader"></span>
            </Spinner>
          </div>
        </>
      )}
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>New Stone</span>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row col-md-12 mt-4">
              <div className="col-md-6">
                <div className="col-md-12 d-flex align-items-center">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <label
                      htmlFor="numOfRows"
                      className="form-label"
                      style={{ marginRight: "20px" }}
                    >
                      MixNo
                    </label>
                  </div>

                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <div className="form-group">
                      <Select
                        id="mix_no"
                        name="mix_no"
                        value={mixNo}
                        onChange={MixNoSetAndGetData}
                        options={optionsMix.map((option) => ({
                          value: option.id,
                          label: option.text,
                        }))}
                        placeholder="--Select MixNo--"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <label
                      htmlFor="stoneType"
                      className="form-label"
                      style={{ marginRight: "20px" }}
                    >
                      Stone Type
                    </label>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <select
                      className="form-control"
                      name="stone_Type"
                      value={stoneType}
                      onChange={(e) => setStoneType(e.target.value)}
                    >
                      <option value="0">--Select Stone Type--</option>
                      {options.map((option) => (
                        <option key={option.text} value={option.text}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <label
                      htmlFor="numOfRows"
                      className="form-label"
                      style={{ marginRight: "20px" }}
                    >
                      Enter the Stone
                    </label>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <input
                      type="number"
                      className="form-control"
                      id="numOfRows"
                      placeholder="Number of Rows"
                      value={numOfRows}
                      onChange={(e) => setNumOfRows(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 d-flex justify-content-center">
                {showMixStoneDetails && (
                  <div className="card" style={{ borderColor: "blue" }}>
                    <h4
                      className="card-title"
                      style={{
                        textAlign: "center",
                        backgroundColor: "#20336b",
                        color: "wheat",
                      }}
                    >
                      {mixNoData.stoneNo}
                    </h4>
                    <div className="card-body table-responsive">
                      <table className="table table-borderd">
                        <thead>
                          <tr>
                            <th>StoneType</th>
                            <th>Pcs</th>
                            <th>Length</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>Weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{mixNoData.stoneType}</td>
                            <td>{mixNoData.pcs}</td>
                            <td>{mixNoData.length}</td>
                            <td>{mixNoData.width}</td>
                            <td>{mixNoData.height}</td>
                            <td>{mixNoData.weight}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {numOfRows > 0 && (
              <div className="card-body table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Index</th>
                      <th>Length</th>
                      <th>Width</th>
                      <th>Height</th>
                      <th>Weight</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Array.from({ length: numOfRows }).map((_, rowIndex) => (
                      <tr key={rowIndex} style={{ lineHeight: "10px" }}>
                        <td style={{ textAlign: "center" }}>{rowIndex + 1}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            id={`length-${rowIndex}`}
                            placeholder="Length"
                            value={stoneList[rowIndex]?.length || ""}
                            onChange={(e) =>
                              handleRowInputChange(
                                rowIndex,
                                "length",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            id={`width-${rowIndex}`}
                            placeholder="Width"
                            value={stoneList[rowIndex]?.width || ""}
                            onChange={(e) =>
                              handleRowInputChange(
                                rowIndex,
                                "width",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            id={`height-${rowIndex}`}
                            placeholder="Height"
                            value={stoneList[rowIndex]?.height || ""}
                            onChange={(e) =>
                              handleRowInputChange(
                                rowIndex,
                                "height",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            id={`weight-${rowIndex}`}
                            placeholder="Weight"
                            value={stoneList[rowIndex]?.weight || ""}
                            onChange={(e) =>
                              handleRowInputChange(
                                rowIndex,
                                "weight",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div className="card-footer margin_top10">
              <button
                type="button"
                className="align-items-center btn btn-primary mb-20px"
                onClick={InsertStone}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="card">
        <div className="card-header" style={{ display: "flex" }}>
          <span>Stone List:</span>
          <button
            type="submit"
            className="btn btn-primary mb-0px"
            onClick={printSelectedRows}
            style={{ marginLeft: "10px" }}
          >
            Print
          </button>
          <div
            className="col-md-2 col-sm-2 col-lg-2"
            style={{ marginLeft: "10px" }}
          >
            <input
              type="date"
              className="form-control"
              name="start_date"
              id="startStartDate"
              placeholder="Enter Start Date"
              value={searchStoneData.start_date}
              onChange={handleInputChange}
            />
          </div>
          <div
            className="col-md-2 col-sm-2 col-lg-2"
            style={{ marginLeft: "10px" }}
          >
            <input
              type="date"
              className="form-control"
              id="stoneEndDate"
              name="end_date"
              placeholder="Enter End Date"
              value={searchStoneData.end_date}
              onChange={handleInputChange}
            />
          </div>
          <div
            className="col-md-2 col-sm-2 col-lg-2"
            style={{ marginLeft: "10px" }}
          >
            <input
              type="text"
              className="form-control"
              id="StoneSearch"
              placeholder="Enter StoneNo"
              name="searchStone"
              value={searchStoneData.searchStone}
              onChange={handleInputChange}
              onKeyDown={showSearchStone}
              ref={inputRef}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary mb-0px"
            onClick={showSearchStone}
            style={{ marginLeft: "10px" }}
          >
            <i class="fa fa-search"></i>
            {"  "} Search
          </button>
          <Button
            type="button"
            variant="success"
            className="btn btn-primary"
            onClick={exportToExcel}
            style={{ marginLeft: "10px" }}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={StoneData}
            columnDefs={columnDefs}
            onSelectionChanged={handleCheckboxSelection}
            onFilterChanged={onFilterChanged}
            pinnedBottomRowData={pinnedBottomRowData}
            paginationPageSize={50}
            height="70vh"
          ></AgGridComponent>
        </div>
      </div>

      {/* Modal Start */}
      <Modal show={isModalOpen} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Stone Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="row col-md-12 mt-4">
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="stone_Type_Edit"
              >
                Stone Type
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Select
                  name="stone_Type_Edit"
                  value={stoneDataForPopup.stoneType}
                  onChange={(e) =>
                    setStoneDataForPopup({
                      ...stoneDataForPopup,
                      stoneType: e.target.value,
                    })
                  }
                >
                  <option value="0">--Select Stone Type--</option>
                  {options.map((option) => (
                    <option key={option.text} value={option.text}>
                      {option.text}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Form.Group>

            <Form.Group className="row col-md-12 mt-4">
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="length_edit"
              >
                Length:
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Control
                  type="text"
                  id="length_edit"
                  placeholder="Enter Length"
                  name="length_edit"
                  value={stoneDataForPopup.length}
                  onChange={(e) =>
                    setStoneDataForPopup({
                      ...stoneDataForPopup,
                      length: e.target.value,
                    })
                  }
                />
              </div>

              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="width_edit"
              >
                Width:
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Control
                  type="text"
                  id="width_edit"
                  placeholder="Enter Width"
                  name="width_edit"
                  value={stoneDataForPopup.width}
                  onChange={(e) =>
                    setStoneDataForPopup({
                      ...stoneDataForPopup,
                      width: e.target.value,
                    })
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className="row col-md-12 mt-4">
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="height_edit"
              >
                Height:
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Control
                  type="text"
                  id="height_edit"
                  placeholder="Enter Height"
                  name="height_edit"
                  value={stoneDataForPopup.height}
                  onChange={(e) =>
                    setStoneDataForPopup({
                      ...stoneDataForPopup,
                      height: e.target.value,
                    })
                  }
                />
              </div>
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="weight_edit"
              >
                Weight:
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Control
                  type="text"
                  id="weight_edit"
                  placeholder="Enter Weight"
                  name="weight_edit"
                  value={stoneDataForPopup.weight}
                  onChange={(e) =>
                    setStoneDataForPopup({
                      ...stoneDataForPopup,
                      weight: e.target.value,
                    })
                  }
                />
              </div>
            </Form.Group>
            <Form.Group className="row col-md-12 mt-4">
              <Form.Label
                className="col-md-2 col-sm-2 col-lg-2"
                htmlFor="pcs_edit"
              >
                Pcs:
              </Form.Label>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <Form.Control
                  type="text"
                  id="pcs_edit"
                  placeholder="Enter PCS"
                  name="pcs_edit"
                  value={stoneDataForPopup.pcs}
                  onChange={(e) =>
                    setStoneDataForPopup({
                      ...stoneDataForPopup,
                      pcs: e.target.value,
                    })
                  }
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="margin_top10">
          <Button variant="primary" onClick={() => updateStoneDetails()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
